import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../app-config';
import { Http, Headers, Response, Request, RequestOptions, URLSearchParams, RequestMethod } from '@angular/http';



@Component({
  selector: 'app-cron',
  templateUrl: './cron.component.html',
  styleUrls: ['./cron.component.css']
})
export class CronComponent implements OnInit {

  //Servicio que ejecuta una tarea programada
  servidorRegistro = this.AppConfig.baseApiRegistro;
  

  constructor( private AppConfig: AppConfig,  private http: Http,) { }

  ngOnInit() {
    this.http.post(this.servidorRegistro + 'cron', {}).subscribe(
      data => {

      }
    );
  }

}
