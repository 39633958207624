import { Categoria } from "../../isa/domains/categoria";
import { Ubicacion } from "./ubicacion";

export class Activo {
    id: number;
    serial: string;
    nav: string;
    imagen: any;
    marca:any;
    modelo: any;
    descripcion: string;
    categoria: Categoria;
    ubicacion: Ubicacion;
    imagenRuta:any;
}