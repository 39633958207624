import { Injectable } from '@angular/core';
/**
 * This is a singleton class
 */
@Injectable()
export class AppConfig {
    //Provide all the Application Configs here

    public version: string = "1.0.0";
    public locale: string = "en-US";
    public currencyFormat = { style: "currency", currency: "USD" };
    public dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
    public zuulException: string = "com.netflix.zuul.exception.ZuulException";

    // API Related configs
    public apiPort: string = "80";
    // public apiPort: string = "8000";
    public apiPortAuth: string = "80";
    // public apiPortAuth: string = "8000";
    // public apiPortReg: string = "8000";
    public apiPortReg: string = "80";
    public apiPortMsj: string = "9191";
    public apiPortFront: string = "80";
    public apiProtocol: string = '';
    public apiHostName: string = '';
    public servidorImagenActivos: string;
    public servidorFacturaCompra: string;
    public servidorBajaEquipos: string;
    public servidorMantenimiento: string;
    public servidorConfiguracionAER: string;
    public servidorAER: string;
    public servidorSalidaEquipos: string;

    //public baseApiPath: string = "bc/public/index.php/api/v1";
    // public baseApiPath: string = "index.php/api/v1";    
    public baseApiPath: string = "api/v1";

    //public baseApiPathAuth: string = "bc/public/index.php/api";
    // public baseApiPathAuth: string = "index.php/api";    
    public baseApiPathAuth: string = "api";

    public baseApiRegistro: string = '';
    public baseApiPathMsj: string = '';
    //public rutaRegistro: string = "bc/public/index.php/registro";
    //public rutaRegistro: string = "index.php/registro";
    public rutaRegistro: string = "registro";

    constructor() {
        if (this.apiProtocol === undefined || this.apiProtocol === '') {
            this.apiProtocol = window.location.protocol;
        }
        if (this.apiHostName === undefined || this.apiHostName === '') {
            this.apiHostName = window.location.hostname; 
            //this.apiHostName = '192.168.100.4';
            //this.apiHostName = '186.3.3.166';

        }
        if (this.apiPort === undefined) {
            this.apiPort = window.location.port;
        }

        if (this.apiHostName.includes("infomud") || this.apiHostName.includes("heroku")) {
            this.baseApiPath = this.apiProtocol + "//" + this.apiHostName + "/";
            this.servidorImagenActivos = this.apiProtocol + "//" + this.apiHostName + "/imagenes/activos/";
            this.servidorFacturaCompra = this.apiProtocol + "//" + this.apiHostName + "/directorio/facturasCompra/";
            this.servidorBajaEquipos = this.apiProtocol + "//" + this.apiHostName + "/directorio/bajaEquipo/";
            this.servidorMantenimiento = this.apiProtocol + "//" + this.apiHostName + "/directorio/mantenimiento/";
            this.servidorConfiguracionAER = this.apiProtocol + "//" + this.apiHostName + "/directorio/configuracionActaER/";
            this.servidorAER = this.apiProtocol + "//" + this.apiHostName  + "/directorio/actasTemporales/";
            this.servidorSalidaEquipos = this.apiProtocol + "//" + this.apiHostName  + "/directorio/salidaEquipos/";


            //this.servidorImagenActivos =  this.apiProtocol + "//" + this.apiHostName + "/bc/public/imagenes/activos/";
            //this.servidorFacturaCompra =  this.apiProtocol + "//" + this.apiHostName + "/bc/public/directorio/facturasCompra/";            
            //this.servidorBajaEquipos =  this.apiProtocol + "//" + this.apiHostName + "/bc/public/directorio/bajaEquipo/";   
            //this.servidorMantenimiento =  this.apiProtocol + "//" + this.apiHostName + "/bc/public//directorio/mantenimiento/";                        

        }
        else {
            // this.servidorImagenActivos = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/imagenes/activos/";
            // this.servidorFacturaCompra = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/directorio/facturasCompra/";
            // this.servidorBajaEquipos = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/directorio/bajaEquipo/";
            // this.servidorMantenimiento = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/directorio/mantenimiento/";
            // this.servidorConfiguracionAER = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/directorio/configuracionActaER/";
            // this.servidorAER = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/directorio/actasTemporales/";
            // this.servidorSalidaEquipos = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/directorio/salidaEquipos/";

            this.servidorImagenActivos =  this.apiProtocol + "//" + this.apiHostName + "/bc/public/imagenes/activos/";            
            this.servidorFacturaCompra =  this.apiProtocol + "//" + this.apiHostName + "/bc/public/directorio/facturasCompra/";                        
            this.servidorBajaEquipos =  this.apiProtocol + "//" + this.apiHostName + "/bc/public/directorio/bajaEquipo/"; 
            this.servidorMantenimiento =  this.apiProtocol + "//" + this.apiHostName + "/bc/public/directorio/mantenimiento/";                                     
            this.servidorConfiguracionAER = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/bc/public/directorio/configuracionActaER/";
            this.servidorAER = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/bc/public/directorio/actasTemporales/";
            this.servidorSalidaEquipos = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/bc/public/directorio/salidaEquipos/";

            // this.baseApiPath = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/" + this.baseApiPath + "/";
            // this.baseApiPathAuth = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPortAuth + "/" + this.baseApiPathAuth + "/";
            // this.baseApiRegistro = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPortReg + "/" + this.rutaRegistro + "/";

            this.baseApiPath = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPort + "/bc/public/index.php/" + this.baseApiPath + "/";
            this.baseApiPathAuth = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPortAuth + "/bc/public/index.php/" + this.baseApiPathAuth + "/";
            this.baseApiRegistro = this.apiProtocol + "//" + this.apiHostName + ":" + this.apiPortReg + "/bc/public/index.php/" + this.rutaRegistro + "/";

        }
        if (this.locale === undefined) {
            this.locale = navigator.language;
        }
        if (this.baseApiPathMsj === undefined) {
            this.baseApiPathMsj = this.apiProtocol + "//" + this.apiHostName + ':' + this.apiPortMsj + '/chat';
        }

    }


}