import { Constantes } from '../../constantes';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (value !== null && value !== undefined) {
            return super.transform(value, Constantes.DATE_FMT);
        } else {
            return super.transform(new Date(), Constantes.DATE_FMT);
        }
    }
}