import {Component} from '@angular/core';
import {AppComponent} from './app.component';
import { Subject } from 'rxjs';
import { ApiRequestService } from './services/api-request.service';
import { UserInfoService } from './services/user-info.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl:  './app.topbar.component.html'
})
export class AppTopbarComponent {

    constructor(public userInfoService: UserInfoService, private router: Router,public app: AppComponent, public apiRequest: ApiRequestService) {}
    logout() {
        let resultado: Subject<any> = new Subject<any>();
        this.apiRequest.postLogout('/auth/logout/')
            .subscribe(respuesta => {
                if (respuesta !== undefined && respuesta !== null) {
                    resultado.next(respuesta);
                }
            }, error => {
                resultado.next(error);
            });
        this.userInfoService.removeUserInfo();
        this.router.navigate(['/login']);
    }

}
