import { Component, AfterViewInit, ElementRef, Renderer, ViewChild, OnDestroy, Compiler } from '@angular/core';
import { AppProperties } from './app.properties';
import { MdcDialogComponent } from '@angular-mdc/web';
import { SelectItem, MessageService } from 'primeng/primeng';
import { ApiRequestService } from './services/api-request.service';
import { UserInfoService } from './services/user-info.service';
import { Router } from '@angular/router';
import { URLSearchParams } from '@angular/http';
import { ConnectionService } from 'ng-connection-service';
import { connect } from '../assets/javascript/epsonimprimi';
require('jquery');
require('nanoScroller')
const ePosDev = require('../assets/javascript/epsonimprimi.js');

enum MenuOrientation {
    STATIC,
    OVERLAY,
    SLIM,
    HORIZONTAL
}

declare var jQuery: any;
declare var document: any;
declare var Element: any;
declare var rasterizeHTML: any;
declare var $: any;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

    canvas: any;
    private stompClient;

    Agencias_length: boolean = false;

    layoutCompact = true;

    layoutMode: MenuOrientation = MenuOrientation.OVERLAY;

    darkMenu = true;

    profileMode = 'inline';

    rotateMenuButton: boolean;

    topbarMenuActive: boolean;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    rightPanelActive: boolean;

    rightPanelClick: boolean;

    layoutContainer: HTMLDivElement;

    layoutMenuScroller: HTMLDivElement;

    menuClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    resetMenu: boolean;

    menuHoverActive: boolean;

    myHtml: any;

    loader: boolean = false;
    progressBarstatus: boolean = false;
    Agencias: SelectItem[];
    Menus = new Array;
    tienequePagar: boolean = false;
    notificaciones: any[];
    @ViewChild('layoutContainer') layourContainerViewChild: ElementRef;
    @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ElementRef;
    @ViewChild('myDialogAgencia') myDialogAgencia: MdcDialogComponent;

    public tituloHTML: string;
    public htmlLabelTitle: HTMLLabelElement;
    @ViewChild('titulo', { read: ElementRef }) titulo: ElementRef;

    isConnected: boolean = true;

    idiomaCalendario:any;

    constructor(private router: Router, public renderer: Renderer, public properties: AppProperties,
        private api: ApiRequestService, private userInfoService: UserInfoService,
        public Compiler: Compiler, private messageService: MessageService, private connectionService: ConnectionService
    ) {

        this.idiomaCalendario = {
            firstDayOfWeek: 0,
            dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
            dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
            today: 'Hoy',
            clear: 'Limpiar',
            dateFormat: 'dd/mm/yyyy'
          };

        this.Compiler.clearCache();
        this.overlayMenuActive = false;
        this.loader = false;
        this.progressBarstatus = false;
    //    this.initializeWebSocketConnection();
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                console.log("ONLINE");
            }
            else {
                console.log("OFFLINE");
            }
        })  
    }

    ngOnInit() {
        this.notificaciones = [];

        let parametros: URLSearchParams = new URLSearchParams();
        parametros.set('page', '0');
        parametros.set('size', '10');


    }


    // initializeWebSocketConnection() {
    //     let me = this;
    //     var ws = new SockJS(me.api.appConfig.baseApiPathMsj);
    //     this.stompClient = Stomp.over(ws);
    //     let that = this;
    //     this.stompClient.connect({}, function (frame) {
    //         that.stompClient.subscribe("/topic/messages" + '/' + me.userInfoService.getIdEmpresa(), (message) => {
    //             if (message.body != null && message.body) {
    //                 let resp: any = JSON.parse(message['body']);
    //                 if (resp.idEmpresa == me.userInfoService.getIdEmpresa()) {
    //                     resp.imagen = [
    //                         new Image(
    //                             0,
    //                             { // modal
    //                                 img: resp.imagen,
    //                                 // extUrl: 'http://www.google.com'
    //                                 description: resp.titulo
    //                             }
    //                         )]
    //                     me.notificaciones.push(resp);
    //                     // me.mensaje.mensajeServer(resp.operationMessage, resp.mensaje, resp.titulo);
    //                 }
    //             }
    //         });
    //     });
    // }

    cargarMenu() {
        setTimeout(() => {
           
            this.api.get('menu/listaPorPerfil', this.tituloHTML).subscribe(
                datos => {
                    datos.forEach(element => {
                        element.items.forEach(element2 => {
                            this.Menus.push(element2.routerLink[0]);
                        });
                    });
                });
        }, 1);
    }

    ngAfterViewInit() {
        this.layoutContainer = <HTMLDivElement>this.layourContainerViewChild.nativeElement;
        this.layoutMenuScroller = <HTMLDivElement>this.layoutMenuScrollerViewChild.nativeElement;



        setTimeout(() => {
            jQuery(this.layoutMenuScroller).nanoScroller({ flash: true });
        }, 10);


    }
    hideDialogAgencias(agencia: any, nombre: any) {
        this.myDialogAgencia.close();
        this.overlayMenuActive = true;
        this.router.navigate(['']);
        location.reload();
    }
    onLayoutClick($event) {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }
        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.resetMenu = true;
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;

    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;

        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        } else {
            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }
        }


        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;
        if (!this.isHorizontal()) {
            setTimeout(() => {
                jQuery(this.layoutMenuScroller).nanoScroller();
            }, 50);
        }
    }
    onFullScreen() {
        this.toggleFullscreen();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event, item) {

        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;
        event.preventDefault();
    }

    onRightPanelClick() {
        this.rightPanelClick = true;
        this.rightPanelActive = true;
    }

    hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    isHorizontal() {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    }

    isSlim() {
        return this.layoutMode === MenuOrientation.SLIM;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
    }

    changeToHorizontalMenu() {
        this.layoutMode = MenuOrientation.HORIZONTAL;
    }

    changeToSlimMenu() {
        this.layoutMode = MenuOrientation.SLIM;
    }

    ngOnDestroy() {
        jQuery(this.layoutMenuScroller).nanoScroller({ flash: true });
    }

    validMenu(menu) {
        var a: boolean = false;
        for (let index = 0; index < this.Menus.length; index++) {
            if (menu == this.Menus[index]) {
                a = true;
            }
        }
      
        if (!a) this.router.navigate(['']);
    }


    guardarMenuNavegacion() {
        const envio = {
            pathOrigen: window.location.href,
        };
        this.api.post('api/seguridad/auditoriaNavegacion/guardar', envio, this.tituloHTML).subscribe(item => item);;
    }


    fullscreen = function (e?) {

        if (e.webkitRequestFullScreen) {

            e.webkitRequestFullScreen();

        } else if (e.mozRequestFullScreen) {

            e.mozRequestFullScreen();

        }

    }
    public mensajeError(mensaje: string) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }

    public mensajeAdvertencia(mensaje: string) {

        this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: mensaje,  life: 9500 });
    }

    public mensajeInformacion(mensaje: string) {

        this.messageService.add({ severity: 'info', summary: 'Información', detail: mensaje });
    }

    public mensajeOK(mensaje: string) {

        this.messageService.add({ severity: 'success', summary: 'OK', detail: mensaje });
    }

    public mensaje(tipo: string, mensaje: string) {

        this.messageService.add({ severity: tipo.toLowerCase(), summary: '', detail: mensaje,  life: 9500 });
    }

    public mensajeServer(tipo: string, mensaje: string, titulo: string) {

        this.messageService.add({ severity: tipo.toLowerCase(), summary: titulo, detail: mensaje });
    }
    public mensajeServerFlash(tipo: string, mensaje: string, titulo: string) {

        this.messageService.add({ severity: tipo.toLowerCase(), summary: titulo, detail: mensaje, life: 1500 });
    }

    clear() {
        this.messageService.clear();
    }

    toggleFullscreen = function (elem?: any) {
        elem = elem || document.documentElement;
        if (!document.fullscreenElement && !document.mozFullScreenElement &&
            !document.webkitFullscreenElement && !document.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    public round(number, precision) {
        var factor = Math.pow(10, precision);
        var tempNumber = number * factor;
        var roundedTempNumber = Math.round(tempNumber);
        return roundedTempNumber / factor;
    };

    public imprimir(nativehtml, ipprinter, portprinter, urlPrinter, name, tipoImpresion: string, idComprobate: number, caja: boolean) {
        if (caja) {
            ePosDev.connectOpen(ipprinter, portprinter);
        }
        this.canvas = document.getElementById('myCanvas');
        var pagina: any = "<html><body>" + nativehtml + "</body></html>";
        rasterizeHTML.drawHTML(pagina, this.canvas);
        var p = $('#' + name).clone().css('display', 'none');
        $('body').append(p);

        this.canvas.height = p.height() * 1.3;

        p.remove();
        setTimeout(() => {

            if (tipoImpresion == 'PT' || tipoImpresion == 'SC') {
                connect(this.canvas, ipprinter, portprinter, urlPrinter);
            } else if (tipoImpresion == 'PI' || tipoImpresion == 'SP') {
                let printWindow: any = window.open('', '', 'height=600,width=800');

                if (printWindow == null) {
                    this.mensajeServer('warn', 'Popup Block está habilitado por favor agregar este sítio a las excepciones y que le permita imprmir', 'Advertencia Impresora')
                } else {
                    printWindow.document.write('<html>');
                    printWindow.document.write('<body>');
                    printWindow.document.write(nativehtml);
                    printWindow.document.write('</body></html>');
                    printWindow.document.close();
                    printWindow.print();
                    printWindow.close();
                }
            } else if (tipoImpresion == 'SE' && idComprobate != null) {
                this.api.getFileByIdComprobanteFromPath('api/comprobantes/comprobantessincabecera/pdf', idComprobate.toString()).subscribe((data) => {
                    var file = new Blob([data], { type: 'application/pdf' });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL, '_blank');
                }, error => {
                    this.mensajeError('No se puede descargar PDF');
                });
            }
        }, 500);
    }


}
