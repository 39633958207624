import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule, Http } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routes';
import 'rxjs/add/operator/toPromise';
import { MdcDialogModule } from '@angular-mdc/web';
import { HttpClientModule, HttpRequest, HttpEvent, HttpClient } from '@angular/common/http';
import { AccordionModule, SidebarModule, MessageService, ConfirmationService, ConfirmDialogModule } from 'primeng/primeng';
import { AutoCompleteModule } from 'primeng/primeng';
import { BreadcrumbModule } from 'primeng/primeng';
import { ButtonModule } from 'primeng/primeng';
import { CalendarModule } from 'primeng/primeng';
import { CarouselModule } from 'primeng/primeng';
import { ColorPickerModule } from 'primeng/primeng';
import { CheckboxModule } from 'primeng/primeng';
import { ChipsModule } from 'primeng/primeng';
import { CodeHighlighterModule } from 'primeng/primeng';
import { SharedModule } from 'primeng/primeng';
import { ContextMenuModule } from 'primeng/primeng';
import { DataGridModule } from 'primeng/primeng';
import { DataListModule } from 'primeng/primeng';
import { DataScrollerModule } from 'primeng/primeng';
import { DataTableModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';
import { DragDropModule } from 'primeng/primeng';
import { DropdownModule } from 'primeng/primeng';
import { EditorModule } from 'primeng/primeng';
import { FieldsetModule } from 'primeng/primeng';
import { FileUploadModule } from 'primeng/primeng';
import { GalleriaModule } from 'primeng/primeng';
import { GMapModule } from 'primeng/primeng';
import { InputMaskModule } from 'primeng/primeng';
import { InputSwitchModule } from 'primeng/primeng';
import { InputTextModule } from 'primeng/primeng';
import { InputTextareaModule } from 'primeng/primeng';
import { LightboxModule } from 'primeng/primeng';
import { ListboxModule } from 'primeng/primeng';
import { MegaMenuModule } from 'primeng/primeng';
import { MenuModule } from 'primeng/primeng';
import { MenubarModule } from 'primeng/primeng';
import { MessagesModule } from 'primeng/primeng'; 
import { MultiSelectModule } from 'primeng/primeng';
import { OrderListModule } from 'primeng/primeng';
import { OrganizationChartModule } from 'primeng/primeng';
import { OverlayPanelModule } from 'primeng/primeng';
import { PaginatorModule } from 'primeng/primeng';
import { PanelModule } from 'primeng/primeng';
import { PanelMenuModule } from 'primeng/primeng';
import { PasswordModule } from 'primeng/primeng';
import { PickListModule } from 'primeng/primeng';
import { ProgressBarModule } from 'primeng/primeng';
import { RadioButtonModule } from 'primeng/primeng';
import { RatingModule } from 'primeng/primeng';
import { ScheduleModule } from 'primeng/primeng';
import { SelectButtonModule } from 'primeng/primeng';
import { SlideMenuModule } from 'primeng/primeng';
import { SliderModule } from 'primeng/primeng';
import { SpinnerModule } from 'primeng/primeng';
import { SplitButtonModule } from 'primeng/primeng';
import { StepsModule } from 'primeng/primeng';
import { TabMenuModule } from 'primeng/primeng';
import { TabViewModule } from 'primeng/primeng';
import { TerminalModule } from 'primeng/primeng';
import { TieredMenuModule } from 'primeng/primeng';
import { ToggleButtonModule } from 'primeng/primeng';
import { ToolbarModule } from 'primeng/primeng';
import { TooltipModule } from 'primeng/primeng';
import { TreeModule } from 'primeng/primeng';
import { TreeTableModule } from 'primeng/primeng';
import { KeyFilterModule } from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { BlockUIModule } from 'primeng/blockui';
import { AppLoginComponent } from './app.login.component';
import { AppComponent } from './app.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppTopbarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { AppRightpanelComponent } from './app.rightpanel.component';
import { AppInlineProfileComponent } from './app.profile.component';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { LoginComponente } from './login/login.component';
import { LoginService } from './services/api/seguridad/login.service';
import { LogoutComponent } from './logout/logout.component';
import { UserInfoService, LoginInfoInStorage, } from './services/user-info.service';
import { AuthGuard } from './services/auth_guard.service';
import { ApiRequestService } from './services/api-request.service';
import { UsuariosService } from './services/api/seguridad/usuarios.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfig } from './app-config';
import { AppProperties } from './app.properties';
import { EstadoPipePipe } from './isa/pipes/estado-pipe.pipe';
import { EstadoPagoPipe } from './isa/pipes/estado-pago.pipe';
import { TipoMovimientoPipePipe } from './isa/pipes/tipo-movimiento-pipe.pipe';
import { DolarPipe } from './dolar.pipe';
import { RoundPipe } from './round.pipe';
// import { Ng2ImgToolsModule } from 'ng2-img-tools';
import { CaptchaModule } from 'primeng/captcha';
import { EstadoPipeSiNoPipe } from './isa/pipes/estado-pipeSiNo.pipe';
import { CdkTableModule } from '@angular/cdk/table';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CardModule } from 'primeng/card';

import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,

    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,  
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,

} from '@angular/material';
import { QuestionComponent } from './isa/question/question.component';

import { CurrencyMaskModule } from "ng2-currency-mask";

import { DateFormatPipe } from './isa/pipes/dateFormatPipe';

import { UtilService } from './services/util.service';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import stock from 'highcharts/modules/stock.src';
import exporting from 'highcharts/modules/exporting.src';
import * as more from 'highcharts/highcharts-3d.src';
import * as moreh from 'highcharts/highcharts-more.src';
import { ExcelService } from './services/excel.service';
import { DataViewModule } from 'primeng/dataview';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ToastModule } from 'primeng/toast';
import { BienvenidaComponent } from './isa/bienvenida/bienvenida.component';
import { CantonesServicios } from './services/cantonesServicio';
import { UsuarioComponent } from './logica/administracion/usuario/usuario.component';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { EnumService } from './services/enum.service';
import { CronComponent } from './cron/cron.component';
import { CategoriaComponent } from './logica/inventario/categoria/categoria.component';
import { CategoriaFormularioComponent } from './logica/inventario/categoria-formulario/categoria-formulario.component';
import { UbicacionComponent } from './logica/configuracion/ubicacion/ubicacion.component';
import { UbicacionFormularioComponent } from './logica/configuracion/ubicacion-formulario/ubicacion-formulario.component';
import { MarcaComponent } from './logica/configuracion/marca/marca.component';
import { MarcaFormularioComponent } from './logica/configuracion/marca-formulario/marca-formulario.component';
import { ModeloComponent } from './logica/configuracion/modelo/modelo.component';
import { ModeloFormularioComponent } from './logica/configuracion/modelo-formulario/modelo-formulario.component';
import { ActivoComponent } from './logica/inventario/activo/activo.component';
import { ActivoFormularioComponent } from './logica/inventario/activo-formulario/activo-formulario.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ActaComponent } from './logica/inventario/acta/acta.component';
import { ActaFormularioComponent } from './logica/inventario/acta-formulario/acta-formulario.component';
import { SecuenciaComprobanteComponent } from './logica/administracion/secuencia-comprobante/secuencia-comprobante.component';
import { PrestamoDevolucionComponent } from './logica/inventario/prestamo-devolucion/prestamo-devolucion.component';
import { PrestamoDevolucionFormularioComponent } from './logica/inventario/prestamo-devolucion-formulario/prestamo-devolucion-formulario.component';
import { StatusComprobantePrestamoPipe } from './logica/pipes/status-comprobante-prestamo.pipe';
import { SalidaEquipoComponent } from './logica/inventario/salida-equipo/salida-equipo.component';
import { SalidaEquipoFormularioComponent } from './logica/inventario/salida-equipo-formulario/salida-equipo-formulario.component';
import { StatusComprobanteSalidaEquiposPipe } from './logica/pipes/status-comprobante-salida-equipos.pipe';
import { ActivosAsignadoComponent } from './logica/inventario/activos-asignado/activos-asignado.component';
import { BajaEquipoComponent } from './logica/inventario/baja-equipo/baja-equipo.component';
import { BajaEquipoFormularioComponent } from './logica/inventario/baja-equipo-formulario/baja-equipo-formulario.component';
import { ReporteComponent } from './isa/reportes/reporte/reporte.component';
import { QrComponent } from './qr/qr.component';
import { FirmaComponent } from './firma/firma.component';
import { ImpresionQrComponent } from './logica/inventario/impresion-qr/impresion-qr.component';
import { ConfiguracionActaERComponent } from './logica/administracion/configuracion-acta-er/configuracion-acta-er.component';
import { ConfiguracionActaERFormularioComponent } from './logica/administracion/configuracion-acta-erformulario/configuracion-acta-erformulario.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ImpuestoComponent } from './logica/configuracion/impuesto/impuesto.component';
import { ImpuestoFormularioComponent } from './logica/configuracion/impuesto-formulario/impuesto-formulario.component';
import { TablaAmortizacionComponent } from './logica/configuracion/tabla-amortizacion/tabla-amortizacion.component';
import { TablaAmortizacionFormularioComponent } from './logica/configuracion/tabla-amortizacion-formulario/tabla-amortizacion-formulario.component';
import { ActaOtrosComponent } from './logica/inventario/acta-otros/acta-otros.component';
import { ActaOtrosFormularioComponent } from './logica/inventario/acta-otros-formulario/acta-otros-formulario.component';
registerLocaleData(es);

@NgModule({

    imports: [
        ConfirmDialogModule,
        ToastModule,
        DataViewModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        CdkTableModule,
        MatTableModule,
        MatPaginatorModule,
        CaptchaModule,
        // Ng2ImgToolsModule,
        MdcDialogModule,
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CarouselModule,
        ColorPickerModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        CardModule,
        SharedModule,
        ContextMenuModule,
        DataGridModule,
        DataListModule,
        DataScrollerModule,
        DataTableModule,
        MatDatepickerModule,
        MatNativeDateModule,
        DialogModule,
        DragDropModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        GMapModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KeyFilterModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScheduleModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        ReactiveFormsModule,
        TableModule,
        CurrencyMaskModule,
        NgxBarcodeModule,
        SidebarModule,
        BlockUIModule,
        QRCodeModule,
        SignaturePadModule
    ],
    declarations: [

        AppLoginComponent,
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppRightpanelComponent,
        AppInlineProfileComponent,
        DashboardDemoComponent,
        LoginComponente,
        LogoutComponent,
        EstadoPipePipe,
        EstadoPipeSiNoPipe,
        TipoMovimientoPipePipe,
        EstadoPagoPipe,
        DateFormatPipe,
        StatusComprobantePrestamoPipe,
        StatusComprobanteSalidaEquiposPipe,
        DolarPipe,
        RoundPipe,
        QuestionComponent,
        BienvenidaComponent,
        UsuarioComponent,
        CronComponent,
        CategoriaComponent,
        CategoriaFormularioComponent,
        UbicacionComponent,
        UbicacionFormularioComponent,
        MarcaComponent,
        MarcaFormularioComponent, 
        ModeloComponent,
        ModeloFormularioComponent,
        ActivoComponent,
        ActivoFormularioComponent,
        ActaComponent,
        ActaOtrosComponent,
        ActaFormularioComponent,
        ActaOtrosFormularioComponent,
        SecuenciaComprobanteComponent,
        PrestamoDevolucionComponent,
        PrestamoDevolucionFormularioComponent,
        SalidaEquipoComponent,
        SalidaEquipoFormularioComponent,
        ActivosAsignadoComponent,
        BajaEquipoComponent,
        BajaEquipoFormularioComponent,
        ReporteComponent,
        QrComponent,
        ImpresionQrComponent,
        ConfiguracionActaERComponent,
        ConfiguracionActaERFormularioComponent,
        FirmaComponent,
        ImpuestoComponent,
        ImpuestoFormularioComponent,
        TablaAmortizacionComponent, 
        TablaAmortizacionFormularioComponent
       
    ],
    entryComponents: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: LOCALE_ID, useValue: "es-ES" },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
         CantonesServicios,  LoginService, LogoutComponent,
        UserInfoService, AuthGuard, ApiRequestService, AppConfig, MessageService, AppProperties, UsuariosService, TranslateService,  UtilService,
        EnumService, ConfirmationService,
        { provide: HIGHCHARTS_MODULES, useFactory: () => [more, stock, exporting, moreh] },
        ExcelService

    ],
    bootstrap: [AppLoginComponent]
}
)

export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/i18n/', '.json')
};


    // export class WebpackTranslateLoader implements TranslateLoader {
    //     getTranslation(lang: string): Observable<any> {
    //       return Observable.fromPromise(System.import(`../assets/i18n/${lang}.json`));
    //     }
    //   }