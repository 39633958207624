import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';


@Component({
  selector: 'app-modelo-formulario',
  templateUrl: './modelo-formulario.component.html',
  styleUrls: ['./modelo-formulario.component.css'],
  providers: [ConfirmationService] 
})
export class ModeloFormularioComponent implements OnInit {

    /**
   * CAMPOS
   */
  @Output() public enviarPadre = new EventEmitter();
  @Input() formulario: FormGroup;
  tituloHTML = '';
  marcas: any[] =[];

  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.model');    
    this.cargaInicialMarcas();
  }


  cargaInicialMarcas() {
    if (this.marcas == null || this.marcas.length == 0) {
      this.marcas = [];
      this.api.get('marca/listaCompleta', this.tituloHTML).subscribe(res=> this.marcas = res);
    }
  }

  guardar() {
    if (this.formulario.valid) {
      this.api.post('modelo/guardar', this.asignarEntidad(), this.tituloHTML).subscribe(res => {

        this.appComponent.mensaje(res.operationStatus, res.operationMessage);
        if (res.operationStatus == 'SUCCESS') {
          this.enviarPadre.emit({ esNuevo: false });
        }
      });

    } else {
      this.api.mensajeError(this.api.mensajeI18('validation.invalidForm'));
      Object.keys(this.formulario.controls).forEach(field => { 
        const control = this.formulario.get(field);
        control.markAsDirty({ onlySelf: true });            
        control.markAsTouched({ onlySelf: true });       
      });

    }
  }

  eliminar() {
    this.confirmationService.confirm({
      message: this.appComponent.properties.mensajeConfirmacionEliminar,
      accept: () => {
        this.api.delete('modelo/eliminar/' + this.formulario.get('id').value , this.tituloHTML).subscribe(res => {
          if (res !== undefined && res !== null && res.operationStatus === "SUCCESS") {
            this.enviarPadre.emit({ esNuevo: false });
          }
          this.appComponent.mensajeServer(res.operationStatus, res.operationMessage, this.tituloHTML);
        });
      }
    });
  }

  cancelar() {
    this.enviarPadre.emit({ esNuevo: false });
  }

  asignarEntidad() {
    const entidad = {
      id: this.formulario.get('id').value,
      nombre: this.formulario.get('nombre').value,
      marca: this.formulario.get('marca').value
    }

    console.log('Entidad');
    console.log(entidad);

    return entidad;
  }

}
