import { Activo } from "./activo";


export class Mantenimiento {

    id:number;
    fechaCreacion: Date;    
    fechaFinalizacion: Date;    
    estatus:number;
    descripcion:string;
    costo: number;
    documento:string;
    estatusNombre:string;
    imagenDocumentoAsociado:string;
    documentoAsociadoRuta:string;
    editarDocumento:boolean;
}