import { Activo } from "./activo";

export class ComprobanteDetalle {
    id: number;
    linea: number;
    activo: Activo;
    fechaEntrega: Date;
    observacionEntrega: string;
    fechaRecepcion: Date;
    observacionRecepcion: string;
    estado: boolean = true; 
    estatus: number;   
}