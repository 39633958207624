import { Component, OnInit, NgZone } from '@angular/core';
import {  trigger, state, transition, style, animate, } from '@angular/animations';
import { UserInfoService, LoginInfoInStorage, } from './services/user-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms'
import { LazyLoadEvent } from 'primeng/primeng';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';
import { FileUpload } from 'primeng/primeng';
import { DomSanitizer, BrowserModule } from '@angular/platform-browser';
 
import { SelectItem } from 'primeng/primeng';
import { ApiRequestService } from './services/api-request.service';
import { AppComponent } from './app.component';
// import { Ng2ImgToolsService } from 'ng2-img-tools';// <-- import the module
import { Subject } from 'rxjs/Rx';

@Component({
    selector: 'app-inline-profile',
    template: `
        <div class="profile" [ngClass]="{'profile-expanded':active}">
            <a href="#" (click)="onClick($event)">
                <img class="profile-image" [src]="imagen" /> 
                <span class="profile-name">{{usuario}}</span>
                <i class="material-icons">keyboard_arrow_down</i>
            </a>
        </div>

        <ul class="ultima-menu profile-menu" [@menu]="active ? 'visible' : 'hidden'">
    
            <li role="menuitem" >
                <a  href="#" (click)="logout()" class="ripplelink" [attr.tabindex]="!active ? '-1' : null" >
                    <i class="material-icons" style="color:red">power_settings_new</i>
                    <span>Cerrar Sesión</span>
                </a>
            </li>
            <div style="display:none"> 
            <form method="POST" enctype="multipart/form-data">
            <input id="photoid" accept="image/*" maxFileSize="1000000" type="file" name="photoid" (change)="cargarFoto($event)">
            </form>
            </div>
        </ul>
    `,
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppInlineProfileComponent implements OnInit {

    active: boolean;

    usuario: string = '';
    path: string = '';
    imagen: any = '';
    imgdata: FormData;
    uploadedFiles: any[] = [];


    constructor(public userInfoService: UserInfoService, private router: Router, private api: ApiRequestService, private domSanitizer: DomSanitizer, public appComponent: AppComponent, 
         public apiRequest: ApiRequestService) {
    }

    ngOnInit() {
        this.usuario = this.userInfoService.getUserName();
        this.path = this.userInfoService.getPathImagen();
        this.imagen = "assets/layout/images/Perfil.png"
        this.cargarImagen();


    }
    cambiarAgencia() {
        this.appComponent.overlayMenuActive = false;
        this.appComponent.myDialogAgencia.show();

    }
    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }
    cargarImagen() {


        if (this.path != null && this.path != '') {
            this.imagen = this.domSanitizer.bypassSecurityTrustUrl(this.path);
        }
        else {

            this.imagen = "assets/layout/images/Perfil.png"
        }

    }
    logout() {
        let resultado: Subject<any> = new Subject<any>();
        this.apiRequest.postLogout('/auth/logout/')
            .subscribe(respuesta => {
                if (respuesta !== undefined && respuesta !== null) {
                    resultado.next(respuesta);
                }
            }, error => {
                resultado.next(error);
            });
        this.userInfoService.removeUserInfo();
        this.router.navigate(['/login']);
    }

    abrirFile() {
        let element: HTMLElement = document.getElementById('photoid') as HTMLElement;
        element.click();
    }


    cargarFoto(event) {

        this.imgdata = new FormData();
        var file = event.target.files[0];

        var url = URL.createObjectURL(event.target.files[0]);
        this.imagen = this.domSanitizer.bypassSecurityTrustUrl(url);
        file.objectURL = this.domSanitizer.bypassSecurityTrustUrl(url);

        // if (file.size > 1000000) {
        //     this.ng2ImgToolsService.resizeImage(file, 500, 500).subscribe((result) => {
        //         var url2 = URL.createObjectURL(result);
        //         result.objectURL = this.domSanitizer.bypassSecurityTrustUrl(url2);
        //         var file2 = new File([result], result.name);
        //         this.imgdata.append('file', file2);
        //         this.api.postMultipartFile('api/seguridad/usuario/cambiarImagen', this.imgdata, 'profile').subscribe(res => {
        //             if (res !== undefined && res !== null) {}
        //             this.appComponent.mensaje(res.operationStatus, res.operationMessage);
        //         });
        //     });
        // }
        // else {
            file.objectURL = this.domSanitizer.bypassSecurityTrustUrl(url);
            this.imgdata.append('file', file);
            this.api.postMultipartFile('api/seguridad/usuario/cambiarImagen', this.imgdata, 'profile').subscribe(res => {
                if (res !== undefined && res !== null) {}
                this.appComponent.mensaje(res.operationStatus, res.operationMessage);
            });
        // }
    }


}

