import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { OperationResponse } from '../isa/common/operationResponse';
import { isNumber } from 'util';
import { ResponseStatusEnum } from '../isa/domains/responseStatusEnum';
import { ApiRequestService } from '../../app/services/api-request.service';
import { Validators } from '@angular/forms/src/validators';
import { PatternValidator } from '@angular/forms/src/directives/validators';

declare var moment: any;


@Injectable()
export class UtilService {
    constructor(private api: ApiRequestService
    ) { moment.locale('es'); }

    listaDiasSemanaPorFecha(fecha: string): any[] {
        let resultado = [];
        resultado.push('Dom ' + moment(fecha).day(0).format("DD-MM-YYYY"));
        resultado.push('Lun ' + moment(fecha).day(1).format("DD-MM-YYYY"));
        resultado.push('Mar ' + moment(fecha).day(2).format("DD-MM-YYYY"));
        resultado.push('Mie ' + moment(fecha).day(3).format("DD-MM-YYYY"));
        resultado.push('Jue ' + moment(fecha).day(4).format("DD-MM-YYYY"));
        resultado.push('Vie ' + moment(fecha).day(5).format("DD-MM-YYYY"));
        resultado.push('Sab ' + moment(fecha).day(6).format("DD-MM-YYYY"));
        return resultado;
    }

    listaDiasPorMes(fecha: string): any[] {

        let resultado = [];
        let ultimoDia: number = Number(moment(fecha).endOf('month').format('DD'));
        let mesAnio = moment(fecha).format('ddd');
        for (var index = 1; index <= ultimoDia; index++) {
            resultado.push(moment(fecha).date(index).format('ddd') + ' ' + this.rellenar('0', 2, index.toString()));
        }
        return resultado;
    }

    rellenar(caracterRellenar, longitudFinal: number, dato: string): string {

        if (dato.length < longitudFinal) {
            let diferencia = longitudFinal - dato.length;

            for (var index = 0; index < diferencia; index++) {
                dato = caracterRellenar + dato;
            }
        }

        return dato;
    }


    fechaEnDate(value: any): Date | null {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
          const str = value.split('/');
    
          const year = Number(str[2]);
          const month = Number(str[1]) - 1;
          const date = Number(str[0]);
    
          return new Date(year, month, date);
        } else if((typeof value === 'string') && value === '') {
          return new Date();
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
      }


    validarCodigoEstablecimiento(numero: String): OperationResponse {
        var respuesta: OperationResponse = new OperationResponse();

        if (Number(numero) != 0) {
            respuesta.operationStatus = ResponseStatusEnum.SUCCESS;
        } else {
            respuesta.operationStatus = ResponseStatusEnum.WARN;
            respuesta.operationMessage = this.api.mensajeI18('validation.ruc.error');
        }

        return respuesta;
    }


    diferenciaFechaActual(fecha: Date): string {
        let respuesta:string = '';
        var starts = moment(fecha);
        var ends   = moment();
        var diferencia = moment.duration(ends.diff(starts));
        if(diferencia._data != null){
            let resultado = diferencia._data;
            respuesta = resultado.years+" Años, "+ resultado.months+" Meses, "+ resultado.days+" Días "  ;
        }
        return respuesta;
    }



}