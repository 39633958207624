import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { ActivoComponenteCategoria } from '../../../logica/clases/activoComponenteCategoria';
import { ComponenteCategoria } from '../../../logica/clases/componenteCategoria';
import { Componente } from '../../../logica/clases/componente';
import { Categoria } from '../../../isa/domains/categoria';
import { EnmTipoValor } from '../../../logica/enum/enmTipoValor';
import { Activo } from '../../../logica/clases/activo';
import { Mantenimiento } from '../../../logica/clases/mantenimiento';
import { EnmEstatusMantenimiento } from '../../../logica/enum/enmEstatusMantenimiento';
import { ExcelService } from '../../../services/excel.service';
import { DatePipe } from '@angular/common';
import { EnmEstatusActaEntregaRecepcionComprobanteDetalle } from '../../../logica/enum/enmEstatusActaEntregaRecepcionComprobanteDetalle';
import { EnmStatusActaEntregaRecepcionComprobante } from '../../../logica/enum/enmStatusActaEntregaRecepcionComprobante';
import { EnmTipoComprobante } from '../../../logica/enum/enmTipoComprobante';
import { EnmEstatusPrestamoComprobanteDetalle } from '../../../logica/enum/enmEstatusPrestamoComprobanteDetalle';
import { EnmStatusPrestamoComprobante } from '../../../logica/enum/enmStatusPrestamoComprobante';
import { iM } from '@angular/core/src/render3';


@Component({
  selector: 'app-activo-formulario',
  templateUrl: './activo-formulario.component.html',
  styleUrls: ['./activo-formulario.component.css', './activo-formulario.component.scss'],
  providers: [ConfirmationService, DatePipe]
})
export class ActivoFormularioComponent implements OnInit {

  /**
   * CAMPOS
   */
  @Output() public enviarPadre = new EventEmitter();
  @Input() formulario: FormGroup;
  tituloHTML = '';
  mostrarCategoria: boolean = false;
  interfazPropia: boolean = true;

  marcas: any[] = [];
  modelos: any[] = [];
  impuestos: any[] = [];
  imagen: any = '';
  archivosImagen: any[] = [];
  archivosFacturaCompra: any[] = [];
  archivosMantenimiento: any[] = [];

  editarImagen = false;
  editarFacturaCompra = false;
  ubicaciones: any[] = [];
  archivos: FormData;
  listaActivoComponenteCategoria: ActivoComponenteCategoria[] = [];
  listaMantenimientos: Mantenimiento[] = [];
  mostrarMantenimientos: boolean = false;
  mostrarAsignaciones: boolean = false;
  asignaciones: any[] = [];
  mostrarPrestamos: boolean = false;
  prestamos: any[] = [];
  es: any;
  qrdata = '';
  totalCompra = 0;


  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private confirmationService: ConfirmationService,
    private http: Http,
    private excelService: ExcelService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.es = this.appComponent.idiomaCalendario;
    this.tituloHTML = this.api.mensajeI18('common.active');
    this.cargaInicialImpuestos();
    this.cargaInicialMarcas();
    this.cargaInicialUbicaciones();
    this.archivos = new FormData();

    if (this.formulario.get('id').value != null && this.formulario.get('id').value > 0) {
      this.api.get('modelo/listaPorMarca/' + this.formulario.get('marca').value.id, this.tituloHTML).subscribe(res => this.modelos = res);
      this.cargaActivoComponenteCategoria();
      this.cargaMantenimientos();
      this.qrdata = this.api.appConfig.apiProtocol + "//" + this.api.appConfig.apiHostName + ':' + this.api.appConfig.apiPortFront + '/#/qr/' + this.formulario.get('id').value;
      this.calcularTotalCompra();
    }


  }


  cargaActivoComponenteCategoria() {
    this.api.post('activoComponenteCategoria/listaPorActivoYCategoria', { idActivo: this.formulario.get('id').value, idCategoria: this.formulario.get('categoria').value.id }, this.tituloHTML).subscribe(async res => {
      this.listaActivoComponenteCategoria = [];
      if (res != null && res.data != null && res.data.length > 0) {

        res.data.forEach(item => {
          let acc: ActivoComponenteCategoria = new ActivoComponenteCategoria();
          acc.id = item.id;
          acc.tipoValor = item.tipoValor;
          acc.valorTexto = item.valorTexto;
          acc.valorNumero = item.valorNumero;
          if (acc.tipoValor == EnmTipoValor.Texto) {
            acc.valor = acc.valorTexto;
          } else if (acc.tipoValor == EnmTipoValor.Numero) {
            acc.valor = acc.valorNumero;
          }
          acc.activo = new Activo();
          acc.activo.id = this.formulario.get('id').value;
          acc.componenteCategoria = new ComponenteCategoria();
          acc.componenteCategoria.id = item.idComponenteCategoria;
          acc.componenteCategoria.estado = item.estadoComponenteCategoria;
          acc.componenteCategoria.componente = new Componente();
          acc.componenteCategoria.componente.id = item.idComponente;
          acc.componenteCategoria.componente.nombre = item.nombreComponente;
          acc.componenteCategoria.componente.tipoValor = item.tipoValorComponente;
          acc.componenteCategoria.componente.estado = item.estadoComponente;
          acc.componenteCategoria.categoria = new Categoria();
          acc.componenteCategoria.categoria.id = item.idCategoria;
          acc.componenteCategoria.categoria.nombre = item.nombreCategoria;
          acc.componenteCategoria.categoria.estado = item.estadoCategoria;
          this.listaActivoComponenteCategoria.push(acc);
        });


        await this.espera(1000);

        this.api.post('componenteCategoria/listaComponentesPorCategoria', { idCategoria: this.formulario.get('categoria').value.id }, this.tituloHTML).subscribe(res => {
          if (res != null && res.data != null && res.data.length > 0) {
            res.data.forEach(item => {
              if (this.listaActivoComponenteCategoria.filter(x => x.componenteCategoria.id == item.id).length === 0) {
                let acc: ActivoComponenteCategoria = new ActivoComponenteCategoria();
                acc.id = null;
                acc.componenteCategoria = new ComponenteCategoria();
                acc.componenteCategoria.id = item.id;
                acc.componenteCategoria.estado = true;
                acc.componenteCategoria.componente = new Componente();
                acc.componenteCategoria.componente.id = item.idComponente;
                acc.componenteCategoria.componente.nombre = item.nombreComponente;
                acc.componenteCategoria.componente.tipoValor = item.tipoValorComponente;
                acc.componenteCategoria.categoria = new Categoria();
                acc.componenteCategoria.categoria.id = this.formulario.get('categoria').value.id;
                acc.componenteCategoria.categoria.nombre = this.formulario.get('categoria').value.nombre;
                acc.componenteCategoria.estado = true;
                acc.tipoValor = item.tipoValorComponente;
                this.listaActivoComponenteCategoria.push(acc);
              }
            });
          }
        }
        );



      } else {
        this.api.post('activoComponenteCategoria/eliminarPorActivo', { idActivo: this.formulario.get('id').value }, this.tituloHTML).subscribe(res => {
          console.log('Eliminar');
          console.log(res);
        });
        this.creacionActivoComponenteCategorias(this.formulario.get('categoria').value.id, this.formulario.get('categoria').value.nombre);
      }
    });
  }



  espera(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  cargaMantenimientos() {
    this.api.post('mantenimiento/listaPorActivo', { idActivo: this.formulario.get('id').value }, this.tituloHTML).subscribe(res => {

      if (res != null && res.data != null && res.data.length > 0) {
        this.listaMantenimientos = [];
        res.data.forEach(item => {
          let m: Mantenimiento = new Mantenimiento();
          m.id = item.id;
          m.fechaCreacion = item.fechaCreacion != null ? new Date(item.fechaCreacion) : null;
          m.fechaFinalizacion = item.fechaFinalizacion != null ? new Date(item.fechaFinalizacion) : null;
          m.estatus = item.estatus;
          m.descripcion = item.descripcion;
          m.costo = item.costo;
          m.documento = item.documento;
          m.estatusNombre = m.estatus == EnmEstatusMantenimiento.EnMantenimiento ? EnmEstatusMantenimiento.EnMantenimientoNombre : m.estatus == EnmEstatusMantenimiento.MantenimientoRealizado ? EnmEstatusMantenimiento.MantenimientoRealizadoNombre : '';
          m.imagenDocumentoAsociado = (item.documento == null || item.documento == '') ? 'assets/isa/images/sin_archivo.png' : 'assets/isa/images/archivo.png';
          m.documentoAsociadoRuta = (item.documento == null || item.documento == '') ? '' : this.api.appConfig.servidorMantenimiento + item.documento;
          m.editarDocumento = false;
          this.listaMantenimientos.push(m);
        });
      }
    });
  }

  cargaInicialMarcas() {
    if (this.marcas == null || this.marcas.length == 0) {
      this.marcas = [];
      this.api.get('marca/listaCompleta', this.tituloHTML).subscribe(res => this.marcas = res);
    }
  }

  cargaInicialImpuestos() {
    if (this.impuestos == null || this.impuestos.length == 0) {
      this.impuestos = [];
      this.api.get('impuesto/listaCompleta', this.tituloHTML).subscribe(res => this.impuestos = res);
    }
  }

  cargaInicialUbicaciones() {
    if (this.ubicaciones == null || this.ubicaciones.length == 0) {
      this.ubicaciones = [];
      this.api.get('ubicacion/listaCorta', this.tituloHTML).subscribe(res => this.ubicaciones = res);
    }
  }

  agregarCategoria() {
    this.mostrarCategoria = true;
    this.interfazPropia = true;
  }

  seleccionarMarca($evento) {
    if ($evento != null && $evento.value != null && $evento.value.id != null) {
      this.modelos = [];
      this.api.get('modelo/listaPorMarca/' + $evento.value.id, this.tituloHTML).subscribe(res => this.modelos = res);
    }
  }


  calcularTotalCompra() {
    let impuestoSeleccionado = this.formulario.get('impuesto').value;
    let subtotalCompraSeleccionado = this.formulario.get('precioSubtotalCompra').value;

    if(impuestoSeleccionado && impuestoSeleccionado.porcentaje && subtotalCompraSeleccionado){
      this.totalCompra = subtotalCompraSeleccionado * (1 + impuestoSeleccionado.porcentaje /100);
    }else{
      this.totalCompra = 0;
    }
  }

  cargarAmortizacion(idCategoria) {
    return new Promise((resolve, reject) => {
      this.api.get(`tablaAmortizacion/dias/${idCategoria}`, this.tituloHTML).subscribe(data => resolve(data));
    });
  }


  async calcularFechaAmortizacion(){
    let categoriaSeleccionada = this.formulario.get('categoria').value;
    let fechaCompraSeleccionada = this.formulario.get('fechaCompra').value;

    if(categoriaSeleccionada && categoriaSeleccionada.id && fechaCompraSeleccionada) {

      let diasAmortizacion:number = await this.cargarAmortizacion(categoriaSeleccionada.id) as number;

      let nuevaFecha: Date = new Date(fechaCompraSeleccionada);
      nuevaFecha.setDate(fechaCompraSeleccionada.getDate() + (diasAmortizacion * 365));

      this.formulario.patchValue({fechaAmortizacion: nuevaFecha});

    }

  }

  guardar() {
    this.appComponent.loader = true;
    if (this.formulario.valid) {
      this.archivos.append('entidad', new Blob([JSON.stringify(this.asignarEntidad())],
        { type: "application/json" }));
      this.api.postMultipartFile('activo/guardar', this.archivos, this.tituloHTML).subscribe(res => {
        this.appComponent.mensaje(res.operationStatus, res.operationMessage);
        if (res.operationStatus == 'SUCCESS') {
          this.appComponent.loader = false;
          this.enviarPadre.emit({ esNuevo: false });
        }
        this.appComponent.loader = false;
      }, error => { this.appComponent.loader = false });
    } else {
      this.api.mensajeError(this.api.mensajeI18('validation.invalidForm'));
      Object.keys(this.formulario.controls).forEach(field => {
        console.log('Campo');
        console.log(field);
        const control = this.formulario.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.appComponent.loader = false;
    }
  }

  cancelar() {
    this.enviarPadre.emit({ esNuevo: false });
  }

  asignarEntidad() {
    const entidad = {
      id: this.formulario.get('id').value,
      ubicacion: this.formulario.get('ubicacion').value,
      categoria: this.formulario.get('categoria').value,
      modelo: this.formulario.get('modelo').value,
      serie: this.formulario.get('serie').value,
      nav: this.formulario.get('nav').value,
      fechaCompra: this.formulario.get('fechaCompra').value,
      fechaFinGarantia: this.formulario.get('fechaFinGarantia').value,
      descripcion: this.formulario.get('descripcion').value,
      serialTic: this.formulario.get('serialTic').value,
      fechaInicioUso: this.formulario.get('fechaInicioUso').value,
      imagen: this.formulario.get('imagen').value,
      facturaCompra: this.formulario.get('facturaCompra').value,
      activoComponenteCategorias: this.listaActivoComponenteCategoria,
      mantenimientos: this.listaMantenimientos,
      habilitadoPrestamo: this.formulario.get('habilitadoPrestamo').value,
      fechaAmortizacion: this.formulario.get('fechaAmortizacion').value,
      precioSubtotalCompra: this.formulario.get('precioSubtotalCompra').value,
      impuesto: this.formulario.get('impuesto').value
    }
    return entidad;
  }

  editarImagenGuardada() {
    this.editarImagen = true;
  }

  editarFacturaCompraGuardada() {
    this.editarFacturaCompra = true;
  }

  cargarImagen(event) {

    this.archivosImagen = [];

    for (let file of event.files) {
      this.archivosImagen.push(file);
    }
    this.archivos.append('imagen', this.archivosImagen[0]);
    this.appComponent.mensajeInformacion(this.api.mensajeI18('actions.successUploadFile'));
  }


  cargaFacturaCompra(event) {
    for (let file of event.files) {
      this.archivosFacturaCompra.push(file);
    }
    this.archivos.append('facturaCompra', this.archivosFacturaCompra[0]);
    this.appComponent.mensajeInformacion(this.api.mensajeI18('actions.successUploadFile'));
  }


  cargarArchivoMantenimiento(event, mantenimiento) {

    for (let file of event.files) {
      this.archivosMantenimiento.push(file);
    }
    if (mantenimiento != null && mantenimiento.id > 0) {
      let nombreArchivo = 'mantenimiento' + mantenimiento.id;
      this.archivos.append(nombreArchivo, this.archivosMantenimiento[0]);
    } else {
      this.archivos.append('mantenimiento', this.archivosMantenimiento[0]);
    }
    this.appComponent.mensajeInformacion(this.api.mensajeI18('actions.successUploadFile'));

  }


  creacionActivoComponenteCategorias($idCategoria, $nombreCategoria) {
    this.api.post('componenteCategoria/listaComponentesPorCategoria', { idCategoria: $idCategoria }, this.tituloHTML).subscribe(res => {
      if (res != null && res.data != null && res.data.length > 0) {
        this.listaActivoComponenteCategoria = [];

        res.data.forEach(item => {
          let acc: ActivoComponenteCategoria = new ActivoComponenteCategoria();
          acc.id = null;
          acc.componenteCategoria = new ComponenteCategoria();
          acc.componenteCategoria.id = item.id;
          acc.componenteCategoria.estado = true;
          acc.componenteCategoria.componente = new Componente();
          acc.componenteCategoria.componente.id = item.idComponente;
          acc.componenteCategoria.componente.nombre = item.nombreComponente;
          acc.componenteCategoria.componente.tipoValor = item.tipoValorComponente;
          acc.componenteCategoria.categoria = new Categoria();
          acc.componenteCategoria.categoria.id = $idCategoria;
          acc.componenteCategoria.categoria.nombre = $nombreCategoria;
          acc.componenteCategoria.estado = true;
          acc.tipoValor = item.tipoValorComponente;
          this.listaActivoComponenteCategoria.push(acc);
        });
      }
    }
    );
  }


  agregarMantenimiento() {

    let cantidadMantenimientosActivos = this.listaMantenimientos.filter(x => x.estatus == EnmEstatusMantenimiento.EnMantenimiento).length;

    if (cantidadMantenimientosActivos == 0) {
      let mantenimiento: Mantenimiento = new Mantenimiento();
      mantenimiento.id = null;
      mantenimiento.fechaCreacion = new Date();
      mantenimiento.fechaFinalizacion = null;
      mantenimiento.costo = 0;
      mantenimiento.descripcion = '';
      mantenimiento.estatus = EnmEstatusMantenimiento.EnMantenimiento;
      mantenimiento.estatusNombre = EnmEstatusMantenimiento.EnMantenimientoNombre;
      mantenimiento.documento = '';
      this.listaMantenimientos.push(mantenimiento);
    } else {
      this.appComponent.mensajeAdvertencia('Ya existe un mantenimiento activo. Para agregar un nuevo mantenimiento es necesario cerrar los mantenimientos anteriores.');
    }

  }


  cambiarEstatus(mantenimiento: Mantenimiento) {
    if (mantenimiento.estatus == EnmEstatusMantenimiento.EnMantenimiento) {
      mantenimiento.estatus = EnmEstatusMantenimiento.MantenimientoRealizado;
      mantenimiento.estatusNombre = EnmEstatusMantenimiento.MantenimientoRealizadoNombre;

      if (mantenimiento.fechaFinalizacion == null) {
        mantenimiento.fechaFinalizacion = new Date();
      }

    } else if (mantenimiento.estatus == EnmEstatusMantenimiento.MantenimientoRealizado) {
      mantenimiento.estatus = EnmEstatusMantenimiento.EnMantenimiento;
      mantenimiento.estatusNombre = EnmEstatusMantenimiento.EnMantenimientoNombre;
    }
  }

  verOcultaMantenimientos() {
    if (this.mostrarMantenimientos) {
      this.mostrarMantenimientos = false;
    } else {
      this.mostrarMantenimientos = true;
    }
  }

  verOcultaAsignaciones() {
    if (this.mostrarAsignaciones) {
      this.mostrarAsignaciones = false;
    } else {
      this.mostrarAsignaciones = true;
      if (this.asignaciones == null || this.asignaciones.length == 0) {
        this.api.get('comprobanteDetalle/listaPorActivo/' + this.formulario.get('id').value + '/' + EnmTipoComprobante.ActaEntregaRecepcion, this.tituloHTML).subscribe(res => {
          console.log('asignaciones');
          console.log(res);
          if (res != null) {
            this.asignaciones = res;
            this.asignaciones.forEach(as => {
              as.nombreEstatus = as.estatus == EnmEstatusActaEntregaRecepcionComprobanteDetalle.Entregado ? 'Entregado' :
                as.estatus == EnmEstatusActaEntregaRecepcionComprobanteDetalle.Recepcionado ? 'Devuelto' : '';
              as.nombreStatusActa = as.statusComprobante == EnmStatusActaEntregaRecepcionComprobante.EquiposAsignados ? 'Equipos Asignados' :
                as.statusComprobante == EnmStatusActaEntregaRecepcionComprobante.EquiposEntregados ? 'Equipos Devueltos' : '';
            });
          }
        });
      }
    }
  }

  descargarMantenimientos() {
    this.appComponent.loader = true;
    let informacionExportacion: any[] = [];

    this.api.post('mantenimiento/listaPorActivo', { idActivo: this.formulario.get('id').value }, this.tituloHTML).subscribe(res => {
      if (res != null && res.data != null && res.data.length > 0) {
        res.data.forEach(item => {
          informacionExportacion.push({
            'Fecha de Inicio': this.datePipe.transform(item.fechaCreacion, 'dd-MM-yyyy'),
            'Fecha Fin': (item.fechaFinalizacion != null && item.fechaFinalizacion != '') ? this.datePipe.transform(item.fechaFinalizacion, 'dd-MM-yyyy') : '',
            Costo: item.costo,
            'Descripción': item.descripcion,
            Estado: item.estatus == EnmEstatusMantenimiento.EnMantenimiento ? EnmEstatusMantenimiento.EnMantenimientoNombre : item.estatus == EnmEstatusMantenimiento.MantenimientoRealizado ? EnmEstatusMantenimiento.MantenimientoRealizadoNombre : ''
          })
        });
        this.excelService.exportAsExcelFile(informacionExportacion, 'mantenimientos');
        this.appComponent.loader = false;
      }
    }, error => { this.appComponent.loader = false; })
  }

  descargarAsignaciones() {
    let informacionExportacion: any[] = [];
    this.appComponent.loader = true;

    this.asignaciones.forEach(item => {
      informacionExportacion.push({
        'N. Documento': item.secuencia,
        'Fecha Asignación': this.datePipe.transform(item.fechaEntrega, 'dd-MM-yyyy'),
        'Observación Entrega': item.observacionEntrega,
        'Fecha Devolución': (item.fechaRecepcion == null || item.fechaRecepcion == '') ? '' : this.datePipe.transform(item.fechaRecepcion, 'dd-MM-yyyy'),
        'Observación Devolución': item.observacionRecepcion,
        'Empleado': item.empleado,
        'Estado': item.nombreEstatus,
        'Estado Acta': item.nombreStatusActa
      })
    });
    this.excelService.exportAsExcelFile(informacionExportacion, 'asignaciones');
    this.appComponent.loader = false;

  }


  descargarPrestamos() {
    let informacionExportacion: any[] = [];
    this.appComponent.loader = true;

    this.prestamos.forEach(item => {
      informacionExportacion.push({
        'N. Documento': item.secuencia,
        'Fecha Inicio': this.datePipe.transform(item.fechaEntrega, 'dd-MM-yyyy HH:mm'),
        'Fecha Fin': (item.fechaRecepcion == null || item.fechaRecepcion == '') ? '' : this.datePipe.transform(item.fechaRecepcion, 'dd-MM-yyyy HH:mm'),
        'Observación': item.observacionEntrega,
        'Empleado': item.empleado,
        'Estado': item.nombreEstatus,
        'Estado Acta': item.nombreStatusActa
      })
    });
    this.excelService.exportAsExcelFile(informacionExportacion, 'prestamos');
    this.appComponent.loader = false;

  }


  verOcultaPrestamos() {
    if (this.mostrarPrestamos) {
      this.mostrarPrestamos = false;
    } else {
      this.mostrarPrestamos = true;
      if (this.prestamos == null || this.prestamos.length == 0) {
        this.api.get('comprobanteDetalle/listaPorActivo/' + this.formulario.get('id').value + '/' + EnmTipoComprobante.PrestamoDeEquipos, this.tituloHTML).subscribe(res => {
          if (res != null) {
            this.prestamos = res;
            this.prestamos.forEach(as => {
              as.nombreEstatus = as.estatus == EnmEstatusPrestamoComprobanteDetalle.Solicitado ? 'Solicitado' :
                as.estatus == EnmEstatusPrestamoComprobanteDetalle.Entregado ? 'Entregado' :
                  as.estatus == EnmEstatusPrestamoComprobanteDetalle.Recepcionado ? 'Devuelto' : '';
              as.nombreStatusActa = as.statusComprobante == EnmStatusPrestamoComprobante.EquiposSolicitados ? 'Equipos Solicitados' :
                as.statusComprobante == EnmStatusPrestamoComprobante.EquiposEntregados ? 'Equipos Entregados' :
                  as.statusComprobante == EnmStatusPrestamoComprobante.EquiposDevueltos ? 'Equipos Devueltos' : '';
            });
          }
        });
      }
    }
  }


  retornoCategoria(evento) {
    if (evento != null) {
      this.mostrarCategoria = evento.mostrarCategoria;

      if (evento.categoria != null) {
        this.formulario.patchValue({ categoria: evento.categoria });

        this.calcularFechaAmortizacion();

        if (this.formulario.get('id').value == null || this.formulario.get('id').value == '') {
          this.creacionActivoComponenteCategorias(evento.categoria.id, evento.categoria.nombre);
        }
      }
    }
  }


  eliminar() {
    this.confirmationService.confirm({
      message: this.appComponent.properties.mensajeConfirmacionEliminar,
      accept: () => {
        this.api.delete('activo/eliminar/' + this.formulario.get('id').value, this.tituloHTML).subscribe(res => {
          if (res !== undefined && res !== null && res.operationStatus === "SUCCESS") {
            this.enviarPadre.emit({ esNuevo: false });
          }
          this.appComponent.mensajeServer(res.operationStatus, res.operationMessage, this.tituloHTML);

        });
      }
    });
  }


  editarDocumentoAsociadoMantenimiento(mantenimiento: Mantenimiento) {
    mantenimiento.editarDocumento = true;
  }



  imprimir() {

    const printWindow: any = window.open('', '', `height=600,width=800`);
    printWindow.document.write('<html>');
    printWindow.document.write('<head>');
    printWindow.document.write('<style>');
    printWindow.document.write(`
    #divQRPrint {
      border: 1px solid black;
      width: 5cm;
      height: 3cm;
      font-size: xx-small;
    }


  `);
    printWindow.document.write('</style>');
    printWindow.document.write('</head>');
    printWindow.document.write('<body>');
    printWindow.document.write(document.getElementById("divQRPrint").outerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }




}
