

var ePosDev = new epson.ePOSDevice();
var printer = null;
var canvas = null;
export function connect(image, ip, puerto, address) {
    canvas = image;
    if (address !== null && address !== undefined) {
        var epos = new epson.CanvasPrint(address);
        epos.onreceive = function (res) { };
        epos.onerror = function (err) { };
        epos.cut = true;
        epos.print(canvas);

    } else {
        ePosDev.connect(ip, puerto, callback_connect);
    }
}

export function connectOpen(ip, puerto) {
    ePosDev.connect(ip, puerto, callback_connect_print);
}

function callback_connect_print(resultConnect) {
    if ((resultConnect == 'OK') || (resultConnect == 'SSL_CONNECT_OK')) {
        ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER, { 'crypto': true, 'buffer': false }, callback_Print);
    }
}
function callback_Print(deviceobj, retcode) {
    if (retcode == 'OK') {
        deviceobj.addPulse(deviceobj.DRAWER_1, deviceobj.PULSE_100)
        deviceobj.send();
    }
}

function callback_connect(resultConnect) {
    if ((resultConnect == 'OK') || (resultConnect == 'SSL_CONNECT_OK')) {
        ePosDev.createDevice('local_printer', ePosDev.DEVICE_TYPE_PRINTER, { 'crypto': true, 'buffer': false }, callback_createDevice);
    }
}
function callback_createDevice(deviceobj, retcode) {
    if (retcode == 'OK') {
        printer = deviceobj;
        printer.timeout = 1000;
        //Register the printing complete event
        printer.onreceive = function (res) { };
        print();
    }
}

function print() {
    //Create the printing data
    var cut = true;
    var mode = printer.MODE_MONO;
    //Send the printing data
    printer.print(canvas, cut, mode);
    //caja registradora abrir DRAWER_1 energiza el pin2, DRAWER_2 energiza el pin 5
    printer.addPulse(printer.DRAWER_1, printer.PULSE_100)
    //Send the printing data
    printer.send();
}
function disconnect() {
    //Discard the Printer object
    ePosDev.deleteDevice(printer, callback_deleteDevice);
}
function callback_deleteDevice(errorCode) {
    //Disconnect to device
    ePosDev.disconnect();
}