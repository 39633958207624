import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusComprobantePrestamoPipe'
})
export class StatusComprobantePrestamoPipe implements PipeTransform {

  transform(value: number): string {
    return value  == 1  ? 'Equipos Reservados' :  value == 2 ? 'Equipos Prestados' : value == 3 ? 'Equipos Devueltos' :'';
  }

}


