import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { LoginComponente } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { AppComponent } from './app.component';


import { BienvenidaComponent } from './isa/bienvenida/bienvenida.component';
import { UsuarioComponent } from './logica/administracion/usuario/usuario.component';
import { CronComponent } from './cron/cron.component';
import { CategoriaComponent } from './logica/inventario/categoria/categoria.component';
import { UbicacionComponent } from './logica/configuracion/ubicacion/ubicacion.component';
import { MarcaComponent } from './logica/configuracion/marca/marca.component';
import { ModeloComponent } from './logica/configuracion/modelo/modelo.component';
import { ActivoComponent } from './logica/inventario/activo/activo.component';
import { ActaComponent } from './logica/inventario/acta/acta.component';
import { SecuenciaComprobanteComponent } from './logica/administracion/secuencia-comprobante/secuencia-comprobante.component';
import { PrestamoDevolucionComponent } from './logica/inventario/prestamo-devolucion/prestamo-devolucion.component';
import { SalidaEquipoComponent } from './logica/inventario/salida-equipo/salida-equipo.component';
import { ActivosAsignadoComponent } from './logica/inventario/activos-asignado/activos-asignado.component';
import { BajaEquipoComponent } from './logica/inventario/baja-equipo/baja-equipo.component';
import { ReporteComponent } from './isa/reportes/reporte/reporte.component';
import { QrComponent } from './qr/qr.component';
import { ImpresionQrComponent } from './logica/inventario/impresion-qr/impresion-qr.component';
import { AuthGuard } from './services/auth_guard.service';
import { ConfiguracionActaERComponent } from './logica/administracion/configuracion-acta-er/configuracion-acta-er.component';
import { FirmaComponent } from './firma/firma.component';
import { ImpuestoComponent } from './logica/configuracion/impuesto/impuesto.component';
import { TablaAmortizacionComponent } from './logica/configuracion/tabla-amortizacion/tabla-amortizacion.component';
import { ActaOtrosComponent } from './logica/inventario/acta-otros/acta-otros.component';

export const routes: Routes = [
    {
        path: '', redirectTo: '/home/bienvenida', canActivate: [AuthGuard], pathMatch: 'full'
    },
    {
        path: 'home',
        canActivate: [AuthGuard],
        component: AppComponent,
        children: [

            { path: '', redirectTo: 'bienvenida', pathMatch: 'full' },
            { path: 'dashboard', component: DashboardDemoComponent },
            { path: 'bienvenida', component: BienvenidaComponent },

            //ADMINISTRACION
            {path: 'usuario', component: UsuarioComponent},
            {path: 'ubicacion', component: UbicacionComponent},
            {path: 'marca', component: MarcaComponent},
            {path: 'modelo', component: ModeloComponent},
            {path: 'categoria', component: CategoriaComponent},
            {path: 'impuesto', component: ImpuestoComponent},
            {path: 'tabla-amortizacion', component: TablaAmortizacionComponent},
            {path: 'secuencia', component: SecuenciaComprobanteComponent},
            {path: 'configuracion-acta-er', component: ConfiguracionActaERComponent},
            
            //INVENTARIO
            {path: 'activo', component: ActivoComponent},
            {path: 'acta', component: ActaComponent},
            {path: 'acta-otros', component: ActaOtrosComponent},
            {path: 'prestamo', component:PrestamoDevolucionComponent},
            {path: 'salida-equipo', component:SalidaEquipoComponent},
            {path: 'activos-asignados', component:ActivosAsignadoComponent},
            {path: 'baja-equipos', component:BajaEquipoComponent},
            {path: 'impresion-qr', component:ImpresionQrComponent},
            
            //REPORTES
            {path: 'reporte', component:ReporteComponent}
        ]
    },
    { path: 'login', component: LoginComponente },
    { path: 'logout', component: LogoutComponent },
    { path: 'cron', component: CronComponent },
    { path: 'qr/:id', component: QrComponent },
    { path: 'firma/:id', component: FirmaComponent }

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
 