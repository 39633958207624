import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoService } from '../services/user-info.service';

@Component({
    selector: 's-logout-pg',
    templateUrl: './logout.component.html'
})

export class LogoutComponent {
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private userInfoService: UserInfoService) {
        this.userInfoService.removeUserInfo();
        this.router.navigate(['/login']);
    }
}
