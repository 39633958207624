import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';

@Component({
  selector: 'app-ubicacion-formulario',
  templateUrl: './ubicacion-formulario.component.html',
  styleUrls: ['./ubicacion-formulario.component.css'],
  providers: [ConfirmationService] 
})
export class UbicacionFormularioComponent implements OnInit {

  /**
   * CAMPOS
   */
  @Output() public enviarPadre = new EventEmitter();
  @Input() formulario: FormGroup;
  tituloHTML = '';


  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private confirmationService: ConfirmationService,
  ) {
  }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.location');
  }

  guardar() {
    if (this.formulario.valid) {

      this.api.post('ubicacion/guardar', this.asignarEntidad(), this.tituloHTML).subscribe(res => {
        this.appComponent.mensaje(res.operationStatus, res.operationMessage);
        if (res.operationStatus == 'SUCCESS') {
          this.enviarPadre.emit({ esNuevo: false });
        }
      });

    } else {
      this.api.mensajeError(this.api.mensajeI18('validation.invalidForm'));
      Object.keys(this.formulario.controls).forEach(field => { // {1}
        const control = this.formulario.get(field);
        control.markAsDirty({ onlySelf: true });            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });

    }
  }

  eliminar() {
    this.confirmationService.confirm({
      message: this.appComponent.properties.mensajeConfirmacionEliminar,
      accept: () => {
        this.api.delete('ubicacion/eliminar/' + this.formulario.get('id').value , this.tituloHTML).subscribe(res => {
          if (res !== undefined && res !== null && res.operationStatus === "SUCCESS") {
            this.enviarPadre.emit({ esNuevo: false });
          }
          this.appComponent.mensajeServer(res.operationStatus, res.operationMessage, this.tituloHTML);
        });
      }
    });
  }


  cancelar() {
    this.enviarPadre.emit({ esNuevo: false });
  }

  asignarEntidad() {
    const entidad = {
      id: this.formulario.get('id').value,
      nombre: this.formulario.get('nombre').value,
      nav: this.formulario.get('nav').value,
      direccion: this.formulario.get('direccion').value
    }
    return entidad;
  }


}
