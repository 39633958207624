import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dolar'
})
export class DolarPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const dolar = '$ ' + value;
    return dolar;
  }

}
