import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusComprobanteSalidaEquiposPipe'
})
export class StatusComprobanteSalidaEquiposPipe implements PipeTransform {

  transform(value: number): string {
    return value  == 1  ? 'Solicitud Creada' :  value == 2 ? 'Solicitud Aprobada' : value == 3 ? 'Solicitud Rechazada' :'';
  }

}


