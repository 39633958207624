import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { LazyLoadEvent } from 'primeng/primeng';
import { URLSearchParams } from '@angular/http';
import { TreeNode } from 'primeng/components/common/treenode';
import { concat } from 'rxjs/internal/operators/concat';
import { EnmEstatusActivo } from '../../../logica/enum/enmEstatusActivo';
import { EnmTipoComprobante } from '../../../logica/enum/enmTipoComprobante';
import { EnmStatusPrestamoComprobante } from '../../../logica/enum/enmStatusPrestamoComprobante';
import { EnmStatusActaEntregaRecepcionComprobante } from '../../../logica/enum/enmStatusActaEntregaRecepcionComprobante';
import { EnmEstatusActaEntregaRecepcionComprobanteDetalle } from '../../../logica/enum/enmEstatusActaEntregaRecepcionComprobanteDetalle';
import { ExcelService } from '../../../services/excel.service';
import { EnmTipoValor } from '../../../logica/enum/enmTipoValor';
import { DatePipe } from '@angular/common';
import { SelectItem } from 'primeng/components/common/selectitem';



@Component({
  selector: 'app-activo',
  templateUrl: './activo.component.html',
  styleUrls: ['./activo.component.css', './activo.component.scss'],
  providers: [DatePipe]
})
export class ActivoComponent implements OnInit {

  /**
   * CAMPOS
   */
  @Output() public enviarPadre = new EventEmitter();
  @Input() soloDisponibles: boolean;
  @Input() activosAsignados: boolean;
  @Input() todosActivo: boolean;


  tituloHTML = '';
  esNuevo: boolean = false;
  formulario: FormGroup;
  //Filtros
  filtroNombreProducto: string = "";
  arbol: TreeNode[];
  filtroCategoria: any[] = [];
  filtroCategoriasSeleccionadas: TreeNode[] = [];
  arbolEstatus: TreeNode[];
  arbolUbicacion: TreeNode[];
  filtroUbicacionesSeleccionadas: TreeNode[] = [];
  filtroUbicaciones: any[] = [];
  filtroEstatus: any[] = [];
  filtroEstatusSeleccionados: TreeNode[] = [];
  //Datos de Tabla Lazy
  datosTabla: any[] = [];
  filtroTabla: any;
  tamanioPagina: number = 10;
  paginaActual: number = 0;
  totalRegistros: number;
  parametro = '';
  cabeceraExcel: any[] = [];
  columnas = 4;

  cantidadColumnasGrid: SelectItem[] = [];
  controladorTiempo: any;
  timestamp = new Date().getTime();

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private excelService: ExcelService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.activesParameter', { valor1: this.parametro });
    this.iniciarCantidadColumnas();
    this.cargarArbolUbicacion();
    this.cargaArbolCategoria();
    this.cargarArbolEstatus();
    this.cargarCabeceraExcel();

    if (this.soloDisponibles == null || !this.soloDisponibles) {
      this.soloDisponibles = false;
    } else if (this.soloDisponibles && (this.activosAsignados == null || !this.activosAsignados)) {
      this.parametro = this.api.mensajeI18('common.avaiables');
      this.tituloHTML = this.api.mensajeI18('common.activesParameter', { valor1: this.parametro });
    }

    if (this.activosAsignados == null) {
      this.activosAsignados = false;
    } else if (this.activosAsignados && (this.soloDisponibles == null || !this.soloDisponibles)) {
      this.parametro = this.api.mensajeI18('common.assigned')
      this.tituloHTML = this.api.mensajeI18('common.activesParameter', { valor1: this.parametro });
    }

    if (this.activosAsignados && this.soloDisponibles) {
      this.parametro = this.api.mensajeI18('common.avaiables');
      this.tituloHTML = this.api.mensajeI18('common.activesParameter', { valor1: this.parametro });
    }



  }

  ngAfterViewInit() {

  }

  iniciarCantidadColumnas() {
    if (!this.cantidadColumnasGrid || this.cantidadColumnasGrid.length == 0) {
      this.cantidadColumnasGrid = [
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '6', value: 6 }
      ];
    }
  }

  cargaArbolCategoria() {
    this.api.get('categoria/categoriasArbol', this.tituloHTML).subscribe(data => this.arbol = data);
  }

  cargarArbolEstatus() {
    if (this.arbolEstatus == null || this.arbolEstatus.length == 0) {
      this.arbolEstatus = [];
      this.arbolEstatus.push({ label: 'Disponible', data: { id: 1 }, expandedIcon: 'fa fa-check', collapsedIcon: 'fa fa-check', icon: 'fa fa-check' });
      this.arbolEstatus.push({ label: 'Asignado', data: { id: 2 }, expandedIcon: 'fa fa-user', collapsedIcon: 'fa fa-user', icon: 'fa fa-user' });
      this.arbolEstatus.push({ label: 'Dado de baja', data: { id: 3 }, expandedIcon: 'fa fa-ban', collapsedIcon: 'fa fa-ban', icon: 'fa fa-ban' });
    }
  }

  cargarArbolUbicacion() {
    if (this.arbolUbicacion == null || this.arbolUbicacion.length == 0) {
      this.arbolUbicacion = [];
      this.api.get('ubicacion/listaCorta', this.tituloHTML).subscribe(data => {
        if (data != null) {
          let listaUbicaciones = data;
          data.forEach(ubicacion => {
            this.arbolUbicacion.push({ label: ubicacion.nombre, data: { id: ubicacion.id } });
          })
        }
      }
      )
    }
  }

  nuevo() {
    this.nuevoFormulario();
    this.esNuevo = true;
  }

  nuevoFormulario() {
    return this.formulario = this.formBuilder.group({
      id: [''],
      categoria: ['', Validators.required],
      ubicacion: ['', Validators.required],
      marca: [{ id: '0' }, Validators.required],
      modelo: [{ id: '0' }, Validators.required],
      serie: ['', Validators.required],
      nav: [''],
      fechaCompra: [new Date(), Validators.required],
      fechaFinGarantia: [''],
      descripcion: [''],
      serialTic: [''],
      fechaInicioUso: [''],
      imagen: '',
      facturaCompra: '',
      imagenRuta: '',
      facturaCompraRuta: '',
      imagenFacturaCompra: '',
      habilitadoPrestamo: [false, Validators.required],
      fechaAmortizacion: [],
      precioSubtotalCompra: [0],
      impuesto: []
    })
  }


  loadLazy(event: LazyLoadEvent) {
    setTimeout(() => {

      let params: URLSearchParams = new URLSearchParams();
      this.tamanioPagina = event.rows;
      this.paginaActual = event.first;
      if (this.paginaActual !== 0) {
        this.paginaActual = this.paginaActual / this.tamanioPagina;
      }
      params.set('pagina', this.paginaActual.toString());
      params.set('tamanio', this.tamanioPagina.toString());
      params.set('parametroBusqueda', this.filtroNombreProducto);
      params.set('categorias', this.filtroCategoria.toString());
      params.set('ubicaciones', this.filtroUbicaciones.toString());


      if (this.soloDisponibles != null && this.soloDisponibles && (this.activosAsignados == null || !this.activosAsignados)) {
        let filtroActa: any[] = [];
        filtroActa.push(EnmEstatusActivo.Disponilble);
        params.set('estatus', filtroActa.toString());
        params.set('usuarioSesion', 'false');
      } else if (!this.soloDisponibles && this.activosAsignados) {
        let filtroActa: any[] = [];
        filtroActa.push(EnmEstatusActivo.Asignado);
        params.set('estatus', filtroActa.toString());
        params.set('usuarioSesion', 'true');
        params.set('tipoComprobante', EnmTipoComprobante.ActaEntregaRecepcion.toString());
        let filtroStatusComprobante: any[] = [];
        filtroStatusComprobante.push(EnmStatusActaEntregaRecepcionComprobante.EquiposAsignados);
        params.set('statusComprobante', filtroStatusComprobante.toString())
        let filtroEstatusComprobanteDetalle: any[] = [];
        filtroEstatusComprobanteDetalle.push(EnmEstatusActaEntregaRecepcionComprobanteDetalle.Entregado);
        params.set('estatusComprobanteDetalle', filtroEstatusComprobanteDetalle.toString());
      } else if (this.soloDisponibles && this.activosAsignados) {
        let filtroActa: any[] = [];
        filtroActa.push(EnmEstatusActivo.Disponilble);
        filtroActa.push(EnmEstatusActivo.Asignado);
        params.set('estatus', filtroActa.toString());
        params.set('usuarioSesion', 'false');
      }
      this.cargaTabla(params);
    }, 25);
  }


  cargaTabla(params: URLSearchParams) {

    this.appComponent.progressBarstatus = true;
    this.appComponent.loader = true;
    this.api.get('activo/lista', this.tituloHTML, params).subscribe((data) => {
      if (data != null) {
        if (data.content != null) {

          this.datosTabla = data.content;
          this.datosTabla.forEach(dt => {
            dt.imagenRuta = (dt.imagen == null || dt.imagen == '') ? 'assets/isa/images/sin_imagen.png' : this.api.appConfig.servidorImagenActivos + dt.imagen +'?t=' + (new Date().getTime());

            dt.facturaCompraRuta = (dt.facturaCompra == null || dt.facturaCompra == '') ? '' : this.api.appConfig.servidorFacturaCompra + dt.facturaCompra;
            dt.imagenFacturaCompra = (dt.facturaCompra == null || dt.facturaCompra == '' ? 'assets/isa/images/sin_archivo.png' : 'assets/isa/images/archivo.png');
            dt.urlQR = this.api.appConfig.apiProtocol + "//" + this.api.appConfig.apiHostName + ':' + this.api.appConfig.apiPortFront + '/#/qr/' + dt.id
          });
          this.totalRegistros = data.totalElements;
          this.paginaActual = data.number + 1;
          this.appComponent.progressBarstatus = false;
          this.appComponent.loader = false;
        }
        else {
          this.appComponent.progressBarstatus = false;
          this.appComponent.loader = false;
        }
      }
      else {
        this.appComponent.progressBarstatus = false;
        this.appComponent.loader = false;
      }
    });
  }


  busqueda() {

    clearTimeout(this.controladorTiempo);
    this.controladorTiempo = setTimeout(() => {

      setTimeout(() => {
        this.busquedaCategoria();
        this.busquedaEstatus();
        this.busquedaUbicaciones();
        let params: URLSearchParams = new URLSearchParams();
        this.paginaActual = this.paginaActual - 1;
        params.set('page', this.paginaActual.toString());
        params.set('size', this.tamanioPagina.toString());
        params.set('parametroBusqueda', this.filtroNombreProducto);
        params.set('categorias', this.filtroCategoria.toString());
        params.set('ubicaciones', this.filtroUbicaciones.toString());
        if (!this.soloDisponibles) {
          if (this.activosAsignados) {
            let filtroActa: any[] = [];
            filtroActa.push(EnmEstatusActivo.Asignado);
            params.set('estatus', filtroActa.toString());
            params.set('usuarioSesion', 'true');
            params.set('tipoComprobante', EnmTipoComprobante.ActaEntregaRecepcion.toString());
            let filtroStatusComprobante: any[] = [];
            filtroStatusComprobante.push(EnmStatusActaEntregaRecepcionComprobante.EquiposAsignados);
            params.set('statusComprobante', filtroStatusComprobante.toString())
            let filtroEstatusComprobanteDetalle: any[] = [];
            filtroEstatusComprobanteDetalle.push(EnmEstatusActaEntregaRecepcionComprobanteDetalle.Entregado);
            params.set('estatusComprobanteDetalle', filtroEstatusComprobanteDetalle.toString());
          } else {
            params.set('usuarioSesion', 'false');
            params.set('estatus', this.filtroEstatus.toString());
          }
        } else {
          let filtroActa: any[] = [];
          filtroActa.push(EnmEstatusActivo.Disponilble);
          params.set('estatus', filtroActa.toString());
          params.set('usuarioSesion', 'false');
        }

        this.cargaTabla(params);
      }, 25);

    }, 1000);

  }

  busquedaCategoria() {
    this.filtroCategoria = [];
    this.filtroCategoriasSeleccionadas.forEach(nodo => {
      this.filtroCategoria.push(nodo.data.id);
    });
  }

  busquedaEstatus() {
    this.filtroEstatus = [];
    this.filtroEstatusSeleccionados.forEach(nodo => {
      this.filtroEstatus.push(nodo.data.id);
    });
  }

  busquedaUbicaciones() {
    this.filtroUbicaciones = [];
    this.filtroUbicacionesSeleccionadas.forEach(nodo => {
      this.filtroUbicaciones.push(nodo.data.id);
    });
  }

  seleccionRegistro($registro) {

    if (!this.soloDisponibles && !this.activosAsignados && !this.todosActivo) {
      this.formulario = this.formBuilder.group({
        id: $registro.id,
        categoria: { id: $registro.idCategoria, nombre: $registro.nombreCategoria, idCategoriaPadre: $registro.idCategoriaPadre },
        ubicacion: { id: $registro.idUbicacion, nombre: $registro.nombreUbicacion },
        marca: { id: $registro.idMarca, nombre: $registro.nombreMarca },
        modelo: { id: $registro.idModelo, nombre: $registro.nombreModelo, idMarca: $registro.idMarca },
        impuesto:  { id: $registro.idImpuesto, nombre: $registro.nombreImpuesto, porcentaje: $registro.porcentajeImpuesto, principal: $registro.principalImpuesto },
        serie: $registro.serie,
        nav: $registro.nav,
        fechaCompra: $registro.fechaCompra != null ? new Date($registro.fechaCompra) : null,
        fechaFinGarantia: $registro.fechaFinGarantia != null ? new Date($registro.fechaFinGarantia) : null,
        descripcion: $registro.descripcion,
        imagen: $registro.imagen,
        imagenRuta: $registro.imagenRuta,
        facturaCompra: $registro.facturaCompra,
        facturaCompraRuta: $registro.facturaCompraRuta,
        imagenFacturaCompra: $registro.imagenFacturaCompra,
        serialTic: [$registro.serialTic],
        fechaInicioUso: [$registro.fechaInicioUso != null ? new Date($registro.fechaInicioUso) : null],
        habilitadoPrestamo: $registro.habilitadoPrestamo,
        fechaAmortizacion: [$registro.fechaAmortizacion != null ? new Date($registro.fechaAmortizacion) : null],
        precioSubtotalCompra: $registro.precioSubtotalCompra
      })
      this.esNuevo = true;
    } else {

      const entidad = {
        id: $registro.id,
        categoria: { id: $registro.idCategoria, nombre: $registro.nombreCategoria },
        ubicacion: { id: $registro.idUbicacion, nombre: $registro.nombreUbicacion },
        marca: { id: $registro.idMarca, nombre: $registro.nombreMarca },
        modelo: { id: $registro.idModelo, nombre: $registro.nombreModelo, idMarca: $registro.idMarca },
        impuesto:  { id: $registro.idImpuesto, nombre: $registro.nombreImpuesto, porcentaje: $registro.porcentajeImpuesto, principal: $registro.principalImpuesto },
        serie: $registro.serie,
        nav: $registro.nav,
        fechaCompra: $registro.fechaCompra != null ? new Date($registro.fechaCompra) : null,
        fechaFinGarantia: $registro.fechaFinGarantia != null ? new Date($registro.fechaFinGarantia) : null,
        descripcion: $registro.descripcion,
        imagen: $registro.imagen,
        imagenRuta: $registro.imagenRuta,
        facturaCompra: $registro.facturaCompra,
        facturaCompraRuta: $registro.facturaCompraRuta,
        imagenFacturaCompra: $registro.imagenFacturaCompra,
        fechaAmortizacion: [$registro.fechaAmortizacion != null ? new Date($registro.fechaAmortizacion) : null],
        precioSubtotalCompra: $registro.precioSubtotalCompra
      }

      this.enviarPadre.emit({ activo: entidad });
    }

  }


  descargar() {

    this.appComponent.loader = true;

    let params: URLSearchParams = new URLSearchParams();
    params.set('page', '0');
    params.set('size', this.totalRegistros.toString());
    params.set('parametroBusqueda', this.filtroNombreProducto);
    params.set('categorias', this.filtroCategoria.toString());
    params.set('estatus', this.filtroEstatus.toString());
    params.set('ubicaciones', this.filtroUbicaciones.toString());

    this.api.get('activo/lista', this.tituloHTML, params).subscribe(async data => {
      if (data != null) {
        let informacionExportacion: any[] = [];

        if (data.content != null && data.content != undefined) {
          for (let i = 0; i < data.content.length; i++) {
            await this.tratamientoExcel(informacionExportacion, data.content[i]);
          }
        }
        this.excelService.exportAsExcelFile(informacionExportacion, 'activos');
        this.appComponent.loader = false;
      }
      this.appComponent.loader = false;
    }, error => {
      this.appComponent.loader = false;
    });

  }



  tratamientoExcel(lista, activo) {

    return new Promise((resolve, reject) => {

      this.api.post('activoComponenteCategoria/listaPorActivoYCategoria', { idActivo: activo.id, idCategoria: activo.idCategoria }, this.tituloHTML).subscribe(res => {

        let listaComponentes: any[] = [];

        if (res != null && res.data != null && res.data.length > 0) {
          listaComponentes = res.data;
        }

        var obj: { [k: string]: any } = {};
        obj['Categoria Padre'] = activo.nombreCategoriaPadre,
          obj.Categoria = activo.nombreCategoria;
        obj.Marca = activo.nombreMarca;
        obj.Modelo = activo.nombreModelo;
        obj.Serie = activo.serie;
        obj['Código Nav'] = activo.nav;
        obj['Ubicación'] = activo.nombreUbicacion;
        obj['Fecha Compra'] = this.datePipe.transform(activo.fechaCompra, 'dd-MM-yyyy');
        obj['Fin de Garantía'] = this.datePipe.transform(activo.fechaFinGarantia, 'dd-MM-yyyy');
        obj['Inicio Uso'] = this.datePipe.transform(activo.fechaInicioUso, 'dd-MM-yyyy');
        obj['Serial Tic'] = activo.serialTic;
        obj.Estatus = activo.estatus == 1 ? 'Disponible' : activo.estatus == 2 ? 'Asignado' : activo.estatus == 3 ? 'Dado de Baja' : '';
        obj['Descripción'] = activo.descripcion;

        this.cabeceraExcel.forEach(item => {
          obj[item.nombre] = '';

          let componentesEncontrados: any = listaComponentes.filter(x => x.idComponente == item.id);

          if (componentesEncontrados != null && componentesEncontrados.length == 1) {
            let componente = componentesEncontrados[0];
            obj[item.nombre] = componente.tipoValor == EnmTipoValor.Numero ? componente.valorNumero : componente.tipoValor == EnmTipoValor.Texto ? componente.valorTexto : '';
          }

        })

        lista.push(obj);
        resolve('OK');

      })

    });

  }


  cargarCabeceraExcel() {
    if (this.cabeceraExcel == null || this.cabeceraExcel.length == 0) {
      this.cabeceraExcel = [];
      this.api.get('componenteCategoria/componentesActivos', this.tituloHTML).subscribe(data => {
        data.forEach(item => {
          this.cabeceraExcel.push({ id: item.id, nombre: item.nombre });
        })
      })
    }
  }


  retorno(evento) {
    if (evento != null) {
      this.esNuevo = evento.esNuevo;
    }
  }

  cancelar() {
    this.enviarPadre.emit({ activo: null });
  }



}
