export class Constantes {

    //Facturacion
    public static get MODIFICA_PRECIO(): string { return "FAC-PRE"; };
    public static get MODIFICA_IMPUESTO(): string { return "PIM-IVA"; };
    //DashBoard
    public static get VISUALIZA_VENTAS(): string { return "DAS-VEN"; };
    public static get VISUALIZA_COMPRAS(): string { return "DAS-COM"; };
    public static get VISUALIZA_CLIENTES(): string { return "DAS-CLI"; };
    public static get VISUALIZA_GRAFICA_VENTA_COMPRAS(): string { return "DAS-VCO"; };
    public static get VISUALIZA_GRAFICA_TOP_5(): string { return "DAS-TOP"; };
    public static get VISUALIZA_GRAFICA_STOCK_MINIMO(): string { return "DAS-MIN"; };
    public static get VISUALIZA_GRAFICA_CATEGORIAS(): string { return "DAS-CAT"; };

    // Ambitos constantes para para parametros
    public static get AMBITO_GENERAL(): string { return "G"; };
    public static get AMBITO_POR_EMPRESA(): string { return "E"; };
    public static get AMBITO_POR_AGENCIA(): string { return "A"; };
    public static get AMBITO_POR_USUARIO(): string { return "U"; };

    //Manejo de fechas y horas
    public static readonly DATE_FMT = 'dd/MM/yyyy';
    public static readonly DATE_TIME_FMT = '${Constantes.DATE_FMT} hh:mm:ss';
}