import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { LazyLoadEvent } from 'primeng/primeng';
import { URLSearchParams } from '@angular/http';

@Component({
  selector: 'app-configuracion-acta-er',
  templateUrl: './configuracion-acta-er.component.html',
  styleUrls: ['./configuracion-acta-er.component.css']
})
export class ConfiguracionActaERComponent implements OnInit {

   /**
   * CAMPOS
   */
    tituloHTML = '';
    esNuevo:boolean = false;
    formulario: FormGroup;
    //Datos de Tabla Lazy
    datosTabla:any[] = [];
    filtroTabla: any;
    tamanioPagina: number = 10;
    paginaActual: number = 0;
    totalRegistros: number;  
  
    constructor(
      private formBuilder: FormBuilder,
      private api: ApiRequestService,
      public appComponent: AppComponent 
    ) { }
  
    ngOnInit() {
      this.tituloHTML = this.api.mensajeI18('common.configurationMinuteAR');    
    }
  
    nuevo(){
      this.nuevoFormulario();    
      this.esNuevo = true;
    }
  
    nuevoFormulario(){
      return this.formulario = this.formBuilder.group({
        id:[''],
        nombre:['', Validators.required],
        cargo:['', Validators.required],
        tipoComprobante: ['', Validators.required]
      })
    }
  
    loadLazy(event: LazyLoadEvent) {
      setTimeout(() => {
        let params: URLSearchParams = new URLSearchParams();
        this.tamanioPagina = event.rows;
        this.paginaActual = event.first;
        if (this.paginaActual !== 0) {
          this.paginaActual = this.paginaActual / this.tamanioPagina;
        }
        params.set('pagina', this.paginaActual.toString());
        params.set('tamanio', this.tamanioPagina.toString());
        this.cargaTabla(params);
      }, 25);
    }
  
    cargaTabla(params: URLSearchParams) {
      this.appComponent.loader = true;
      this.api.get('configuracionActaER/lista', this.tituloHTML, params).subscribe((data) => {
        if (data != null) {
          this.datosTabla = data.content;

          this.datosTabla.forEach(x => x.firma = (x.firma == null || x.firma == '') ? 'assets/isa/images/sin_imagen.png' : this.api.appConfig.servidorConfiguracionAER + x.firma)

          this.totalRegistros = data.totalElements;
          this.paginaActual = Number(data.number) + 1;
        }
        this.appComponent.loader = false;
      }, error => {
        this.appComponent.loader = false;
      });
    }
  

    retorno(evento){
      if(evento != null){
        this.esNuevo = evento.esNuevo;
      }
    }

}
