import { TreeNode } from 'primeng/components/common/treenode';
import { Tree } from 'primeng/primeng';
import { MenuItem } from 'primeng/components/common/menuitem';
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { UserInfoService } from '../../../services/user-info.service';
import {  FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {  ConfirmationService } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { Categoria } from '../../../isa/domains/categoria';


const IntroJs = require('../../../../assets/javascript/intro.js');
let intro = IntroJs();


@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css'],
  providers: [ConfirmationService]  //Pantalla de Confirmacion

})
export class CategoriaComponent implements OnInit {

  /**
 * CAMPOS
 */

  @ViewChild('expandingTree')
  expandingTree: Tree;
  arbol: TreeNode[];
  nodoSeleccionado: TreeNode;
  menuArbol: MenuItem[];
  hayRegistros: boolean;
  //Campos para formulario
  esNuevo: boolean;
  formulario: FormGroup;
  tituloHTML: string;
  imgdata: FormData;
  uploadedFilesSend: FormData;
  @Output() public enviarPadre = new EventEmitter();
  @Input() interfazPropia: boolean;
  



  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private userInfoService: UserInfoService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private domSanitizer: DomSanitizer,
  ) {
    intro.exit();
  }


  ngOnInit() {
    intro.exit();
    this.appComponent.validMenu('/home/categoria');
    this.translate.get('common.category').subscribe(mensaje => this.tituloHTML = mensaje);
    this.esNuevo = false;
    this.inicializarFormulario();
    this.api.get('categoria/categoriasArbol', this.tituloHTML).subscribe(data => this.arbol = data);

    this.menuArbol = [
      { label: 'Nuevo', icon: 'fa fa-plus', command: (event) => this.nuevo(this.nodoSeleccionado) },
      { label: 'Editar', icon: 'fa fa-pencil', command: (event) => this.editar(this.nodoSeleccionado) },
    ];

  }


  inicializarFormulario() {
    this.formulario = this.formBuilder.group({
      id: '',
      nombre: ['', Validators.required],
      descripcion: '',
      categoriaPadreId: null,
      categoriaPadreNombre: '',
      nav: ['', Validators.required],
      estado: true,
      imagen: '',
    });

  }


  nuevo(nodo: TreeNode) {
    this.imgdata = new FormData();
    if (nodo.data.id != null && nodo.data.id > 0) {
      this.appComponent.clear();
      this.formulario = this.formBuilder.group({
        id: '',
        nombre: ['', Validators.required],
        descripcion: '',
        categoriaPadreId: nodo.data.id,
        categoriaPadreNombre: nodo.data.nombre,
        nav: ['', Validators.required],
        estado: true,
        imagen: '',
      });
      this.esNuevo = true;
    }
  }

  editar(nodo: TreeNode) {

    this.appComponent.clear();
    this.formulario = this.formBuilder.group({
      id: nodo.data.id,
      nombre: [nodo.data.nombre, Validators.required],
      categoriaPadreId: nodo.data.categoria_padre_id,
      categoriaPadreNombre: nodo.data.categoria_padre_nombre,
      nav: [nodo.data.nav, Validators.required],
      descripcion: nodo.data.descripcion,
      estado: nodo.data.estado,
      imagen: nodo.data.imagen == '' || nodo.data.imagen == null ? 'assets/isa/images/sin_imagen.png' : nodo.data.imagen,
    });
    this.esNuevo = true;
  }

  cancelar() {
    this.appComponent.clear();
    this.formulario.reset();
    this.esNuevo = false;
  }

  nuevaCategoriaRaiz() {
    this.appComponent.clear();
    this.inicializarFormulario();
    this.esNuevo = true;
  }

  seleccionCategoria(categoria) {
    if (categoria != null && categoria.node.data != null) {
      let categoriaEnvio: Categoria = new Categoria();
      categoriaEnvio.id = categoria.node.data.id;
      categoriaEnvio.nombre = categoria.node.data.nombre.toUpperCase();
      categoriaEnvio.estado = true;
      categoriaEnvio.idCategoriaPadre = categoria.node.data.categoria_padre_id;
      this.enviarPadre.emit({ mostrarCategoria:false, categoria: categoriaEnvio });
    }
  }


  retorno(evento) {
    if (evento != null) {
      this.esNuevo = evento.esNuevo;
      this.api.get('categoria/categoriasArbol', this.tituloHTML).subscribe(data => this.arbol = data);
    }
  }

  retornoCancelar(){
    this.enviarPadre.emit({ mostrarCategoria:false, categoria: null });
  }


}
