import { Activo } from "./activo";
import { ComponenteCategoria } from "./componenteCategoria";

export class ActivoComponenteCategoria {
    id: number;
    tipoValor: number;
    valorTexto: string;
    valorNumero: number;
    valor: any;
    activo:Activo;
    componenteCategoria: ComponenteCategoria;
}