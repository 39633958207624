import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { empty } from 'rxjs/internal/observable/empty';
import { ComprobanteDetalle } from '../../../logica/clases/comprobanteDetalle';
import { TreeNode } from 'primeng/components/common/treenode';
import { EnmStatusPrestamoComprobante } from '../../../logica/enum/enmStatusPrestamoComprobante';
import { EnmTipoComprobante } from '../../../logica/enum/enmTipoComprobante';
import { StatusComprobantePrestamoPipe } from '../../../logica/pipes/status-comprobante-prestamo.pipe';
import { DatePipe } from '@angular/common';
import { EnmPerfil } from '../../../logica/enum/enmPerfil';


declare var moment: any;

@Component({
  selector: 'app-prestamo-devolucion-formulario',
  templateUrl: './prestamo-devolucion-formulario.component.html',
  styleUrls: ['./prestamo-devolucion-formulario.component.css'],
  providers: [ConfirmationService, DatePipe, StatusComprobantePrestamoPipe]
})
export class PrestamoDevolucionFormularioComponent implements OnInit {

  /**
 * CAMPOS
 */
  @Output() public enviarPadre = new EventEmitter();
  @Input() formulario: FormGroup;
  tituloHTML = '';
  usuarios: any[] = [];
  @Input() comprobantesDetalles: ComprobanteDetalle[] = [];
  mostrarActivo: boolean = false;
  soloDisponibles: boolean = true;
  fechaActual: Date = new Date();
  es: any;
  esAdministrador = false;


  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.equipmentLoan');
    this.cargaInicialUsuarios();
    this.cargaIdiomaCalendario();
    this.validarAdministrador();
  }

  validarAdministrador() {
    this.api.post('perfil/perfilUsuario', {}, this.tituloHTML).subscribe(res => {
      if (res != null && res.data != null && res.operationStatus == 'SUCCESS') {
        if (res.data[0].perfil == EnmPerfil.Administrador) {
          this.esAdministrador = true;
        } else {
          this.formulario.patchValue({ usuario: { email: res.data[0].emailUsuario, name: res.data[0].nameUsuario, username: res.data[0].usernameUsuario } });
        }
      }
    });
  }


  cargaIdiomaCalendario() {
    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
      dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      today: 'Hoy',
      clear: 'Limpiar',
      dateFormat: 'dd/mm/yyyy'
    };

  }

  cargaInicialUsuarios() {
    if (this.usuarios == null || this.usuarios.length == 0) {
      this.api.get('usuario/listaLDAP', this.tituloHTML).subscribe(res => this.usuarios = res);
    }
  }

  guardar() {
    this.appComponent.loader = true;
    if (this.formulario.valid) {
      if (this.comprobantesDetalles != null && this.comprobantesDetalles.length > 0) {
        this.api.post('comprobante/guardarPrestamo', this.asignarEntidad(), this.tituloHTML).subscribe(res => {
          this.appComponent.mensaje(res.operationStatus, res.operationMessage);
          if (res.operationStatus == 'SUCCESS') {
            this.appComponent.loader = false;
            this.enviarPadre.emit({ esNuevo: false });
          }
          this.appComponent.loader = false;
        }, error => { this.appComponent.loader = false });
      } else {
        this.api.mensajeError('Es importante seleccionar al menos un activo para prestamo');
        this.appComponent.loader = false;
      }
    } else {
      this.api.mensajeError(this.api.mensajeI18('validation.invalidForm'));
      Object.keys(this.formulario.controls).forEach(field => {
        const control = this.formulario.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.appComponent.loader = false;
    }

  }

  cancelar() {
    this.enviarPadre.emit({ esNuevo: false });
  }

  asignarEntidad() {

    let incremental = 1;
    this.comprobantesDetalles.forEach(cd => {
      if (cd.estatus == EnmStatusPrestamoComprobante.EquiposSolicitados) {
        cd.fechaEntrega = moment(this.formulario.get('fechaCreacion').value).format("YYYY-MM-DD HH:mm")
        cd.fechaRecepcion = moment(this.formulario.get('fechaFinalizacion').value).format("YYYY-MM-DD HH:mm");
      } else if (cd.estatus == EnmStatusPrestamoComprobante.EquiposEntregados) {
        cd.fechaRecepcion = moment(this.formulario.get('fechaFinalizacion').value).format("YYYY-MM-DD HH:mm");
      }
      if (cd.linea == null || cd.linea == 0) {
        cd.linea = incremental;
      }
      incremental++;
    });

    const entidad = {
      id: this.formulario.get('id').value,
      status: this.formulario.get('status').value,
      tipoComprobante: { id: EnmTipoComprobante.PrestamoDeEquipos },
      secuencia: this.formulario.get('secuencia').value,
      fechaCreacion: moment(this.formulario.get('fechaCreacion').value).format("YYYY-MM-DD HH:mm"),
      fechaFinalizacion: moment(this.formulario.get('fechaFinalizacion').value).format("YYYY-MM-DD HH:mm"),
      observacion: this.formulario.get('observacion').value,
      usuario: this.formulario.get('usuario').value,
      comprobanteDetalles: this.comprobantesDetalles
    }

    return entidad;
  }


  agregarActivo() {
    if (this.formulario.valid) {
      if (new Date(this.formulario.get('fechaCreacion').value).getTime() != new Date(this.formulario.get('fechaFinalizacion').value).getTime()) {
        this.mostrarActivo = true;
      } else {
        this.appComponent.mensajeAdvertencia('Las fechas de Inicio y Fin del Prestamo deben ser distintas.');
      }
    } else {
      this.api.mensajeError(this.api.mensajeI18('validation.invalidForm'));
      Object.keys(this.formulario.controls).forEach(field => {
        const control = this.formulario.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
    }


  }




  entregarEquipos() {
    this.formulario.patchValue({ status: EnmStatusPrestamoComprobante.EquiposEntregados });
    this.comprobantesDetalles.forEach(cd => {
      cd.estatus = EnmStatusPrestamoComprobante.EquiposEntregados;
    });
    this.guardar();
  }

  recibirEquipos() {
    this.formulario.patchValue({ status: EnmStatusPrestamoComprobante.EquiposDevueltos });
    this.comprobantesDetalles.forEach(cd => {
      cd.estatus = EnmStatusPrestamoComprobante.EquiposDevueltos;
    });
    this.guardar();
  }


  retornoActivo($event) {

    if ($event != null && $event.activo != null) {

      let existeActivo: boolean = false;

      for (let item of this.comprobantesDetalles) {
        if (item.activo.id == $event.activo.id) {
          existeActivo = true;
        }
      }

      if (!existeActivo) {
        let cd: ComprobanteDetalle = new ComprobanteDetalle();
        cd.id = null;
        cd.activo = $event.activo;
        cd.fechaEntrega = this.formulario.get('fechaCreacion').value;
        cd.observacionEntrega = 'Equipo en perfectas condiciones';
        cd.fechaRecepcion = this.formulario.get('fechaFinalizacion').value;
        cd.observacionRecepcion = '';
        cd.estatus = EnmStatusPrestamoComprobante.EquiposSolicitados;

        this.api.post('comprobanteDetalle/existenciaActivoReservado', {
          activo: cd.activo.id,
          fechaDesde: moment(cd.fechaEntrega).format("YYYY-MM-DD HH:mm"),
          fechaHasta: moment(cd.fechaRecepcion).format("YYYY-MM-DD HH:mm"),
          tipoComprobante: EnmTipoComprobante.PrestamoDeEquipos
        }, this.tituloHTML).subscribe(res => {

          if (res != null && res.data.length > 0) {
            res.data.forEach(x => this.appComponent.mensajeAdvertencia('El activo seleccionado ya posee una reserva realizada por ' + x.name + ' activa desde ' +
              this.datePipe.transform(x.fechaEntrega, 'dd-MM-yyyy HH:mm') + ' hasta ' + this.datePipe.transform(x.fechaRecepcion, 'dd-MM-yyyy HH:mm')));
            //this.mostrarActivo = false;
          } else if (res != null && res.data.length == 0) {
            this.comprobantesDetalles.push(cd);
            this.mostrarActivo = false;
          }


        });

      } else {
        this.appComponent.mensajeAdvertencia('El activo del tipo ' + $event.activo.categoria.nombre + ' con número de serie ' + $event.activo.serie + ' ya se encuentra en esta acta.');
        this.mostrarActivo = false;
      }

    }

    this.mostrarActivo = false;
  }


  eliminar() {
    this.confirmationService.confirm({
      message: this.appComponent.properties.mensajeConfirmacionEliminar,
      accept: () => {
        this.api.post('comprobante/eliminar', { idComprobante: this.formulario.get('id').value }, this.tituloHTML).subscribe(res => {
          if (res !== undefined && res !== null && res.operationStatus === "SUCCESS") {
            this.enviarPadre.emit({ esNuevo: false });
          }
          this.appComponent.mensajeServer(res.operationStatus, res.operationMessage, this.tituloHTML);

        });
      }
    });
  }

}
