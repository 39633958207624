import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'


declare var google: any;

@Component({
  selector: 'app-ubicacion',
  templateUrl: './ubicacion.component.html',
  styleUrls: ['./ubicacion.component.css'],
  providers: [ConfirmationService]
})
export class UbicacionComponent implements OnInit {

  /**
   * CAMPOS
   */
  tituloHTML = '';
  esNuevo:boolean = false;
  formulario: FormGroup;
  datosTabla:any[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private api: ApiRequestService,
    public appComponent: AppComponent 
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.location');
    this.cargarRegistros();
  }

  cargarRegistros(){
    this.api.get('ubicacion/lista', this.tituloHTML).subscribe(res=> {
      this.datosTabla = res;
    });
  }

  nuevo(){
    this.nuevoFormulario();    
    this.esNuevo = true;
  }

  nuevoFormulario(){
    return this.formulario = this.formBuilder.group({
      id:[''],
      nombre:['', Validators.required],
      nav:['',Validators.required],
      direccion:['']
    })
  }


  seleccionRegistro($registro){
    this.formulario = this.formBuilder.group({
      id:$registro.id,
      nombre:[$registro.nombre, Validators.required],
      nav:[$registro.nav,Validators.required],
      direccion:[$registro.direccion]
    });
    this.esNuevo = true;
  }


  retorno(evento){
    if(evento != null){
      this.esNuevo = evento.esNuevo;
      this.cargarRegistros();
    }
  }


}
