import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    template: `

    <div class="footer"  >
    <div class=" clearfix" style="background: white;
    height: 25px;
    border-radius: 25px;
    padding-left: 10px;
    filter: drop-shadow(5px 5px 5px #9e9e9e);
    padding-right: 10px;">
    <a class="logo-container" href="https://www.vendex.ec" target="_blank">
    <img alt="harmony-layout" style="  width: 90px;margin:1px" src="assets/layout/images/logo2x.png">
    </a>
 <span class="footer-text-right" style=" ">
 <a id="rightpanel-menu-button" href="tel:+593-9620-53333" title="+593-9620-53333"  style="color: #ab0909; font-size: 15px;">
 <i class="material-icons">
 phone
 </i>
  </a>
 <a id="rightpanel-menu-button" href="mailto:soporte@vendex.ec"  style="color: #ab0909; font-size: 15px;">
 <i class="material-icons">
 email
 </i>
  </a>
 <a target='_blank' href="https://www.facebook.com/vendex.ec/">
                                                        <img src="https://cdn4.iconfinder.com/data/icons/bettericons/354/facebook-circle-128.png" width='25'
                                                           height='25' alt='facebook icon' >
                                                    </a>
                                                    </span>
    </div>
</div>
 
    `
})
export class AppFooterComponent {

}
