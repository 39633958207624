import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { LazyLoadEvent } from 'primeng/primeng';
import { URLSearchParams } from '@angular/http';


@Component({
  selector: 'app-tabla-amortizacion',
  templateUrl: './tabla-amortizacion.component.html',
  styleUrls: ['./tabla-amortizacion.component.css']
})
export class TablaAmortizacionComponent implements OnInit {

  /**
   * CAMPOS
   */
  tituloHTML = '';
  esNuevo:boolean = false;
  formulario: FormGroup;
  //Datos de Tabla Lazy
  datosTabla:any[] = [];
  filtroTabla: any;
  tamanioPagina: number = 10;
  paginaActual: number = 0;
  totalRegistros: number;  

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiRequestService,
    public appComponent: AppComponent 
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.amortizations');    
  }

  nuevo(){
    this.nuevoFormulario();    
    this.esNuevo = true;
  }

  nuevoFormulario(){
    return this.formulario = this.formBuilder.group({
      id:[''],
      categoria:[, Validators.required],
      anios:[0, Validators.required]
    })
  }

  loadLazy(event: LazyLoadEvent) {
    setTimeout(() => {
      let params: URLSearchParams = new URLSearchParams();
      this.tamanioPagina = event.rows;
      this.paginaActual = event.first;
      if (this.paginaActual !== 0) {
        this.paginaActual = this.paginaActual / this.tamanioPagina;
      }
      params.set('pagina', this.paginaActual.toString());
      params.set('tamanio', this.tamanioPagina.toString());
      params.set('parametroBusqueda', this.filtroTabla);
      this.cargaTabla(params);
    }, 25);
  }

  cargaTabla(params: URLSearchParams) {
    this.appComponent.loader = true;
    this.api.get('tablaAmortizacion/lista', this.tituloHTML, params).subscribe((data) => {
      if (data != null) {
        this.datosTabla = data.content;
        this.totalRegistros = data.totalElements;
        this.paginaActual = Number(data.number) + 1;
      }
      this.appComponent.loader = false;
    }, error => {
      this.appComponent.loader = false;
    });
  }

  busqueda() {
    let params: URLSearchParams = new URLSearchParams();
    params.set('pagina', (this.paginaActual - 1).toString());
    params.set('tamanio', this.tamanioPagina.toString());
    params.set('parametroBusqueda', this.filtroTabla);
    this.cargaTabla(params);
  }

  seleccionRegistro($registro){
    this.formulario = this.formBuilder.group({
      id:$registro.id,
      categoria:[{ id: $registro.idCategoria, nombre: $registro.nombreCategoria, idCategoriaPadre: $registro.idCategoriaPadre }, Validators.required],
      anios: [$registro.anios, Validators.required]
    });
    this.esNuevo = true;
  }

  retorno(evento){
    if(evento != null){
      this.esNuevo = evento.esNuevo;
    }
  }

}
