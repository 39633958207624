import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { OperationResponse } from '../isa/common/operationResponse';
import { isNumber } from 'util';
import { ResponseStatusEnum } from '../isa/domains/responseStatusEnum';
import { ApiRequestService } from '../../app/services/api-request.service';
import { Validators } from '@angular/forms/src/validators';
import { PatternValidator } from '@angular/forms/src/directives/validators';
import { EnmTipoIdentificacion } from '../logica/enum/enmTipoIdentificacion';
import { EnmSexo } from '../logica/enum/enmSexo';
import { EnmLogica } from '../logica/enum/enmLogica';
import { EnmNivelAcademico } from '../logica/enum/enmNivelAcademico';

declare var moment: any;


@Injectable()
export class EnumService {

    
    constructor(private api: ApiRequestService
    ) { }




    nombreTipoIdentificacion(idTipoIdentificacion):String{
        var respuesta:String = '';

        switch(idTipoIdentificacion){
            case 1 :
            respuesta = EnmTipoIdentificacion.CedulaLabel;
            break;
        }
        return respuesta;
    }

    nombreSexo(idSexo):String{
        var respuesta:String = '';

        console.log(idSexo);

        switch(idSexo){
            case 1 :
            respuesta = EnmSexo.MasculinoLabel;
            break;
            case 2 :
            respuesta = EnmSexo.FemeninoLabel;
            break;
            default:
            respuesta = '';
            break;
        }
        console.log('RESPUESTA');
        console.log(respuesta);
        return respuesta;
    }

 

    nombreLogica(idLogica):String{
        var respuesta:String = '';

        switch(idLogica){
            case 1 :
            respuesta = EnmLogica.SiLabel;
            break;
            case 2 :
            respuesta = EnmLogica.NoLabel;
            break;
            case 3 :
            respuesta = EnmLogica.OtroLabel;
            break;
            default:
            respuesta = '';
            break;
        }
        return respuesta;
    }

  

}