import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { ApiRequestService } from '../../services/api-request.service';
import { UserInfoService } from '../../services/user-info.service';
import { isNull, error } from 'util';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';

import { Moment } from 'moment/locale/es';
import { Constantes } from '../../constantes';
import { Chart, StockChart, Highcharts } from 'angular-highcharts';
import { UtilService } from '../../services/util.service';
import { MdcDialog, MdcDialogComponent, MdcDialogRef, MdcTextField } from '@angular-mdc/web';


declare var moment: any;
@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})

export class DashboardDemoComponent implements OnInit {

    optionsTOP5Clientes: any;
    options3: any;
    compras: any;
    ventas: any;
    clientesNuevos: any;
    clientes: any;
    optionsTOP5: any;
    options2: any;
    chart: any;
    cities: SelectItem[];
    desripTiempo: any;

    events: any[];
    CardVentas: boolean = false;
    CardCompras: boolean = false;
    CardCliente: boolean = false;
    ventas_compras: boolean = false;
    top: boolean = false;
    stock_min: boolean = false;
    catego: boolean = false;

    selectedCity: any;
    options: any;

    types: SelectItem[];

    tipoSeleccionado: string;
    fechaFiltro: Date;
    fechaMaxima: Date = new Date();
    areaDiarioVentasCompras: Chart;
    barrasStockProducto: Chart;
    barraTopProductos: Chart;
    barraTopClientes: Chart;
    polarTopCategoria: Chart;
    subtituloGlobal: string;
    @ViewChild('filtroDialog') filtroDialog: MdcDialogComponent;



    constructor( private api: ApiRequestService,
        public userInfoService: UserInfoService, public utilService: UtilService) { }
    openDialog() {
        this.filtroDialog.show();
    }

    ngOnInit() {

        this.types = [
            { label: 'Dia', value: 'D' },
            { label: 'Semana', value: 'S' },
            { label: 'Mes', value: 'M' },
            { label: 'Año', value: 'A' }
        ];


        this.cities = [];
        this.cities.push({ label: 'Select City', value: null });
        this.cities.push({ label: 'New York', value: { id: 1, name: 'New York', code: 'NY' } });
        this.cities.push({ label: 'Rome', value: { id: 2, name: 'Rome', code: 'RM' } });
        this.cities.push({ label: 'London', value: { id: 3, name: 'London', code: 'LDN' } });
        this.cities.push({ label: 'Istanbul', value: { id: 4, name: 'Istanbul', code: 'IST' } });
        this.cities.push({ label: 'Paris', value: { id: 5, name: 'Paris', code: 'PRS' } });



        this.fechaFiltro = new Date();
        this.fechaMaxima = new Date();
        this.tipoSeleccionado = 'D';

        setTimeout(() => { this.tiempo('D', moment().format('YYYY-MM-DD')) }, 2000);

    }


    cargarGraficos(tiempo, fecha: string) {

        let parametros: URLSearchParams = new URLSearchParams();
        parametros.set('tipoFecha', tiempo);
        parametros.set('fecha', moment(fecha).toString());
        parametros.set('limite', '5');

        this.subtituloGlobal = '';

        switch (tiempo) {
            case 'D': {
                this.subtituloGlobal = '' + moment(fecha).format('DD-MM-YYYY');
                break;
            }
            case 'S': {
                this.subtituloGlobal = moment(fecha).day(0).format("DD-MM-YYYY") + ' al ' + moment(fecha).day(6).format("DD-MM-YYYY");
                break;
            }
            case 'M': {
                this.subtituloGlobal = '' + moment(fecha).format('MMMM YYYY');
                break;
            }
            case 'A': {
                this.subtituloGlobal = '' + moment(fecha).format('YYYY');
                break;
            }
        }



        //Inicio Datos Dashboard
        this.api.get('api/contabilidad/comprobantekpi/obtenerDatosDashBoard', 'Dashboard', parametros).subscribe(
            rest => {
                this.clientes = rest.traficoClientes;
                this.ventas = rest.ventas;
                this.compras = rest.compras;
            }
        );
        //Fin Datos Dashboard


        //Inicio Area VentasCompras
        this.graficoAreaComprasVentas(tiempo, fecha);
        //Fin Area VentasCompras


        //Inicio Stock Minimos de Productos
        let seriesDatosMinimosProductos: any[] = [];
        let etiquetasMinimosProductos: any[] = [];
        this.api.get('api/contabilidad/productokpi/minimosProducto/5', 'Dashboard').subscribe(
            rest => {

                if (rest != null && rest.datasets != null && rest.datasets != undefined && rest.labels != undefined && rest.labels != null) {
                    rest.datasets.forEach(element => element != undefined ? seriesDatosMinimosProductos.push({ name: element.label, data: element.data, color: element.label == 'Stock' ? "#9BF7B1" : "#F76191" }) : null);
                    etiquetasMinimosProductos = rest.labels;
                } else {
                    seriesDatosMinimosProductos.push({ name: '-', data: [], color: "#9BF7B1" })
                    etiquetasMinimosProductos = ['-'];
                }

                this.barrasStockProducto = this.columnas3DGenerico('Stock/Mínimos de Productos', '', 'Stock/Inventario Productos', etiquetasMinimosProductos, seriesDatosMinimosProductos)
            },
            error => {
                seriesDatosMinimosProductos.push({ name: '-', data: [], color: "#9BF7B1" })
                etiquetasMinimosProductos = ['-'];
                this.barrasStockProducto = this.columnas3DGenerico('Stock/Mínimos de Productos', 'Sin Información', 'Stock/Inventario Productos', etiquetasMinimosProductos, seriesDatosMinimosProductos)

            }

        );
        //Fin Stock Minimos de Productos


        //Inicio Productos con mas ventas
        let seriesDatosMaximoProductos: any[] = [];
        let etiquetasMaximoProductos: any[] = [];
        this.api.get('api/contabilidad/productokpi/maximoProductos', 'Dashboard', parametros).subscribe(
            rest => {
                if (rest != null && rest.datasets != null && rest.datasets != undefined && rest.labels != undefined && rest.labels != null) {
                    rest.datasets.forEach(element => element != undefined ? seriesDatosMaximoProductos.push({ name: 'Cantidad: ', data: element.data, color: "#81d4fa" }) : null);
                    etiquetasMaximoProductos = rest.labels;
                } else {
                    seriesDatosMaximoProductos.push({ name: 'Cantidad: ', data: [0], color: "#81d4fa" })
                    etiquetasMaximoProductos.push(['-']);
                }
                this.barraTopProductos = this.barrasGenerico('Productos más vendidos', this.subtituloGlobal, 'Cantidad', etiquetasMaximoProductos, seriesDatosMaximoProductos);
            },
            error => {
                seriesDatosMaximoProductos.push({ name: 'Cantidad: ', data: [0], color: "#81d4fa" })
                etiquetasMaximoProductos.push(['-']);
                this.barraTopProductos = this.barrasGenerico('Productos más vendidos', 'Sin información', 'Cantidad', etiquetasMaximoProductos, seriesDatosMaximoProductos);
            }
        );
        //Fin Productos con mas ventas


        //Inicio Maximo Clientes
        let seriesDatosMaximoClientes: any[] = [];
        let etiquetasMaximoClientes: any[] = [];
        this.api.get('api/contabilidad/tercerokpi/maximoClientes', 'DashBoard', parametros).subscribe(
            rest => {
                if (rest != null && rest.datasets != null && rest.datasets != undefined && rest.labels != undefined && rest.labels != null) {
                    rest.datasets.forEach(element => element != undefined ? seriesDatosMaximoClientes.push({ name: 'Monto Venta', data: element.data, color: "#9BF7B1" }) : null);
                    etiquetasMaximoClientes = rest.labels;
                } else {
                    seriesDatosMaximoClientes.push({ name: 'Monto Venta', data: [0], color: "#9BF7B1" })
                    etiquetasMaximoClientes.push(['-']);
                }
                this.barraTopClientes = this.barrasGenerico('Top  Clientes', this.subtituloGlobal, 'Monto Venta', etiquetasMaximoClientes, seriesDatosMaximoClientes);
            },
            error => {
                seriesDatosMaximoClientes.push({ name: 'Monto Venta', data: [0], color: "#9BF7B1" })
                etiquetasMaximoClientes.push(['-']);
                this.barraTopClientes = this.barrasGenerico('Top  Clientes', 'Sin información', 'Monto Venta', etiquetasMaximoClientes, seriesDatosMaximoClientes);

            }
        );
        //Fin Maximo Clientes


        //Inicio Series Datos Categorias
        let seriesDatosMaximoCategorias: any[] = [];
        this.api.get('api/contabilidad/productokpi/maximoCategorias', 'Dashboard', parametros).subscribe(
            rest => {

                if (rest != null && rest != undefined && rest.labels != null && rest.labels != undefined && rest.labels != null) {
                    for (let i = 0; i < rest.labels.length; i++) {
                        seriesDatosMaximoCategorias.push({ type: 'column', name: rest.labels[i], data: [rest.datasets[0].data[i]] });
                    }
                } else {
                    seriesDatosMaximoCategorias.push({ type: 'column', name: '-', data: [0] });
                }

                this.polarTopCategoria = this.polarGenerico('Categorías más vendidas', this.subtituloGlobal, seriesDatosMaximoCategorias)
            },
            error => {
                seriesDatosMaximoCategorias.push({ type: 'column', name: '-', data: [] });
                this.polarTopCategoria = this.polarGenerico('Categorías más vendidas', 'Sin información', seriesDatosMaximoCategorias)
            }
        );
        //Fin Series Datos Categorias


    }

    cambiarGrafico() {
        this.tiempo(this.tipoSeleccionado, moment(this.fechaFiltro).format("YYYY-MM-DD"));
    }


    tiempo(tiempo, fecha: string) {
        this.cargarGraficos(tiempo, fecha);

        switch (tiempo) {
            case 'D': {
                this.desripTiempo = "del Día " + moment(fecha).format("DD") + " de " + moment(fecha).format("MMM") + " de " + moment(fecha).format("YYYY");
                break;
            }
            case 'S': {
                this.desripTiempo = moment(fecha).day(0).format("DD-MM-YYYY") + ' al ' + moment(fecha).day(6).format("DD-MM-YYYY");
                break;
            }
            case 'M': {
                moment.locale('es');
                this.desripTiempo = moment(fecha).format("MMMM") + ' del ' + moment(fecha).format("YYYY");
                break;
            }
            case 'A': {
                this.desripTiempo = moment().format("YYYY");
                break;
            }
        }
        let params2: URLSearchParams = new URLSearchParams();

        params2.set('codigo', Constantes.VISUALIZA_VENTAS);
        this.api.get('api/seguridad/parametrobusqueda', 'Dashboard', params2).subscribe(data => {
            if (data !== null && data.valor !== null) {
                this.CardVentas = data.valor;
            }
        }, error => {
            this.CardVentas = false;
        });
        let params3: URLSearchParams = new URLSearchParams();

        params3.set('codigo', Constantes.VISUALIZA_VENTAS);
        this.api.get('api/seguridad/parametrobusqueda', 'Dashboard', params3).subscribe(data => {
            if (data !== null && data.valor !== null) {
                this.CardCompras = data.valor;
            }
        }, error => {
            this.CardCompras = false;
        });

        let params4: URLSearchParams = new URLSearchParams();

        params4.set('codigo', Constantes.VISUALIZA_CLIENTES);
        this.api.get('api/seguridad/parametrobusqueda', 'Dashboard', params4).subscribe(data => {
            if (data !== null && data.valor !== null) {
                this.CardCliente = data.valor;
            }
        }, error => {
            this.CardCliente = false;
        });
        let params5: URLSearchParams = new URLSearchParams();

        params5.set('codigo', Constantes.VISUALIZA_GRAFICA_VENTA_COMPRAS);
        this.api.get('api/seguridad/parametrobusqueda', 'Dashboard', params5).subscribe(data => {
            if (data !== null && data.valor !== null) {
                this.ventas_compras = data.valor;
            }
        }, error => {
            this.ventas_compras = false;
        });

        let params6: URLSearchParams = new URLSearchParams();
        params6.set('codigo', Constantes.VISUALIZA_GRAFICA_TOP_5);
        this.api.get('api/seguridad/parametrobusqueda', 'Dashboard', params6).subscribe(data => {

            if (data !== null && data.valor !== null) {
                this.top = data.valor;
            }
        }, error => {
            this.top = false;
        });


        let params7: URLSearchParams = new URLSearchParams();
        params7.set('codigo', Constantes.VISUALIZA_GRAFICA_STOCK_MINIMO);
        this.api.get('api/seguridad/parametrobusqueda', 'Dashboard', params7).subscribe(data => {
            if (data !== null && data.valor !== null) {
                this.stock_min = data.valor;
            }
        }, error => {
            this.stock_min = false;
        });
        let params8: URLSearchParams = new URLSearchParams();
        params8.set('codigo', Constantes.VISUALIZA_GRAFICA_CATEGORIAS);
        this.api.get('api/seguridad/parametrobusqueda', 'Dashboard', params8).subscribe(data => {
            if (data !== null && data.valor !== null) {
                this.catego = data.valor;
            }
        }, error => {
            this.catego = false;
        });
    }


    graficoAreaComprasVentas(tiempo: string, fecha: string) {

        if (fecha.length == 4) {
            fecha = fecha + '-01-01';
        }

        let parametrosPorDia: URLSearchParams = new URLSearchParams();
        parametrosPorDia.set('fecha', moment(fecha).toString());

        let servicio = '';
        let subtitulo = '';
        let categorias = [];

        switch (this.tipoSeleccionado) {
            case 'D': {
                servicio = 'api/contabilidad/movilkpi/obtenerDashBoard/comprasVentas';
                // subtitulo = 'Información del día ' + moment(fecha).format('DD-MM-YYYY');
                categorias = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]
                break;
            }
            case 'S': {
                servicio = 'api/contabilidad/movilkpi/comprasVentas/diasSemanas';
                // subtitulo = moment(fecha).day(0).format("DD-MM-YYYY") + ' al ' + moment(fecha).day(6).format("DD-MM-YYYY");
                categorias = this.utilService.listaDiasSemanaPorFecha(fecha);

                break;
            }
            case 'M': {
                servicio = 'api/contabilidad/movilkpi/comprasVentas/diasMeses';
                //subtitulo = 'Información de ' + moment(fecha).format('MMMM YYYY');
                categorias = this.utilService.listaDiasPorMes(fecha);

                break;
            }
            case 'A': {
                servicio = 'api/contabilidad/movilkpi/comprasVentas/mesesAnios';
                //subtitulo = 'Información del año ' + fecha;
                categorias = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
                break;
            }
        }

        let seriesDatos: any[] = [];
        //Inicio Area VentasCompras Diarias
        this.api.get(servicio, 'Dashboard', parametrosPorDia).subscribe(datos => {
            if (datos != null && datos != undefined) {
                datos.forEach(element => {
                    let elemento = { name: element.name, data: element.data, color: element.name == 'COMPRAS' ? "#81d4fa" : "#69f0ae" };
                    seriesDatos.push(elemento)
                });
            } else {
                seriesDatos.push({ name: '', data: [], color: "#81d4fa" });
            }
            this.areaDiarioVentasCompras = this.areaGenerico('Compras Ventas', subtitulo, 'Valor', categorias, seriesDatos);
        },
            error => {
                seriesDatos.push({ name: '', data: [], color: "#81d4fa" });
                this.areaDiarioVentasCompras = this.areaGenerico('Compras Ventas', 'Sin informacioón', 'Valor', categorias, seriesDatos);
            });
        //Fin Area VentasComprasDiarias


    }

    areaGenerico(titulo: string, subtitulo: string, tituloSerieY: string, categorias: any[], seriesDatos: any[]): Chart {
        return new Chart({
            chart: {
                type: 'areaspline',
                zoomType: "x",
                borderWidth: 0
            },
            title: {
                text: titulo
            },
            // subtitle: {
            //     text: subtitulo
            // },
            xAxis: {
                allowDecimals: false,
                categories: categorias,
            },
            yAxis: {
                title: {
                    text: 'Valor'
                }
            },
            tooltip: {
                shared: true,
                valueSuffix: "USD",
                // formatter: function () {
                //     return '<b>' + this.x +
                //         '</b> el valor es de <b>' + this.y + '</b>';
                // }, 
                backgroundColor: "#fafafa"
            },
            plotOptions: {
                area: {
                    marker: {
                        enabled: false,
                        symbol: 'circle',
                        radius: 2,
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    }
                },
                series: {
                    animation: {
                        duration: 4000
                    }
                }
            },
            series: seriesDatos,
            credits: { "enabled": false },
            exporting: {
                enabled: false
            }
        });
    }

    barrasGenerico(titulo: string, subtitulo: string, tituloSerieY: string, categorias: any[], seriesDatos: any[]): Chart {
        return new Chart({
            chart: {
                type: 'bar'
            },
            title: {
                text: titulo
            },


            xAxis: {
                categories: categorias,
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: tituloSerieY,
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: ''
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                shadow: true,
                enabled: false
            },
            credits: {
                enabled: false
            },
            series: seriesDatos,
            exporting: {
                enabled: false
            }
        });
    }

    polarGenerico(titulo: string, subtitulo: string, seriesDatos: any[]): Chart {
        return new Chart({

            chart: {
                polar: true
            },

            title: {
                text: titulo
            },

            // subtitle: {
            //     text: subtitulo
            // },

            yAxis: {
                min: 0
            },

            plotOptions: {
                series: {
                    pointStart: 0,

                },
                column: {
                    pointPadding: 0,
                    groupPadding: 0
                }
            },
            credits: {
                enabled: false
            },


            series: seriesDatos,
            exporting: {
                enabled: false
            }

        });
    }

    columnas3DGenerico(titulo: string, subtitulo: string, tituloSerieY: string, categorias: any[], seriesDatos: any[]): Chart {
        return new Chart({
            chart: {
                type: 'column',
                options3d: {
                    enabled: true,
                    alpha: 15,
                    beta: 15,
                    viewDistance: 25,
                    depth: 40
                }
            },
            title: {
                text: titulo
            },
            // subtitle: {
            //     text: subtitulo
            // },
            xAxis: {
                categories: categorias,
                labels: {
                    skew3d: true,
                    style: {
                        fontSize: '10px'
                    }
                }
            },
            yAxis: {
                allowDecimals: false,
                min: 0,
                title: {
                    text: tituloSerieY,
                    skew3d: true
                }
            },
            tooltip: {
                headerFormat: '<b>{point.key}</b><br>',
                pointFormat: '<span style="color:{series.color}">\u25CF</span> {series.name}: {point.y} / {point.stackTotal}'
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    depth: 40
                }
            },
            credits: {
                enabled: false
            },

            series: seriesDatos,
            exporting: {
                enabled: false
            }
        });
    }
}


