import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'round' })
export class RoundPipe implements PipeTransform {

  transform(num: number, precision: number = 0): number {
    return applyPrecision(num, precision);
  }
}


export function applyPrecision(num: number, precision: number) {
  if (precision <= 0) {
    return Math.round(num);
  }

  const tho = 10 ** precision;
  return Math.round(num * tho) / tho;
}
