import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent } from 'primeng/primeng';
import { AppComponent } from '../../../app.component';
import { ApiRequestService } from '../../../services/api-request.service';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';


@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css'],
  providers: [ConfirmationService]
})
export class UsuarioComponent implements OnInit {

  /**
   * CAMPOS
   */
  esNuevo: boolean = false;
  tituloHTML: string;
  tamanioPagina: number = 10;
  paginaActual: number = 0;
  objetos: any[];
  totalRegistros: number;
  formulario: FormGroup;
  editarImagen: boolean = false;
  uploadedFiles: any[] = [];
  imagenUsuario: any = '';
  blockSpecialnombres: RegExp = /^[a-zA-Z\s]*$/;
  filtroTabla = '';
  perfiles: any[] = [];
  porAsignar = false;
  usuariosLDAP: any[] = [];
  ubicacionesLista: any[] = [];


  /**
   * CONSTRUCTOR
   */

  constructor(private api: ApiRequestService,
    public appComponent: AppComponent,
    private formBuilder: FormBuilder,
    private http: Http,
    private domSanitizer: DomSanitizer,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.appComponent.validMenu('/home/usuario');
    this.cargarPerfiles();
    this.cargaInicialUbicaciones();
    this.esNuevo = false;
    this.tituloHTML = this.api.mensajeI18('common.user');
  }


  /**
   * METODOS
   */

  nuevo() {
    this.porAsignar = true;
    this.esNuevo = true;
    this.nuevoFormulario();
    this.imagenUsuario = '';
  }

  guardar() {
    this.appComponent.loader = true;

    if (this.formulario.valid) {
      this.api.post('usuario/guardar', this.asignarUsuario(), this.tituloHTML).subscribe(res => {
        if (res !== undefined && res !== null && res.operationStatus === "SUCCESS") {
          this.esNuevo = false;
          this.formulario.reset();
        }
        this.appComponent.mensaje(res.operationStatus, res.operationMessage);
        this.appComponent.loader = false;
      },
        error => {
          this.appComponent.loader = false;
        });
    } else {
      Object.keys(this.formulario.controls).forEach(field => {
        const control = this.formulario.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.appComponent.mensajeAdvertencia(this.api.mensajeI18('validation.invalidForm'));
      this.appComponent.loader = false;
    }

  }

  cancelar() {
    this.esNuevo = false;
    this.appComponent.clear();
  }

  nuevoFormulario() {

    return this.formulario = this.formBuilder.group({
      usuarioLDAP: [],
      id: '',
      email: ['', Validators.email],
      name: ['', Validators.required],
      perfil: ['', Validators.required],
      ubicaciones: ['', Validators.required],
      imagen: '',
      cambioClave: true,
      estado: true
    });
  }

  busqueda() {
    let params: URLSearchParams = new URLSearchParams();
    params.set('pagina', (this.paginaActual - 1).toString());
    params.set('tamanio', this.tamanioPagina.toString());
    params.set('parametro', this.filtroTabla);
    params.set('estado', 'true');
    this.cargaTabla(params);
  }

  cargarPerfiles() {
    if (!this.perfiles || this.perfiles.length == 0) {
      this.api.get('perfil/lista', this.tituloHTML).subscribe(data => {
        this.perfiles = data
        console.log(this.perfiles);
      });
    }
  }

  cargaInicialUbicaciones() {
    if (this.ubicacionesLista == null || this.ubicacionesLista.length == 0) {
      this.ubicacionesLista = [];
      this.api.get('ubicacion/listaCorta', this.tituloHTML).subscribe(res => this.ubicacionesLista = res);
    }
  }

  loadLazy(event: LazyLoadEvent) {
    setTimeout(() => {
      let params: URLSearchParams = new URLSearchParams();
      this.tamanioPagina = event.rows;
      this.paginaActual = event.first;
      if (this.paginaActual !== 0) {
        this.paginaActual = this.paginaActual / this.tamanioPagina;
      }
      params.set('pagina', this.paginaActual.toString());
      params.set('tamanio', this.tamanioPagina.toString());
      params.set('estado', 'true');
      params.set('parametro', this.filtroTabla);
      this.cargaTabla(params);
    }, 25);
  }

  cargaTabla(params: URLSearchParams) {
    this.appComponent.progressBarstatus = true;

    this.api.get('usuario/lista', this.tituloHTML, params).subscribe(data => {
      if (data != null) {
        this.objetos = data.content;

        if (this.objetos != null) {
          this.objetos.forEach(objImagen => {
            objImagen.imagen = objImagen.imagen == '' || objImagen.imagen == null ? '/assets/layout/images/Perfil.png' : this.domSanitizer.bypassSecurityTrustResourceUrl(objImagen.imagen);

          });
          this.totalRegistros = data.totalElements;
          this.paginaActual = data.number + 1;
          this.appComponent.progressBarstatus = false;
        }
        else {
          this.appComponent.progressBarstatus = false;
        }
      }
      else {
        this.appComponent.progressBarstatus = false;
      }
    });


  }

  editarImagenGuardada() {
    this.editarImagen = true;
  }

  cargarImagen(event) {
    this.uploadedFiles = [];
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    var url = event.files[0].objectURL.changingThisBreaksApplicationSecurity;

    this.http.get(url, { responseType: 3 }).subscribe(
      (res: Response) => {
        var reader = new FileReader();
        reader.readAsDataURL(res.blob());
        reader.onloadend = (e) => {
          this.imagenUsuario = reader.result;
        }
      }
    );
    this.appComponent.mensajeOK(this.api.mensajeI18('actions.successUploadImage'));
  }

  asignarUsuario() {
    const formulario =
    {
      id: this.formulario.get('id').value,
      name: this.formulario.get('name').value,
      email: this.formulario.get('email').value,
      estado: this.formulario.get('estado').value,
      cambioClave: this.formulario.get('cambioClave').value,
      imagen: this.imagenUsuario,
      perfil: (this.formulario.get('perfil').value ? this.formulario.get('perfil').value.id : null),
      ubicaciones: (this.formulario.get('ubicaciones').value ? this.formulario.get('ubicaciones').value : null)
    }
    return formulario;
  }

  async seleccionRegistro(event) {

    debugger;
    let ubicaciones = await this.ubicacionesPorUsuario(event.id) as any; 
    const ubicacionesIds = ubicaciones.map(item => item.id);
    const ubicacionesPorUsuario = this.ubicacionesLista.filter(x => ubicacionesIds.includes(x.id))


    this.porAsignar = false;

    this.formulario = this.formBuilder.group({
      id: event.id,
      email: [event.email, Validators.email],
      name: [event.name, Validators.required],
      perfil: [{  id: event.idPerfil          ? event.idPerfil      : event.perfil ? event.perfil.id : null, 
                  nombre: event.nombrePerfil  ? event.nombrePerfil  : event.perfil ? event.perfil.nombre : null }, Validators.required],
      ubicaciones: [ubicacionesPorUsuario,Validators.required],
      imagen: event.imagen,
      cambioClave: event.cambioClave,
      estado: true,
      usuarioLDAP: [],
    });

    this.esNuevo = true;
    this.editarImagen = false;

  }

  ubicacionesPorUsuario(idUsuario) {
    return new Promise((resolve, reject) => {
      this.api.get(`usuarioubicacion/ubicacionesPorUsuario/${idUsuario}`, this.tituloHTML).subscribe(data => resolve(data));
    });
  }

  eliminar() {

    this.confirmationService.confirm({
      message: this.appComponent.properties.mensajeConfirmacionEliminar,
      accept: () => {
        this.api.post('usuario/eliminar', { id: this.formulario.get('id').value }, this.tituloHTML).subscribe(res => {
          if (res !== undefined && res !== null && res.operationStatus === "SUCCESS") {
            this.esNuevo = false;
            this.formulario.reset();
          }
          this.appComponent.mensajeServer(res.operationStatus, res.operationMessage, this.tituloHTML);
        });
      }
    });

  }


  buscarUsuariosLDAP($event) {
    if ($event && $event.query && $event.query.length > 3) {
      let params: URLSearchParams = new URLSearchParams();
      params.set('parametro', $event.query);
      this.api.get('usuario/listaLDAP', this.tituloHTML, params).subscribe(res => {
        this.usuariosLDAP = [];
        this.usuariosLDAP = res;
        console.log(this.usuariosLDAP);
      })
    }

  }

  seleccionarUsuarioLDAP($event) {
    if ($event.username) {

      let params: URLSearchParams = new URLSearchParams();
      params.set('nombre', $event.username);
      this.api.get('seguridad/usuario', this.tituloHTML, params).subscribe(res => {
        if (res) {
          this.seleccionRegistro(res);
        }
      })
    }
  }

  cleanLocations(){
    this.formulario.patchValue({ubicaciones: []})
  }

}
