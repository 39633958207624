import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { SecuenciaTipoComprobante } from '../../../logica/clases/secuenciaTipoComprobante';


@Component({
  selector: 'app-secuencia-comprobante',
  templateUrl: './secuencia-comprobante.component.html',
  styleUrls: ['./secuencia-comprobante.component.css']
})
export class SecuenciaComprobanteComponent implements OnInit {

  /**
  * CAMPOS
  */
  tituloHTML = '';
  datosTabla: SecuenciaTipoComprobante[] = [];


  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('receipt.sequenceTitle');
    this.cargarRegistros();
  }

  cargarRegistros() {
    this.api.get('secuenciaTipoComprobante/lista', this.tituloHTML).subscribe(res => {
      this.datosTabla = res;
    });
  }

  modificar($evento) {
    this.api.post('secuenciaTipoComprobante/guardar', this.asignarEntidad($evento), this.tituloHTML).subscribe(res => {
      this.appComponent.mensaje(res.operationStatus, res.operationMessage);
    });
  }


  asignarEntidad($evento) {
    const entidad = {
      id: $evento.id,
      tipoComprobante: {id:$evento.idTipoComprobante, nombre:$evento.nombreTipoComprobante},
      prefijo: $evento.prefijo,
      secuencia: $evento.secuencia
    }
    return entidad;
  }



}
