import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Http, Headers, Response, Request, RequestOptions, URLSearchParams, RequestMethod } from '@angular/http';
import { AppConfig } from '../app-config';
import { ApiRequestService } from '../services/api-request.service';
import { AppComponent } from '../app.component';
import { ActivoComponenteCategoria } from '../logica/clases/activoComponenteCategoria';
import { EnmTipoValor } from '../logica/enum/enmTipoValor';
import { Activo } from '../logica/clases/activo';
import { ComponenteCategoria } from '../logica/clases/componenteCategoria';
import { Componente } from '../logica/clases/componente';
import { Categoria } from '../isa/domains/categoria';
import { Router } from '@angular/router';


@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.css', './qr.component.scss']
})
export class QrComponent implements OnInit {

  tituloHTML = 'Activo';
  servidorRegistro = this.appConfig.baseApiRegistro;
  activo: any = '';
  camposForaneos: any = '';
  listaActivoComponenteCategoria: ActivoComponenteCategoria[] = [];
  

  constructor(private route: ActivatedRoute, private http: Http, private appConfig: AppConfig,
    private api: ApiRequestService,
    private router: Router,) {

    let idActivo = this.route.snapshot.paramMap.get("id");

    if (idActivo != null && idActivo != '' && Number(idActivo) > 0) {
      this.http.get(this.servidorRegistro + 'activoQr/' + this.route.snapshot.paramMap.get("id")).subscribe(
        data => {
          if (data != null && data.json() != null && data.json().data != null) {
            debugger;
            this.activo = data.json().data.activo;
            this.activo.imagenRuta = (this.activo.imagen == null || this.activo.imagen == '') ? 'assets/isa/images/sin_imagen.png' : this.api.appConfig.servidorImagenActivos + this.activo.imagen;
            this.activo.facturaCompraRuta = (this.activo.factura_compra == null || this.activo.factura_compra == '') ? '' : this.api.appConfig.servidorFacturaCompra + this.activo.factura_compra;
            this.activo.imagenFacturaCompra = (this.activo.factura_compra == null || this.activo.factura_compra == '' ? 'assets/isa/images/sin_archivo.png' : 'assets/isa/images/archivo.png');
            this.camposForaneos = data.json().data.camposForaneos[0];
            this.cargaActivoComponenteCategoria();           
            console.log('Activo');
            console.log(this.activo); 
          }
        }
      );
    }

  }

  ngOnInit() {

  }


  cargaActivoComponenteCategoria() {

    if(this.camposForaneos && this.camposForaneos.idCategoria){
      this.http.post(this.servidorRegistro +'listaPorActivoYCategoria', { idActivo: this.activo.id, idCategoria: this.camposForaneos.idCategoria }).subscribe(res => {
        debugger;
        this.listaActivoComponenteCategoria = [];
        console.log('Respuesta');
        console.log(res.json());
        if (res != null && res.json() != null && res.json().data != null && res.json().data.length > 0) {
          res.json().data.forEach(item => {
            let acc: ActivoComponenteCategoria = new ActivoComponenteCategoria();
            acc.id = item.id;
            acc.tipoValor = item.tipoValor;
            acc.valorTexto = item.valorTexto;
            acc.valorNumero = item.valorNumero;
            if (acc.tipoValor == EnmTipoValor.Texto) {
              acc.valor = acc.valorTexto;
            } else if (acc.tipoValor == EnmTipoValor.Numero) {
              acc.valor = acc.valorNumero;
            }
            acc.activo = new Activo();
            acc.activo.id = this.activo.id;
            acc.componenteCategoria = new ComponenteCategoria();
            acc.componenteCategoria.id = item.idComponenteCategoria;
            acc.componenteCategoria.estado = item.estadoComponenteCategoria;
            acc.componenteCategoria.componente = new Componente();
            acc.componenteCategoria.componente.id = item.idComponente;
            acc.componenteCategoria.componente.nombre = item.nombreComponente;
            acc.componenteCategoria.componente.tipoValor = item.tipoValorComponente;
            acc.componenteCategoria.componente.estado = item.estadoComponente;
            acc.componenteCategoria.categoria = new Categoria();
            acc.componenteCategoria.categoria.id = item.idCategoria;
            acc.componenteCategoria.categoria.nombre = item.nombreCategoria;
            acc.componenteCategoria.categoria.estado = item.estadoCategoria;
            this.listaActivoComponenteCategoria.push(acc);
          });
        } 
      });
  
    }
  }


  retornoInicial(){
    this.router.navigate(["login"]);
  }

  

}
