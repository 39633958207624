import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { ApiRequestService } from '../../../services/api-request.service';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

@Component({
  selector: 'app-impresion-qr',
  templateUrl: './impresion-qr.component.html',
  styleUrls: ['./impresion-qr.component.css']
})
export class ImpresionQrComponent implements OnInit {

  //Campos
  listaActivo: any = [] = [];
  mostrarActivo: boolean = false;
  todosActivo: boolean = true;
  tituloHTML = '';



  constructor(public appComponent: AppComponent, private api: ApiRequestService, ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.printCode');
  }


  imprimir() {
    this.appComponent.loader = true;
    if (this.listaActivo != null && this.listaActivo.length > 0) {
      html2canvas(document.getElementById('content')).then(function (canvas) {
        var img = canvas.toDataURL("image/png");
        var doc = new jsPDF("p", "mm", "a4");
        doc.addImage(img, 'JPEG', 12, 0);
        doc.autoPrint();
        doc.save('codigosGenerados.pdf');
      });
    } else {
      this.appComponent.mensajeAdvertencia('No existen registros a imprimir');
      this.appComponent.loader = false;
    }
    setTimeout(() => {
      this.appComponent.loader = false;
    }, 1000);
  }

  cancelar() {

  }

  agregarActivo() {
    this.mostrarActivo = true;
  }


  retornoActivo($event) {
    if (this.listaActivo != null) {
      if (this.listaActivo != null && this.listaActivo.length < 21) {
        let item = { id: $event.activo.id, url: this.api.appConfig.apiProtocol + "//" + this.api.appConfig.apiHostName + ':' + this.api.appConfig.apiPortFront + '/#/qr/' + $event.activo.id, serial: $event.activo.serie };
        let existeItem = false;

        for (let itemBusqueda of this.listaActivo) {
          if (itemBusqueda && itemBusqueda.id == $event.activo.id) {
            existeItem = true;
          }
        }

        if (!existeItem) {
        
          this.listaActivo.push(item);
        }else{
          this.appComponent.mensajeAdvertencia('El registro seleccionado ya existe');          
        }

      } else {
        this.appComponent.mensajeAdvertencia('Se puede agregar máximo 21 activos');
      }
    }
    this.mostrarActivo = false;
  }


}
