import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { empty } from 'rxjs/internal/observable/empty';
import { ComprobanteDetalle } from '../../../logica/clases/comprobanteDetalle';
import { TreeNode } from 'primeng/components/common/treenode';
import { Activo } from '../../../logica/clases/activo';
import { Categoria } from '../../../isa/domains/categoria';
import { Ubicacion } from '../../../logica/clases/ubicacion';
import { EnmEstatusActaEntregaRecepcionComprobanteDetalle } from '../../../logica/enum/enmEstatusActaEntregaRecepcionComprobanteDetalle';
import { Router } from '@angular/router';


@Component({
  selector: 'app-activos-asignado',
  templateUrl: './activos-asignado.component.html',
  styleUrls: ['./activos-asignado.component.css'],
  providers: [ConfirmationService]
})
export class ActivosAsignadoComponent implements OnInit {


  /**
   * CAMPOS
   */
  formulario: FormGroup = this.formBuilder.group({
    id: [''],
    secuencia: ['TEMP', Validators.required],
    tipoComprobante: { id: 1 }, //Comprobante Acta de Entrega Recepcion
    usuario: ['', Validators.required],
    fechaCreacion: [new Date(), Validators.required],
    fechaFinalizacion: [''],
    observacion: ['']
  });
  tituloHTML = '';
  usuarios: any[] = [];
  comprobantesDetalles: ComprobanteDetalle[] = [];
  mostrarActivo: boolean = false;
  soloDisponibles: boolean = true;
  fechaActual: Date = new Date();
  es: any;
  activosAsignados: boolean = false;

  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.activesParameter', { valor1: this.api.mensajeI18('common.assigned') });
    this.cargaInicialUsuarios();
    this.cargaIdiomaCalendario();
    this.cargaInicialActaEntregaRecepcion();
  }

  cargaInicialActaEntregaRecepcion() {

    this.api.get('comprobante/actaEntregaRecepcionAsignada', this.tituloHTML).subscribe(res => {


      if (res != null && res.length == 1) {

        this.formulario = this.formBuilder.group({
          id: res[0].id,
          secuencia: res[0].secuencia,
          tipoComprobante: { id: res[0].idTipoComprobante },
          usuario: { email: res[0].emailUsuario, name: res[0].nameUsuario, username: res[0].usernameUsuario },
          fechaCreacion: res[0].fechaCreacion != null ? new Date(res[0].fechaCreacion) : null,
          fechaFinalizacion: res[0].fechaFinalizacion != null ? new Date(res[0].fechaFinalizacion) : null,
          observacion: res[0].observacion

        });

        this.api.get('comprobanteDetalle/listaPorComprobante/' + res[0].id, this.tituloHTML).subscribe(res => {

          if (res != null) {
            this.comprobantesDetalles = [];
            res.forEach(item => {
              if (item.estatus == EnmEstatusActaEntregaRecepcionComprobanteDetalle.Entregado) {
                let cd: ComprobanteDetalle = new ComprobanteDetalle();
                cd.id = item.id;
                cd.linea = item.linea;
                cd.estado = item.estado;
                cd.estatus = item.estatus;
                cd.fechaEntrega = item.fechaEntrega != null ? new Date(item.fechaEntrega) : null;
                cd.fechaRecepcion = item.fechaRecepcion != null ? new Date(item.fechaRecepcion) : null;
                cd.observacionEntrega = item.observacionEntrega;
                cd.observacionRecepcion = item.observacionRecepcion;
                cd.activo = new Activo();
                cd.activo.id = item.idActivo;
                cd.activo.descripcion = item.descripcionActivo;
                cd.activo.imagen = item.imagenActivo;
                cd.activo.imagenRuta = (item.imagenActivo == null || item.imagenActivo == '') ? 'assets/isa/images/sin_imagen.png' : this.api.appConfig.servidorImagenActivos + item.imagenActivo;
                cd.activo.nav = item.navActivo;
                cd.activo.serial = item.serialActivo;
                cd.activo.categoria = new Categoria();
                cd.activo.categoria.id = item.idCategoria;
                cd.activo.categoria.nombre = item.nombreCategoria;
                cd.activo.modelo = { id: item.idModelo, nombre: item.nombreModelo };
                cd.activo.marca = { id: item.idMarca, nombre: item.nombreMarca };
                cd.activo.ubicacion = new Ubicacion();
                cd.activo.ubicacion.id = item.idUbicacion;
                cd.activo.ubicacion.nombre = item.nombreUbicacion;
                this.comprobantesDetalles.push(cd);
              }
            });
          }

        })
      }

    });

  }


  cargaIdiomaCalendario() {
    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
      dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      today: 'Hoy',
      clear: 'Limpiar',
      dateFormat: 'dd/mm/yyyy'
    };

  }

  cargaInicialUsuarios() {
    if (this.usuarios == null || this.usuarios.length == 0) {
      this.api.get('usuario/listaLDAP', this.tituloHTML).subscribe(res => this.usuarios = res);
    }
  }


  cancelar() {
    this.router.navigate(["/home/bienvenida"]);
  }

  asignarEntidad() {

    let incremental = 1;
    this.comprobantesDetalles.forEach(cd => {
      if (cd.linea == null || cd.linea == 0) {
        cd.linea = incremental;
      }
      incremental++;
    });

    const entidad = {
      id: this.formulario.get('id').value,
      tipoComprobante: { id: 1 },
      secuencia: this.formulario.get('secuencia').value,
      fechaCreacion: this.formulario.get('fechaCreacion').value,
      observacion: this.formulario.get('observacion').value,
      usuario: this.formulario.get('usuario').value,
      comprobanteDetalles: this.comprobantesDetalles
    }

    return entidad;
  }



}
