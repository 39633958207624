import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { ConfirmationService } from 'primeng/primeng';
import { SelectItem } from 'primeng/components/common/selectitem';
import { Http, Response } from '@angular/http';

@Component({
  selector: 'app-configuracion-acta-erformulario',
  templateUrl: './configuracion-acta-erformulario.component.html',
  styleUrls: ['./configuracion-acta-erformulario.component.css']
})
export class ConfiguracionActaERFormularioComponent implements OnInit {

  /**
   * CAMPOS
   */
  @Output() public enviarPadre = new EventEmitter();
  @Input() formulario: FormGroup;
  tituloHTML = '';
  archivos: FormData = new FormData();
  imagen: any = '';
  uploadedFiles: any[] = [];
  tiposComprobantes: SelectItem[] = [
    { label: 'Acta ER IT', value: 1 },
    { label: 'Acta ER OTROS', value: 5 },
  ]


  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private confirmationService: ConfirmationService,
    private http: Http
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.configurationMinuteAR');
  }


  guardar() {
    this.appComponent.loader = true;
    if (this.formulario.valid) {
      this.archivos.append('entidad', new Blob([JSON.stringify(this.asignarEntidad())],
        { type: "application/json" }));
      this.api.postMultipartFile('configuracionActaER/guardar', this.archivos, this.tituloHTML).subscribe(res => {
        this.appComponent.mensaje(res.operationStatus, res.operationMessage);
        if (res.operationStatus == 'SUCCESS') {
          this.appComponent.loader = false;
          this.enviarPadre.emit({ esNuevo: false });
        }
        this.appComponent.loader = false;
      }, error => { this.appComponent.loader = false });
    } else {
      this.api.mensajeError(this.api.mensajeI18('validation.invalidForm'));
      Object.keys(this.formulario.controls).forEach(field => {
        console.log('Campo');
        console.log(field);
        const control = this.formulario.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.appComponent.loader = false;
    }
  }


  cancelar() {
    this.enviarPadre.emit({ esNuevo: false });
  }


  cargarImagen(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.archivos.append('imagen', this.uploadedFiles[0]);
    this.appComponent.mensajeInformacion(this.api.mensajeI18('actions.successUploadFile'));
  }

  asignarEntidad() {
    return {
      id: this.formulario.get('id').value,
      nombre: this.formulario.get('nombre').value,
      idTipoComprobante: this.formulario.get('tipoComprobante').value,
      cargo: this.formulario.get('cargo').value
    }
  }

}
