import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { empty } from 'rxjs/internal/observable/empty';
import { ComprobanteDetalle } from '../../clases/comprobanteDetalle';
import { TreeNode } from 'primeng/components/common/treenode';
import { EnmEstatusActivo } from '../../enum/enmEstatusActivo';
import { EnmTipoComprobante } from '../../enum/enmTipoComprobante';


@Component({
  selector: 'app-acta-otros-formulario',
  templateUrl: './acta-otros-formulario.component.html',
  styleUrls: ['./acta-otros-formulario.component.css'],
  providers: [ConfirmationService]
})
export class ActaOtrosFormularioComponent implements OnInit {

  /**
   * CAMPOS
   */
  @Output() public enviarPadre = new EventEmitter();
  @Input() formulario: FormGroup;
  tituloHTML = '';
  usuarios: any[] = [];
  usuariosLDAP: any[] = [];
  @Input() comprobantesDetalles: ComprobanteDetalle[] = [];
  mostrarActivo: boolean = false;
  soloDisponibles: boolean = true;
  fechaActual: Date = new Date();
  es: any;
  activosAsignados: boolean = false;

  renvioActa = false;
  correoRenvio = '';
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  mostrarActivosSugeridos = false;
  activosSugeridos: any[] = [];
  activosSugeridosSeleccionados: any[] = [];
  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.actDeliveryReceptionGeneral');
    this.cargaInicialUsuarios();
    this.es = this.appComponent.idiomaCalendario;
  }



  cargaInicialUsuarios() {
    if (this.usuarios == null || this.usuarios.length == 0) {
      this.api.get('usuario/listaLDAP', this.tituloHTML).subscribe(res => this.usuarios = res);
    }
  }

  guardar() {
    this.appComponent.loader = true;
    if (this.formulario.valid) {
      if (this.comprobantesDetalles != null && this.comprobantesDetalles.length > 0) {
        this.api.post('comprobante/guardar', this.asignarEntidad(), this.tituloHTML).subscribe(res => {
          this.appComponent.mensaje(res.operationStatus, res.operationMessage);
          if (res.operationStatus == 'SUCCESS') {
            this.appComponent.loader = false;
            this.enviarPadre.emit({ esNuevo: false });
          }
          this.appComponent.loader = false;
        }, error => this.appComponent.loader = false);

      } else {
        this.api.mensajeError('Es necesario agregar por lo menos un activo a asignar');
        this.appComponent.loader = false;
      }

    } else {
      this.api.mensajeError(this.api.mensajeI18('validation.invalidForm'));
      Object.keys(this.formulario.controls).forEach(field => {
        const control = this.formulario.get(field);
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
      this.appComponent.loader = false;
    }

  }

  cancelar() {
    this.enviarPadre.emit({ esNuevo: false });
  }

  asignarEntidad() {

    let incremental = 1;
    this.comprobantesDetalles.forEach(cd => {
      if (cd.linea == null || cd.linea == 0) {
        cd.linea = incremental;
      }
      incremental++;
    });

    const entidad = {
      id: this.formulario.get('id').value,
      tipoComprobante: { id: EnmTipoComprobante.ActaEntregaRecepcionGeneral.toString() },
      secuencia: this.formulario.get('secuencia').value,
      fechaCreacion: this.formulario.get('fechaCreacion').value,
      observacion: this.formulario.get('observacion').value,
      usuario: this.formulario.get('usuario').value,
      comprobanteDetalles: this.comprobantesDetalles
    }

    return entidad;
  }


  agregarActivo() {
    this.mostrarActivo = true;
  }

  seleccionUsuario($event) {
    if ($event != null && $event.username != null) {
      this.api.post('comprobante/existenciaPorUsuario', { usuario: $event.username, tipoComprobante: EnmTipoComprobante.ActaEntregaRecepcionGeneral.toString(), status: 1 }, this.tituloHTML).subscribe(res => {
        if (res.data != null) {
          this.appComponent.mensajeAdvertencia('El empleado ' + $event.name + ' ya posee una acta con número ' + res.data + ' si desea agregar activos a dicho empleado por favor modifique la acta mencionada')
        } else {
          let filtroActa: any[] = [];
          filtroActa.push(EnmEstatusActivo.Disponilble);
          let params: URLSearchParams = new URLSearchParams();
          params.set('parametroBusqueda', $event.name);
          params.set('estatus', filtroActa.toString());
          this.api.get(`activo/listaSugeridaActa`, this.tituloHTML, params).subscribe(resSugerido => {
            if (resSugerido && resSugerido.content) {
              this.activosSugeridos = resSugerido.content;

              this.activosSugeridos.forEach(dt => {
                dt.imagenRuta = (dt.imagen == null || dt.imagen == '') ? 'assets/isa/images/sin_imagen.png' : this.api.appConfig.servidorImagenActivos + dt.imagen + '?t=' + (new Date().getTime());
              });

              this.mostrarActivosSugeridos = true;
              this.activosSugeridosSeleccionados = this.activosSugeridos;

            }
          })


        }
      });
    }

  }

  retornoActivo($event) {

    if ($event != null && $event.activo != null) {
      let existeActivo: boolean = false;

      for (let item of this.comprobantesDetalles) {
        if (item.activo.id == $event.activo.id) {
          existeActivo = true;
        }
      }

      if (!existeActivo) {
        let cd: ComprobanteDetalle = new ComprobanteDetalle();
        cd.id = null;
        cd.activo = $event.activo;
        cd.fechaEntrega = new Date();
        cd.observacionEntrega = 'Equipo en perfectas condiciones';
        cd.fechaRecepcion = null;
        cd.estatus = 1; //Activo Asignado
        this.comprobantesDetalles.push(cd);
      } else {
        this.appComponent.mensajeAdvertencia('El activo del tipo ' + $event.activo.categoria.nombre + ' con número de serie ' + $event.activo.serie + ' ya se encuentra en esta acta.');
      }
    }

    this.mostrarActivo = false;

  }

  reenviar() {
    this.renvioActa = true;
    this.correoRenvio = this.formulario.get('usuario').value.email
  }

  rennviarAcccion() {
    if (this.correoRenvio.match(this.emailPattern)) {
      this.api.post('comprobante/renviarMail', { idComprobante: this.formulario.get('id').value, email: this.correoRenvio }, this.tituloHTML).subscribe(res => {
        if (res) {
          if (res.operationStatus === 'SUCCESS') {
            this.renvioActa = false;
            this.appComponent.mensaje(res.operationStatus, 'Correo enviado correctamente');
          } else {
            this.appComponent.mensaje(res.operationStatus, res.operationMessage);
          }
        }
      })
    } else {
      this.api.mensajeError('El correo electrónico es incorrecto');
    }
  }

  verActa() { 
    this.api.get('comprobante/verActa/' + this.formulario.get('id').value, this.tituloHTML).subscribe(res => {
      if (res) {
        const url = this.api.appConfig.servidorAER + res.comprobante;
        window.open(url, "_blank");
      }
    });
  }


  agregarSugeridos() {
    console.log(this.activosSugeridosSeleccionados);

    if (this.activosSugeridosSeleccionados && this.activosSugeridosSeleccionados.length > 0) {
      this.activosSugeridosSeleccionados.forEach(x => {

        const entidad = {
          id: x.id,
          categoria: { id: x.idCategoria, nombre: x.nombreCategoria },
          ubicacion: { id: x.idUbicacion, nombre: x.nombreUbicacion },
          marca: { id: x.idMarca, nombre: x.nombreMarca },
          modelo: { id: x.idModelo, nombre: x.nombreModelo, idMarca: x.idMarca },
          serie: x.serie,
          nav: x.nav,
          fechaCompra: x.fechaCompra != null ? new Date(x.fechaCompra) : null,
          fechaFinGarantia: x.fechaFinGarantia != null ? new Date(x.fechaFinGarantia) : null,
          descripcion: x.descripcion,
          imagen: x.imagen,
          imagenRuta: x.imagenRuta,
          facturaCompra: x.facturaCompra,
          facturaCompraRuta: x.facturaCompraRuta,
          imagenFacturaCompra: x.imagenFacturaCompra
        }
  
        this.retornoActivo({activo: entidad});
      });
      this.mostrarActivosSugeridos = false;
    } else {
      this.appComponent.mensajeAdvertencia('Es necesario seleccionar al menos un activo par agregar');
    }

  }

  buscarUsuariosLDAP($event) {
    if ($event && $event.query && $event.query.length > 3) {
      let params: URLSearchParams = new URLSearchParams();
      params.set('parametro', $event.query);
      this.api.get('usuario/listaLDAP', this.tituloHTML, params).subscribe(res => {
        this.usuariosLDAP = [];
        this.usuariosLDAP = res;
        console.log(this.usuariosLDAP);
      })
    }

  }

}
