import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'estadoPagoPipe'
})
export class EstadoPagoPipe implements PipeTransform {

  transform(value: number): string {
    return value  == 1  ? 'Pendiente' :  value == 2 ? 'Pagado' : value == 3 ? 'Pagado Parcialmente' :'';
  }

}


