import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { ApiRequestService } from '../../services/api-request.service';
import { UserInfoService } from '../../services/user-info.service';
import { Router } from '@angular/router';
declare var document: Document;
declare var $: any;
@Component({
  selector: 'app-bienvenida',
  templateUrl: './bienvenida.component.html',
  styleUrls: ['./bienvenida.component.scss']
})
export class BienvenidaComponent implements OnInit {


  constructor(  public api: ApiRequestService,private userInfoService: UserInfoService, private router: Router) { }

  objetoBotones:any=0;
  tituloHtml:string = '';

  ngOnInit() {
    this.tituloHtml = '';
    this.api.get('menu/listaDashboard',this.tituloHtml).subscribe(data => {
      this.objetoBotones = data;
    });
  }


redireccion(ruta){
  this.router.navigate([ruta])
}

}
