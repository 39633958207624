import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { ConfirmDialogModule, ConfirmationService } from 'primeng/primeng';
import { Http, Response, RequestOptions, Headers, URLSearchParams } from '@angular/http';


@Component({
  selector: 'app-categoria-formulario',
  templateUrl: './categoria-formulario.component.html',
  styleUrls: ['./categoria-formulario.component.css'],
  providers: [ConfirmationService]
})
export class CategoriaFormularioComponent implements OnInit {

  /**
   * CAMPOS
   */
  @Output() public enviarPadre = new EventEmitter();
  @Input() formulario: FormGroup;
  tituloHTML = '';
  imagen: any = '';
  uploadedFiles: any[] = [];
  editarImagen = false;




  constructor(
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private confirmationService: ConfirmationService,
    private http: Http

  ) {
  }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.category');
    if(this.formulario.get('id').value == null || this.formulario.get('id').value == ''){
      this.editarImagen = true;
    }
  }

  cancelar() {
    this.enviarPadre.emit({ esNuevo: false });
  }


  guardar() {

    this.appComponent.loader = true;

    if (this.formulario.valid) {
      this.api.post('categoria/guardar', this.asignarCategoria(), this.tituloHTML).subscribe(res => {
        if (res !== undefined && res !== null && res.operationStatus === "SUCCESS") {
          this.appComponent.loader = false;
          this.imagen = '';
          this.enviarPadre.emit({ esNuevo: false });
        }
        this.appComponent.loader = false;
        this.appComponent.mensajeServer(res.operationStatus, res.operationMessage, this.tituloHTML);
      });
    } else {
      this.api.mensajeError(this.api.mensajeI18('validation.invalidForm'));
      this.appComponent.loader = false;
      Object.keys(this.formulario.controls).forEach(field => {
        const control = this.formulario.get(field);
        if (control.invalid) {
          console.log('Campo invalido');
          console.log(field);
        }
        control.markAsDirty({ onlySelf: true });
        control.markAsTouched({ onlySelf: true });
      });
    }
  }

  eliminar() {
    this.confirmationService.confirm({
      message: this.appComponent.properties.mensajeConfirmacionEliminar,
      accept: () => {
        this.api.delete('categoria/eliminar/' + this.formulario.get('id').value, this.tituloHTML).subscribe(res => {
          if (res !== undefined && res !== null && res.operationStatus === "SUCCESS") {
            this.enviarPadre.emit({ esNuevo: false });
          }
          this.appComponent.mensajeServer(res.operationStatus, res.operationMessage, this.tituloHTML);
        },
        error => {
          this.appComponent.mensajeServer('ERROR', 'No se puede eliminar porque tiene dependencias asociadas', this.tituloHTML);
        });
      }
    });
  }

  asignarCategoria() {
    const formulario =
      {
        id: this.formulario.get('id').value,
        nombre: this.formulario.get('nombre').value,
        descripcion: this.formulario.get('descripcion').value,
        categoriaPadre: this.formulario.get('categoriaPadreId').value,
        estado: this.formulario.get('estado').value,
        nav: this.formulario.get('nav').value,
        imagen: this.imagen
      }
    return formulario;
  }

  cargarImagen(event) {
    this.imagen = '';
    this.uploadedFiles = [];
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    var url = event.files[0].objectURL.changingThisBreaksApplicationSecurity;

    this.http.get(url, { responseType: 3 }).subscribe(
      (res: Response) => {
        var reader = new FileReader();
        reader.readAsDataURL(res.blob());
        reader.onloadend = (e) => {
          this.imagen = reader.result;
        }
      }
    );
    this.appComponent.mensajeInformacion('Imagen cargada con exito');
  }


  editarImagenGuardada() {
    this.editarImagen = true;
  }


}
