import { Injectable, Inject, NgZone, } from '@angular/core';
import { Http, Headers, Response, Request, RequestOptions, URLSearchParams, RequestMethod } from '@angular/http';
import { Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { UserInfoService, UserInStorage } from './user-info.service';
import { AppConfig } from '../app-config';
import { HttpRequest, HttpEvent, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/primeng';
import { EventSourcePolyfill } from 'ng-event-source';

import { HttpModule } from '@angular/http'; //NEW


@Injectable()
export class ApiRequestService {

    private headers: Headers;
    private requestOptions: RequestOptions;
    public TimeFormat: string;
    constructor(
        public appConfig: AppConfig,
        private http: Http,
        private router: Router,
        private userInfoService: UserInfoService,
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer,
        public translate: TranslateService,
        private messageService: MessageService,
        private zone: NgZone,
        private httpModule: HttpModule

    ) {
        //idiomas disponibles
        translate.addLangs(["en", "es"]);
        //idioma por defecto
        translate.setDefaultLang('es');
        translate.use('es');
        this.TimeFormat = 'DD/MM/YYYY h:mm:ss a';
    }

    /**
     * This is a Global place to add all the request headers for every REST calls
     */
    appendAuthHeader(): Headers {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let token = this.userInfoService.getStoredToken();
        if (token !== null && token !== undefined) {
            headers.append("Authorization", token);
        }
        return headers;
    }

    appendAuthHeaderValidateCredential(): Headers {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        headers.append('Authorization', 'Basic ' + btoa("eagleeye:thisissecret"));
        return headers;
    }

    appendAuthHeaderCaptcha(): Headers {
        let headers = new Headers({ 'Content-type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS' });
        return headers;
    }

    /**
     * This is a Global place to define all the Request Headers that must be sent for every ajax call
     */
    getRequestOptions(requestMethod, url: string, urlParam?: URLSearchParams, body?: Object): RequestOptions {
        let options = new RequestOptions({
            headers: this.appendAuthHeader(),
            method: requestMethod,
            url: this.appConfig.baseApiPath + url   //this.api + url,
        });
        if (urlParam) {
            options = options.merge({ params: urlParam });
        }
        if (body) {
            options = options.merge({ body: JSON.stringify(body) });
        }
        return options;
    }


    getRequestOptionsLogin(requestMethod, url: string, urlParam?: URLSearchParams, body?: Object): RequestOptions {
        let options = new RequestOptions({
            headers: this.appendAuthHeader(),
            method: requestMethod,
            url: this.appConfig.baseApiPathAuth + url   //this.api + url,
        });
        if (urlParam) {
            options = options.merge({ params: urlParam });
        }
        if (body) {
            options = options.merge({ body: JSON.stringify(body) });
        }
        return options;
    }


    getRequestOptionsValidateCredential(requestMethod, url: string, urlParam?: URLSearchParams, body?: Object): RequestOptions {
        let options = new RequestOptions({
            headers: this.appendAuthHeaderValidateCredential(),
            method: requestMethod,
            url: this.appConfig.baseApiPathAuth + url   //this.api + url,
        });
        if (urlParam) {
            options = options.merge({ params: urlParam });
        }
        if (body) {
            options = options.merge({ body: JSON.stringify(body) });
        }
        return options;
    }


    getRequestOptionsCaptcha(requestMethod, url: string, urlParam?: URLSearchParams, body?: Object): RequestOptions {
        let options = new RequestOptions({
            headers: this.appendAuthHeaderCaptcha(),
            method: requestMethod,
            url: url   //this.api + url,
        });
        if (urlParam) {
            options = options.merge({ params: urlParam });
        }
        if (body) {
            options = options.merge({ body: JSON.stringify(body) });
        }
        return options;
    }

    getEventSource(url: string, tituloPagina: string, urlParams?: URLSearchParams): Observable<any> {

        let urlServer = this.appConfig.baseApiPath;
        let token = this.userInfoService.getStoredToken();

        return Observable.create((observer) => {
            let eventSource = new EventSourcePolyfill(urlServer + url, { headers: { Authorization: token } });

            eventSource.onmessage = (event) => {
                this.zone.run(() => {
                    const json = JSON.parse(event.data);
                    observer.next(json);
                });
            };
            eventSource.onerror = (error) => observer.error('eventSource.onerror: ' + error);

        });

    }


    get(url: string, tituloPagina: string, urlParams?: URLSearchParams): Observable<any> {
        let me = this;
        let requestOptions = this.getRequestOptions(RequestMethod.Get, url, urlParams);
        return this.http.request(new Request(requestOptions))
            .map(resp => resp.json().data)
            .catch(function (error: any) {
                let resp: any = JSON.parse(error['_body']);
                if (resp.exception == me.appConfig.zuulException) {
                    me.mensajeServer('error', 'Servicio no disponible', tituloPagina);
                    return Observable.throw(error || 'Server error');
                } else {
                    if (error.status === 401 || error.status === 403) {
                        me.router.navigate(['/logout']);
                    } else if (resp.operationStatus != null && resp.operationStatus != undefined) {
                        me.mensajeServer(resp.operationStatus, resp.operationMessage, tituloPagina);
                        return Observable.throw(error || 'Server error');
                    }
                }
            });
    }

    post(url: string, body: Object, tituloPagina: string, params?: URLSearchParams): Observable<any> {
        let me = this;
        let requestOptions = this.getRequestOptions(RequestMethod.Post, url, params, body);
        return this.http.request(new Request(requestOptions))
            .map(resp => resp.json())
            .catch(function (error: any) {
                let resp: any = JSON.parse(error['_body']);
                if (resp.exception == me.appConfig.zuulException) {
                    me.mensajeServer('error', 'Servicio no disponible', tituloPagina);
                    return Observable.throw(error || 'Server error');
                } else {
                    if (error.status === 401 || error.status === 403) {
                        me.router.navigate(['/logout']);
                    } else if (resp.operationStatus != null && resp.operationStatus != undefined) {
                        me.mensajeServer(resp.operationStatus, resp.operationMessage, tituloPagina);
                        return Observable.throw(error || 'Server error');
                    }
                }
            });
    }

    postMultipartFile(url: string, body: Object, tituloPagina: string): Observable<any> {
        let me = this;
        let urlServer = this.appConfig.baseApiPath + url;
        let token = this.userInfoService.getStoredToken();

        const httpRequest = new HttpRequest('POST', urlServer, body, {
            headers: new HttpHeaders().set('Authorization', token),
            reportProgress: true,
            responseType: 'text'
        });

        return this.httpClient.post(urlServer, body, {
            headers: new HttpHeaders().set('Authorization', token),
        }).map(a => a).catch(function (error: any) {

            if (error.name == me.appConfig.zuulException) {
                me.mensajeServer('error', 'Servicio no disponible', tituloPagina);
                return Observable.throw(error || 'Server error');
            } else {
                if (error.status === 401) {
                    me.router.navigate(['/logout']);
                } else if (error.error.operationStatus != null && error.error.operationStatus != undefined) {
                    me.mensajeServer(error.error.operationStatus, error.error.operationMessage, tituloPagina);
                    return Observable.throw(error || 'Server error');
                }
            }
        });
    }

    getImageFromPath(path: string): Observable<any> {

        let me = this;
        let urlServer = this.appConfig.baseApiPath + 'api/seguridad/loadimage';
        let token = this.userInfoService.getStoredToken();

        return this.httpClient.get(urlServer, {
            headers: new HttpHeaders().set('Authorization', token),
            reportProgress: true,
            responseType: "arraybuffer",
            params: new HttpParams().set('path', path)
        }).map(res => res).catch(function (error: any) {
            if (error.status === 401) {
                me.router.navigate(['/logout']);
            }
            return Observable.throw(error || 'Server error')
        });
    }


    getFileByIdComprobanteFromPathEstado(path: string, idComprobante: string, estado: string): Observable<any> {

        let me = this;
        let urlServer = this.appConfig.baseApiPath + path;
        let token = this.userInfoService.getStoredToken();

        return this.httpClient.get(urlServer, {
            headers: new HttpHeaders().set('Authorization', token),
            reportProgress: true,
            responseType: "arraybuffer",
            params: new HttpParams().append('idComprobante', idComprobante).append('estado', estado)
        }).map(res => res).catch(function (error: any) {
            if (error.status === 401) {
                me.router.navigate(['/logout']);
            }
            return Observable.throw(error || 'Server error')
        });
    }


    getFileByIdComprobanteFromPath(path: string, idComprobante: string): Observable<any> {
        let me = this;
        let urlServer = this.appConfig.baseApiPath + path;
        let token = this.userInfoService.getStoredToken();

        return this.httpClient.get(urlServer, {
            headers: new HttpHeaders().set('Authorization', token),
            reportProgress: true,
            responseType: "arraybuffer",
            params: new HttpParams().set('idComprobante', idComprobante)
        }).map(res => res).catch(function (error: any) {
            if (error.status === 401) {
                me.router.navigate(['/logout']);
            }
            return Observable.throw(error || 'Server error')
        });
    }

    getParametrosReporte(path: string, httpParams?: HttpParams): Observable<any> {
        let me = this;
        let urlServer = this.appConfig.baseApiPath + path;
        let token = this.userInfoService.getStoredToken();
        return this.httpClient.get(urlServer, {
            headers: new HttpHeaders().set('Authorization', token),
            reportProgress: true,
            responseType: "arraybuffer",
            params: httpParams
        }).map(res => res).catch(function (error: any) {
            if (error.status === 401) {
                me.router.navigate(['/logout']);
            }
            return Observable.throw(error || 'Server error')
        });
    }


    getInfoUsuario(): UserInStorage {
        return this.userInfoService.getUserInfo();
    }

    postLogin(url: string, urlParam?: URLSearchParams): Observable<any> {
        let me = this;
        let requestOptions = this.getRequestOptionsLogin(RequestMethod.Post, url, urlParam, undefined);
        return this.http.request(new Request(requestOptions))
            .map(resp => resp.json())
            .catch(function (error: any) {
                if (error.status === 401) {
                    //  me.router.navigate(['/logout']);
                }

                return Observable.throw(error || 'Server error')
            });
    }


    postLogout(url: string, urlParam?: URLSearchParams): Observable<any> {
        let me = this;
        let requestOptions = this.getRequestOptionsLogin(RequestMethod.Post, url, urlParam, undefined);
        return this.http.request(new Request(requestOptions))
            .map(resp => resp.json())
            .catch(function (error: any) {
                if (error.status === 401) {
                    //  me.router.navigate(['/logout']);
                }

                return Observable.throw(error || 'Server error')
            });
    }

    postVerifyCredential(url: string, urlParam?: URLSearchParams): Observable<any> {
        let me = this;
        let requestOptions = this.getRequestOptionsValidateCredential(RequestMethod.Post, url, urlParam, undefined);
        return this.http.request(new Request(requestOptions))
            .map(resp => resp.json())
            .catch(function (error: any) {
                if (error.status === 401) {
                    //  me.router.navigate(['/logout']);
                }

                return Observable.throw(error || 'Server error')
            });
    }


    postCaptcha(url: string, urlParam?: URLSearchParams): Observable<any> {
        let me = this;
        let requestOptions = this.getRequestOptionsCaptcha(RequestMethod.Post, url, urlParam, undefined);
        return this.http.request(new Request(requestOptions))
            .map(resp => resp.json())
            .catch(function (error: any) {
                if (error.status === 401) {
                    //  me.router.navigate(['/logout']);
                }

                return Observable.throw(error || 'Server error')
            });
    }

    put(url: string, body: Object, tituloPaguina: string): Observable<any> {
        let me = this;
        let requestOptions = this.getRequestOptions(RequestMethod.Put, url, undefined, body);
        return this.http.request(new Request(requestOptions))
            .map(resp => resp.json())
            .catch(function (error: any) {
                let resp: any = JSON.parse(error['_body']);
                if (resp.exception == me.appConfig.zuulException) {
                    me.mensajeError('Servicio no disponible');
                    return Observable.throw(error || 'Server error');
                } else {
                    if (error.status === 401 || error.status === 403) {
                        me.router.navigate(['/logout']);
                    } else if (resp.operationStatus != null && resp.operationStatus != undefined) {
                        me.mensajeServer(resp.operationStatus, resp.operationMessage, tituloPaguina);
                        return Observable.throw(error || 'Server error');
                    }
                }
            });
    }

    delete(url: string, tituloPagina: string): Observable<any> {
        let me = this;
        let requestOptions = this.getRequestOptions(RequestMethod.Delete, url);
        return this.http.request(new Request(requestOptions))
            .map(resp => resp.json())
            .catch(function (error: any) {
                let resp: any = JSON.parse(error['_body']);
                if (resp.exception == me.appConfig.zuulException) {
                    me.mensajeServer('error', 'Servicio no disponible', tituloPagina);
                    return Observable.throw(error || 'Server error');
                } else {
                    if (error.status === 401 || error.status === 403) {
                        me.router.navigate(['/logout']);
                    } else if (resp.operationStatus != null && resp.operationStatus != undefined) {
                        me.mensajeServer(resp.operationStatus, resp.operationMessage, tituloPagina);
                        return Observable.throw(error || 'Server error');
                    }
                }
            });
    }

    lazy(url: string, params: URLSearchParams, tituloPagina: string, page?: number, size?: number): Observable<any> {
        if (page !== 0) {
            page = page / size;
        }
        let items = new Subject<any>();
        if (params == null) {
            params = new URLSearchParams();
        }
        params.set('page', typeof page === "number" ? page.toString() : "0");
        params.set('size', typeof page === "number" ? size.toString() : "1000");
        this.get(url, tituloPagina, params)
            .subscribe(jsonResp => {
                let returnObj = Object.assign({}, jsonResp, {
                    content: jsonResp != null ? jsonResp.content : null
                })
                items.next(returnObj);
            });
        return items
    }

    mensajeI18(mensaje, interpolateParams?: Object): string {
        var respuesta: string;
        this.translate.get(mensaje, interpolateParams).subscribe((res: string) => respuesta = res, error => respuesta = '');
        return respuesta;
    }

    public mensajeServer(tipo: string, mensaje: string, titulo: string) {

        this.messageService.add({ severity: tipo.toLowerCase(), summary: titulo, detail: mensaje });
    }
    public mensajeError(mensaje: string) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: mensaje });
    }

   


}
