import { Component, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { AppComponent } from './app.component';
import { AppProperties } from './app.properties';
import { ApiRequestService } from './services/api-request.service';
import { UserInfoService } from './services/user-info.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ScrollPanel } from 'primeng/primeng';
declare var jQuery: any;

@Component({
    selector: 'app-rightpanel',
    templateUrl: './app.rightpanel.component.html',
    styleUrls: ['./app.rightpanel.component.scss']
})
export class AppRightpanelComponent implements  AfterViewInit {

    // rightPanelMenuScroller: HTMLDivElement;
    nombreempresa: any;
    ruc: any;
    logo: any;
    @ViewChild('scrollRightPanel') rightPanelMenuScrollerViewChild: ScrollPanel;


    // @ViewChild('rightPanelMenuScroller') rightPanelMenuScrollerViewChild: ElementRef;
    nombrePlan: any;
    totalInventario: any;
    totaPuntosEmision: any;
    totFacturacion: any;
    totalComprobantes: any;
    totalUsuarios: any;
    limiteComprobantes: any;
    limiteFacturacion: any;
    limiteInventario: any;
    limitePuntosEmision: any;
    limiteUsuarios: any;


    constructor(public app: AppComponent,
        private api: ApiRequestService,
        private userInfoService: UserInfoService,
        private domSanitizer: DomSanitizer,
    ) {

        
     }

    ngOnInit() {
        this.logo = "assets/isa/images/sin_imagen.png"
    }

    ngAfterViewInit() {

        // setTimeout(() => {this.rightPanelMenuScrollerViewChild.moveBar(); }, 100);
       
        // setTimeout(() => {
        //     jQuery(this.rightPanelMenuScroller).nanoScroller({ flash: true });
        // }, 10);
    }
    borrarNoti(id){
        var itemDinero = this.app.notificaciones.find(item => item.id == id);
        var index = this.app.notificaciones.indexOf(itemDinero);
        this.app.notificaciones.splice(index, 1);
        
    }

    isinfinite(a) {

        if (a == -1) {
            a = "∞";
        }

        return a;
    }
 
  
}
