import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { ApiRequestService } from '../../../services/api-request.service';
import { AppComponent } from '../../../app.component';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms'
import { LazyLoadEvent } from 'primeng/primeng';
import { URLSearchParams } from '@angular/http';
import { ComprobanteDetalle } from '../../../logica/clases/comprobanteDetalle';
import { Activo } from '../../../logica/clases/activo';
import { Categoria } from '../../../isa/domains/categoria';
import { Ubicacion } from '../../../logica/clases/ubicacion';
import { EnmTipoComprobante } from '../../../logica/enum/enmTipoComprobante';
import { ExcelService } from '../../../services/excel.service';
import { DatePipe } from '@angular/common';
import { EnmEstatusActaEntregaRecepcionComprobanteDetalle } from '../../../logica/enum/enmEstatusActaEntregaRecepcionComprobanteDetalle';
import { EnmStatusActaEntregaRecepcionComprobante } from '../../../logica/enum/enmStatusActaEntregaRecepcionComprobante';


@Component({
  selector: 'app-acta',
  templateUrl: './acta.component.html',
  styleUrls: ['./acta.component.css'],
  providers:[DatePipe]
  
})
export class ActaComponent implements OnInit {

  /**
   * CAMPOS
   */
  tituloHTML = '';
  esNuevo: boolean = false;
  formulario: FormGroup;
  //Datos de Tabla Lazy
  datosTabla: any[] = [];
  filtroTabla: any;
  tamanioPagina: number = 10;
  paginaActual: number = 0;
  totalRegistros: number;
  comprobantesDetalles: ComprobanteDetalle[] = [];



  constructor(
    private formBuilder: FormBuilder,
    private api: ApiRequestService,
    public appComponent: AppComponent,
    private excelService: ExcelService,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.tituloHTML = this.api.mensajeI18('common.actDeliveryReception');
  }


  nuevo() {
    this.nuevoFormulario();
    this.comprobantesDetalles = [];
    this.esNuevo = true;
  }

  nuevoFormulario() {
    return this.formulario = this.formBuilder.group({
      id: [''],
      secuencia: ['TEMP', Validators.required],
      tipoComprobante: { id: 1 }, //Comprobante Acta de Entrega Recepcion
      usuario: ['', Validators.required],
      fechaCreacion: [new Date(), Validators.required],
      fechaFinalizacion: [''],
      observacion: [''],
      status: [''] 
    })
  }


  loadLazy(event: LazyLoadEvent) {
    setTimeout(() => {
      let params: URLSearchParams = new URLSearchParams();
      this.tamanioPagina = event.rows;
      this.paginaActual = event.first;
      if (this.paginaActual !== 0) {
        this.paginaActual = this.paginaActual / this.tamanioPagina;
      }
      params.set('pagina', this.paginaActual.toString());
      params.set('tamanio', this.tamanioPagina.toString());
      params.set('parametroBusqueda', this.filtroTabla);
      params.set('tipoComprobante', EnmTipoComprobante.ActaEntregaRecepcion.toString())
      this.cargaTabla(params);
    }, 25);
  }

  cargaTabla(params: URLSearchParams) {
    this.appComponent.loader = true;
    this.api.get('comprobante/lista', this.tituloHTML, params).subscribe((data) => {
      if (data != null) {
        this.datosTabla = data.content;
        this.totalRegistros = data.totalElements;
        this.paginaActual = Number(data.number) + 1;
      }
      this.appComponent.loader = false;
    }, error => {
      this.appComponent.loader = false;
    });
  }

  busqueda() {
    let params: URLSearchParams = new URLSearchParams();
    params.set('pagina', (this.paginaActual - 1).toString());
    params.set('tamanio', this.tamanioPagina.toString());
    params.set('parametroBusqueda', this.filtroTabla);
    params.set('tipoComprobante', EnmTipoComprobante.ActaEntregaRecepcion.toString())
    this.cargaTabla(params);
  }


  seleccionRegistro($registro) {
    this.formulario = this.formBuilder.group({
      id: $registro.id,
      secuencia: $registro.secuencia,
      tipoComprobante: { id: $registro.idTipoComprobante },
      usuario: { email: $registro.emailUsuario, name: $registro.nameUsuario, username: $registro.usernameUsuario },
      fechaCreacion: $registro.fechaCreacion != null ? new Date($registro.fechaCreacion) : null,
      fechaFinalizacion: $registro.fechaFinalizacion != null ? new Date($registro.fechaFinalizacion) : null,
      observacion: $registro.observacion,
      status: $registro.status

    });

    this.api.get('comprobanteDetalle/listaPorComprobante/' + $registro.id, this.tituloHTML).subscribe(res => {

      if (res != null) {
        this.comprobantesDetalles = [];
        res.forEach(item => {
          let cd: ComprobanteDetalle = new ComprobanteDetalle();
          cd.id = item.id;
          cd.linea = item.linea;
          cd.estado = item.estado;
          cd.estatus = item.estatus;
          cd.fechaEntrega = item.fechaEntrega != null ? new Date(item.fechaEntrega) : null;
          cd.fechaRecepcion = item.fechaRecepcion != null ? new Date(item.fechaRecepcion) : null;
          cd.observacionEntrega = item.observacionEntrega;
          cd.observacionRecepcion = item.observacionRecepcion;
          cd.activo = new Activo();
          cd.activo.id = item.idActivo;
          cd.activo.descripcion = item.descripcionActivo;
          cd.activo.imagen = item.imagenActivo;
          cd.activo.imagenRuta = (item.imagenActivo == null || item.imagenActivo == '') ? 'assets/isa/images/sin_imagen.png' : this.api.appConfig.servidorImagenActivos + item.imagenActivo;
          cd.activo.nav = item.navActivo;
          cd.activo.serial = item.serialActivo;
          cd.activo.categoria = new Categoria();
          cd.activo.categoria.id = item.idCategoria;
          cd.activo.categoria.nombre = item.nombreCategoria;
          cd.activo.modelo = { id: item.idModelo, nombre: item.nombreModelo };
          cd.activo.marca = { id: item.idMarca, nombre: item.nombreMarca };
          cd.activo.ubicacion = new Ubicacion();
          cd.activo.ubicacion.id = item.idUbicacion;
          cd.activo.ubicacion.nombre = item.nombreUbicacion;
          this.comprobantesDetalles.push(cd);
        });
      }

    })

    this.esNuevo = true;
  }


  descargar() {

    this.appComponent.loader = true;

    let params: URLSearchParams = new URLSearchParams();
    params.set('pagina', '0');
    params.set('tamanio', this.totalRegistros.toString());
    params.set('parametroBusqueda', this.filtroTabla);
    params.set('tipoComprobante', EnmTipoComprobante.ActaEntregaRecepcion.toString())


    this.api.get('comprobante/lista', this.tituloHTML, params).subscribe(data => {
      if (data != null) {
        let informacionExportacion: any[] = [];

        if (data.content != null && data.content != undefined) {
          data.content.forEach(element => {
            informacionExportacion.push({Secuencia:element.secuencia, 
                                         Fecha: this.datePipe.transform(element.fechaCreacion,'dd-MM-yyyy'),
                                         Empleado: element.nameUsuario,
                                         'Observación': element.observacion,
                                        Estatus: element.status == EnmStatusActaEntregaRecepcionComprobante.EquiposAsignados ? 'Equipos Asignados' :
                                                 element.status == EnmStatusActaEntregaRecepcionComprobante.EquiposEntregados ? 'Equipos Devueltos' : ''});
          });
        }
        this.excelService.exportAsExcelFile(informacionExportacion, 'actasEntregaRecepcion');
        this.appComponent.loader = false;
      }
      this.appComponent.loader = false;
    }, error => {
      this.appComponent.loader = false;
    });

  }




  retorno(evento) {
    if (evento != null) {
      this.esNuevo = evento.esNuevo;
    }
  }




}
