import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Http, Headers, Response, Request, RequestOptions, URLSearchParams, RequestMethod } from '@angular/http';
import { AppConfig } from '../app-config';
import { ApiRequestService } from '../services/api-request.service';
import { Router } from '@angular/router';
import { SignaturePad } from '../../../node_modules/angular2-signaturepad';

@Component({
  selector: 'app-qr',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css', './firma.component.scss']
})
export class FirmaComponent implements OnInit {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  servidorRegistro = this.appConfig.baseApiRegistro;
  idComprobante;
  actaFirmadaCorrectamente = false;

  signaturePadOptions: Object = {
    'minWidth': 1,
    'canvasWidth': 350,
    'canvasHeight': 150
  };

  constructor(private route: ActivatedRoute, private http: Http, private appConfig: AppConfig,
    private api: ApiRequestService,
    private router: Router, ) {
    this.idComprobante = this.route.snapshot.paramMap.get("id");
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.set('penColor', 'blue');
    this.signaturePad.clear();
  }

  drawComplete() {
  }

  drawStart() {
  }

  aceptar() {
    let archivos: FormData = new FormData();

    const url = this.signaturePad.toDataURL();
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "AAAAA", { type: "image/png" })

        archivos.append('firma', file);
        archivos.append('entidad', new Blob([JSON.stringify({ id: this.idComprobante })],
          { type: "application/json" }));
        this.http.post(this.servidorRegistro + 'agregarFirma', archivos).subscribe(async res => {

          if (res != null && res.json() != null) {
            const resJson = res.json();
            if (resJson && resJson.operationStatus == 'SUCCESS') {
              this.actaFirmadaCorrectamente = true;
              await this.espera(6000);
              this.router.navigate(['/login']);
            } else {
              this.api.mensajeError('No se ha podido firmar el acta intentelo mas tarde');
            }
          }else{
            this.api.mensajeError('No se ha podido firmar el acta intentelo mas tarde');
          }

        });

      })



  }


  espera(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


}
