import { Injectable } from '@angular/core';

export class AppProperties{

    //Botones
    botonCancelar = 'Cancelar'; 
    botonGuardar = 'Guardar';
    botonEliminar = 'Eliminar'; 
    botonNuevo = 'Nuevo';


    //Campos comunes

    columnaClave= 'Porfavor ingrese una clave';
    columnaClaveInsegura= 'Clave insegura';
    columnaClaveNormal= 'Clave normal';
    columnaClaveFuerte = 'Clave fuerte';
    columnaConfirmacion = 'Confirmación';

    //Validaciones

    validacionEmail:string='El correo electrónico no es válido';



    //Mensajes comunes
    mensajeConfirmacionEliminar = '¿Esta seguro de eliminar el registro?';   
    mensaejeEliminar = 'Registro eliminado con éxito';    
    mensajeGuardar = 'Registro guardado con éxito';
    mensajeModificar = 'Registro modificado con éxito';

    mensajeErrorGuardar = 'No se ha podido guardar el registro' 

    mensajeConfirmTurnoGuardar = 'Confirma que desea cerrar el Turno?' 
    mensajeConfirmCajaGuardar = 'Confirma que desea cerrar la Caja?' 

    constructor(){}

    public validacionRequerido(campo){
         return 'El campo '+ campo + ' es requerido';
    }

    public validacionLetras(campo){
        return 'El campo '+campo+' solo permite letras';
    }

    public validacionUnico(nombreCampo, valor){
        return 'El campo '+nombreCampo+': '+valor+' ya existe y debe ser único';
    }


}