import { Component, OnInit } from '@angular/core';
import { ApiRequestService } from '../../../services/api-request.service';
import { Chart, StockChart, Highcharts } from 'angular-highcharts';
import { EnmEstatusActivo } from '../../../logica/enum/enmEstatusActivo';
import { EnmTipoComprobante } from '../../../logica/enum/enmTipoComprobante';
import { EnmEstatusActaEntregaRecepcionComprobanteDetalle } from '../../../logica/enum/enmEstatusActaEntregaRecepcionComprobanteDetalle';


@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {

  tituloHTML: string;
  es: any;
  donaPorUbicacion: Chart;
  donaPorEstatus: Chart;
  piePorCategoria: Chart;
  pieAsignaciones: Chart;

  constructor(private api: ApiRequestService) {
  }

  ngOnInit() {
    this.cargarFechas();
    this.tituloHTML = this.api.mensajeI18('common.reports');
    this.cargarGraficos();
  }

  cargarFechas() {
    this.es = {
      firstDayOfWeek: 0,
      dayNames: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
      dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
      dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
      monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
      today: 'Hoy',
      clear: 'Limpiar',
      dateFormat: 'dd/mm/yyyy'
    };
  }


  cargarGraficos() {

    this.api.get('activo/donaPorUbicacion', this.tituloHTML).subscribe(res => {
      let total = 0;
      res.forEach(element => {
        total += element[1];
      });
      this.donaPorUbicacion = this.donaGenerico('Activos por <br> ubicación (' + total + ')', 'Ubicación', res);
    });

    this.api.get('activo/donaPorEstatus', this.tituloHTML).subscribe(res => {
      res.forEach(item => item[0] == EnmEstatusActivo.Disponilble ? item[0] = 'Disponible' :
        item[0] == EnmEstatusActivo.Asignado ? item[0] = 'Asignado' :
          item[0] == EnmEstatusActivo.DadoDeBaja ? item[0] = 'Dado Baja' : '');
      let total = 0;
      res.forEach(element => {
        total += element[1];
      });
      this.donaPorEstatus = this.donaGenerico('Activos por <br> estatus (' + total + ')', 'Estatus', res);
    });


    this.api.get('activo/piePorCategoria', this.tituloHTML).subscribe(res => {
      let total = 0;
      res.forEach(element => {
        total += element[1];
      });
      this.piePorCategoria = this.pieGenerico('Activos','por categoria', res);

    });

    this.api.get('comprobanteDetalle/grafico/'+EnmTipoComprobante.ActaEntregaRecepcion, this.tituloHTML).subscribe(res => {
      res.forEach(item => item[0] == EnmEstatusActaEntregaRecepcionComprobanteDetalle.Entregado ? item[0] = 'Asignado' :
                  item[0] == EnmEstatusActaEntregaRecepcionComprobanteDetalle.Recepcionado ? item[0] = 'Devuelto' : '');

      let total = 0;
      res.forEach(element => { 
        total += element[1];
      });
      this.pieAsignaciones = this.pieGenerico('Activos','por asignacion', res);
    });



  }


  donaGenerico(titulo: string, subtitulo: string, seriesDatos: any[]): Chart {

    return new Chart({
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: titulo,
        align: 'center',
        verticalAlign: 'middle',
        y: 40
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y} - {point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            distance: -10,
            style: {
              fontWeight: 'bold',
              fontSize: '10px',
              color: 'white'
            }
          },
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '110%'
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [{
        type: 'pie',
        name: subtitulo,
        innerSize: '50%',
        data: seriesDatos
      }]

    });
  }


  pieGenerico(titulo: string, subtitulo: string, seriesDatos: any[]) {
    return new Chart({
      chart: {
        type: 'pie',
        options3d: {
          enabled: true,
          alpha: 45
        }
      },
      title: {
        text: titulo
      },
      subtitle: {
        text: subtitulo
      },
      plotOptions: {
        pie: {
          innerSize: 100,
          depth: 45
        }
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      series: [{
        name: titulo,
        data: seriesDatos
      }]
    });

  }




}
