import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoMovimientoPipe'
})
export class TipoMovimientoPipePipe implements PipeTransform {

  transform(value: string): string {
    return value == '1'  ? 'INGRESO' : 'EGRESO';
  }

}
